import { Box } from "@chakra-ui/react";
import React from "react";
import { usePageContext } from "../../contexts/page.context";
import { useThemeColors } from "../../../../hooks/useThemeColors";

const Progress = ({ value }) => {
  const { lightMode } = usePageContext();
  const { PRIMARY_COLOR, SECONDARY_COLOR_DARK } = useThemeColors();
  return (
    <Box
      position={"relative"}
      w={"87px"}
      h={"7px"}
      borderRadius={"15px"}
      bg={lightMode ? "#f5f5f5" : SECONDARY_COLOR_DARK}
    >
      <Box
        borderRadius={"15px"}
        w={value * 100 + "%"}
        height={"100%"}
        bg={PRIMARY_COLOR}
      ></Box>
    </Box>
  );
};

export default Progress;
