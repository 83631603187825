import React from "react";
// import { usePageContext } from '../contexts/page.context'
import { usePageContext } from "../contexts/page.context";
import { useThemeColors } from "../../../hooks/useThemeColors";
const CalendarIcon = ({ active }) => {
  const { currentTheme } = usePageContext();
  const { PRIMARY_COLOR } = useThemeColors();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
    >
      <g clipPath="url(#clip0_69_2624)">
        <path
          d="M16.625 1.75H15.75V0.875C15.75 0.392 15.358 0 14.875 0C14.392 0 14 0.392 14 0.875V1.75H7V0.875C7 0.392 6.608 0 6.125 0C5.642 0 5.25 0.392 5.25 0.875V1.75H4.375C1.96263 1.75 0 3.71263 0 6.125V16.625C0 19.0374 1.96263 21 4.375 21H16.625C19.0374 21 21 19.0374 21 16.625V6.125C21 3.71263 19.0374 1.75 16.625 1.75ZM4.375 3.5H16.625C18.0723 3.5 19.25 4.67775 19.25 6.125V7H1.75V6.125C1.75 4.67775 2.92775 3.5 4.375 3.5ZM16.625 19.25H4.375C2.92775 19.25 1.75 18.0723 1.75 16.625V8.75H19.25V16.625C19.25 18.0723 18.0723 19.25 16.625 19.25Z"
          fill={
            active
              ? PRIMARY_COLOR
              : currentTheme === "light"
              ? "black"
              : "white"
          }
        />
      </g>
      <defs>
        <clipPath id="clip0_69_2624">
          <rect width="21" height="21" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CalendarIcon;
