import { Grid, HStack, VStack } from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";
import { Text } from "../../../../components/text";
import { usePageContext } from "../../../../contexts/page.context";
import NextButton from "../../../../components/NextButton";
import TextInput from "./TextInput";
import SavedIcon from "../../../../icons/Saved.icon";
import { Checkbox } from "../../../../components/checkbox";
import useSendFundsStore from "../../store";
import useSavedAddresses from "./../../hooks/useSavedAddresses";
import { useThemeColors } from "../../../../../../hooks/useThemeColors";
import { useDomainContext } from "../../../../../../contexts/DomainContext";

const BankForm = () => {
  const toggleBankAddresses = useSendFundsStore((s) => s.toggleBankAddresses);

  const { PRIMARY_COLOR } = useThemeColors();

  const { companyTheme } = useDomainContext();

  const { data: savedAddresses } = useSavedAddresses("wire-transfer");
  const bankName = useSendFundsStore((s) => s.bankDetails.bankName);
  const accountName = useSendFundsStore((s) => s.bankDetails.accountName);
  const accountNumber = useSendFundsStore((s) => s.bankDetails.accountNumber);
  const sortCode = useSendFundsStore((s) => s.bankDetails.sortCode);
  const address = useSendFundsStore((s) => s.bankDetails.address);
  const swift = useSendFundsStore((s) => s.bankDetails.swift);

  const { lightMode } = usePageContext();
  const saveBankDetails = useSendFundsStore((s) => s.saveBankDetails);
  const nextPage = useSendFundsStore((s) => s.nextPage);
  const completePage = useSendFundsStore((s) => s.completePage);
  const inCompletePage = useSendFundsStore((s) => s.inCompletePage);
  const [saveAddress, setSaveAddress] = useState(false);
  const isActive =
    !!bankName &&
    !!accountName &&
    !!accountNumber &&
    !!address &&
    !!sortCode &&
    !!swift;

  const handleSubmit = () => {
    saveBankDetails({
      bankName,
      accountName,
      accountNumber,
      address,
      sortCode,
      swift,
      saveAddress,
    });
    nextPage();
  };

  useEffect(() => {
    if (isActive) completePage();
    else inCompletePage();

    return () => {
      inCompletePage();
    };
  }, [isActive]);

  return (
    <Grid
      as={"form"}
      w={"100%"}
      gap={"54px"}
      pt={"12px"}
      gridTemplateColumns={"7fr 10fr"}
    >
      <VStack gap={"34px"}>
      {companyTheme === "default" && <VStack gap={"19px"} w={"100%"} alignItems={"flex-start"}>
          <Text
            as={"label"}
            htmlFor={"bank-name"}
            fontSize={"14px"}
            fontWeight={"500"}
          >
            Bank Name
          </Text>
          <TextInput
            value={bankName}
            onChange={(e) => {
              saveBankDetails({
                bankName: e.target.value,
                accountName,
                accountNumber,
                address,
                sortCode,
                swift,
                saveAddress,
              });
            }}
            id={"bank-name"}
            placeholder={"Insert bank name"}
          />
        </VStack>}
        {companyTheme === "default" && <VStack gap={"19px"} w={"100%"} alignItems={"flex-start"}>
          <Text
            as={"label"}
            htmlFor={"account-number"}
            fontSize={"14px"}
            fontWeight={"500"}
          >
            IBAN
          </Text>
          <TextInput
            value={accountNumber}
            onChange={(e) =>
              saveBankDetails({
                bankName,
                accountName,
                accountNumber: e.target.value,
                address,
                sortCode,
                swift,
                saveAddress,
              })
            }
            id={"account-number"}
            placeholder={"Insert IBAN"}
          />
        </VStack>}
        <VStack gap={"19px"} w={"100%"} alignItems={"flex-start"}>
          <Text
            as={"label"}
            htmlFor={"sort-code"}
            fontSize={"14px"}
            fontWeight={"500"}
          >
            Branch/Sort Code
          </Text>
          <TextInput
            value={sortCode}
            onChange={(e) =>
              saveBankDetails({
                bankName,
                accountName,
                accountNumber,
                address,
                sortCode: e.target.value,
                swift,
                saveAddress,
              })
            }
            id={"sort-code"}
            placeholder={"Insert branch or sort code"}
          />
        </VStack>
        <HStack w={"100%"} gap={"10px"}>
          <Checkbox value={saveAddress} onChange={setSaveAddress} />
          <Text
            color={lightMode ? PRIMARY_COLOR : "white"}
            fontWeight={"500"}
            fontSize={"14px"}
          >
            Save address for faster withdrawals
          </Text>
        </HStack>
      </VStack>
      <VStack gap={"34px"}>
        <VStack gap={"19px"} w={"100%"} alignItems={"flex-start"}>
          <HStack
            w={"100%"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Text
              as={"label"}
              htmlFor={"account-name"}
              fontSize={"14px"}
              fontWeight={"500"}
            >
              Account Name
            </Text>
            {!!savedAddresses?.length && (
              <HStack
                onClick={toggleBankAddresses}
                gap={"11px"}
                cursor={"pointer"}
                alignItems={"center"}
              >
                <Text
                  mb={0}
                  color={lightMode ? PRIMARY_COLOR : "white"}
                  fontWeight={"500"}
                  fontSize={"13px"}
                  textDecoration={lightMode ? "" : "underline"}
                >
                  Saved Addresses
                </Text>
                <SavedIcon />
              </HStack>
            )}
          </HStack>

          <TextInput
            value={accountName}
            onChange={(e) =>
              saveBankDetails({
                bankName,
                accountName: e.target.value,
                accountNumber,
                address,
                sortCode,
                swift,
                saveAddress,
              })
            }
            id={"account-name"}
            placeholder={"Insert account name"}
          />
        </VStack>
        <VStack gap={"19px"} w={"100%"} alignItems={"flex-start"}>
          <Text
            as={"label"}
            htmlFor={"account-name"}
            fontSize={"14px"}
            fontWeight={"500"}
          >
            Address
          </Text>
          <TextInput
            value={address}
            onChange={(e) =>
              saveBankDetails({
                bankName,
                accountName,
                accountNumber,
                address: e.target.value,
                sortCode,
                swift,
                saveAddress,
              })
            }
            id={"address"}
            placeholder={"Insert bank's address"}
          />
        </VStack>
        <VStack gap={"19px"} w={"100%"} alignItems={"flex-start"}>
          <Text
            as={"label"}
            htmlFor={"swift"}
            fontSize={"14px"}
            fontWeight={"500"}
          >
            Swift
          </Text>
          <HStack w={"100%"} gap={"33px"}>
            <TextInput
              value={swift}
              onChange={(e) =>
                saveBankDetails({
                  bankName,
                  accountName,
                  accountNumber,
                  address,
                  sortCode,
                  swift: e.target.value,
                  saveAddress,
                })
              }
              id={"swift"}
              placeholder={"Insert swift"}
            />
            <NextButton active={isActive} onSubmit={handleSubmit} />
          </HStack>
        </VStack>
      </VStack>
    </Grid>
  );
};

export default BankForm;
