import { useEffect, useState } from "react";
import { env } from "../../constants/env";
import DownloadIcon from "../../icons/DownloadIcon";
import { getCookie } from "../../utilities/functions";
import classes from "./ServicesFees.module.css"
import { useSelector } from "react-redux";
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import { saveAs } from 'file-saver';
import FileDownload from "js-file-download";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useDynamicTitle } from "../../hooks/useDynamicTitle";
import { useThemeColors } from "../../hooks/useThemeColors";

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}

const tableData = [
  {
    status: "Failed",
    date: "February 1, 2023",
    amount: "$3,000"
  },
  {
    status: "Paid",
    date: "January 1, 2023",
    amount: "$3,525"
  },
  {
    status: "Paid",
    date: "December 1, 2022",
    amount: "$2,987"
  }
]


const MemberHeader = () => {
  const { PRIMARY_COLOR } = useThemeColors();
  return (
    <>
      <tr style={{ color: PRIMARY_COLOR }}>
        <th>Status</th>
        <th>Date</th>
        <th>Amount</th>
        <th>Invoice</th>
      </tr>
    </>
  );
};


const MemberRow = ({
  item,
  themeMode
}) => {

  const companyName = useSelector(state => state.company.companyName)
  const companyDirector = useSelector(state => state.company.companyDirector)
  const [isSending, setIsSending] = useState(false)
  const [fileKey, setFileKey] = useState()

  const { PRIMARY_COLOR } = useThemeColors();


  const dateToYMD = date => {
    const formatedDate = date.split('T')[0]
    console.log("DATE", formatedDate)
    var newDate = new Date(formatedDate)
    var today = new Date()
    var strArray = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]
    var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    var d = newDate.getDate()
    var dayName = days[newDate.getDay()]
    var m = strArray[newDate.getMonth()]
    var y = newDate.getFullYear()


    return {
      dashDate: '' + m + ' ' + (d <= 9 ? '0' + d : d) + ', ' + y,
      invoiceDate: formatedDate
    }
  }

  const capitalizeFirstLetter = (word) => {
    return word[0].toUpperCase() + word.substring(1);
  }


  const deleteFile = async (key) => {
    let dataContent = { key }
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/docxtopdf/delete-file`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContent)
      });

      if (!response.ok) {
        const result = await response.json();

        console.log("Error", result);
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      console.log("Reply from plays data", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };


  const handleDownload = (url, filename) => {
    axios.get(url, {
      responseType: 'blob',
    })
      .then((res) => {
        FileDownload(res.data, filename)
      }).then(() => {
        deleteFile(fileKey)
      })
  }





  const uploadDocumentHandler = async (file) => {
    const sendRequest = async () => {
      setIsSending(true)
      console.log("REACHED HERE")
      const formData = new FormData()
      formData.append('files', file)

      const response = await fetch(`${env.primeBridgeUrl}/api/docxtopdf`, {
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        const result = await response.json();
        setIsSending(false)
        console.log(result.error)
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      setIsSending(false)

      console.log("File", result.file);
      handleDownload(result.file, 'prime-bridge_invoice.pdf');
      return result;
    };

    try {
      const data = await sendRequest();
      // deleteFile(data.key)
      setFileKey(data.key)
    } catch (error) {
      setIsSending(false)
      console.log(error);
    }
  };





  const generateDocument = () => {
    loadFile(
      'https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/invoice_template.docx',
      function (error, content) {
        if (error) {
          throw error;
        }
        var zip = new PizZip(content);
        var doc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
        });
        doc.setData({
          director_name: companyDirector,
          company_name: companyName,
          invoice_date: dateToYMD(item?.date).invoiceDate,
          invoice_number: '100-13',
          payment_terms: 'Monthly',
          customer_number: '12345',
          account_number: '12345',
          description: item?.item,
          total_amount: item?.amount
        });
        try {
          // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
          doc.render();
        } catch (error) {
          // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
          function replaceErrors(key, value) {
            if (value instanceof Error) {
              return Object.getOwnPropertyNames(value).reduce(function (
                error,
                key
              ) {
                error[key] = value[key];
                return error;
              },
                {});
            }
            return value;
          }
          console.log(JSON.stringify({ error: error }, replaceErrors));

          if (error.properties && error.properties.errors instanceof Array) {
            const errorMessages = error.properties.errors
              .map(function (error) {
                return error.properties.explanation;
              })
              .join('\n');
            console.log('errorMessages', errorMessages);
            // errorMessages is a humanly readable message looking like this :
            // 'The tag beginning with "foobar" is unopened'
          }
          throw error;
        }
        var out = doc.getZip().generate({
          type: 'blob',
          mimeType:
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        }); //Output the document using Data-URI

        uploadDocumentHandler(out)
        // saveAs(out, 'prime-bridge_invoice.docx');
      }
    );
  };

  return (
    <tr>
      <td>
        <div className="d-flex align-items-center">
          <div className={`${classes.dotIndicator} ${item?.status === "failed" ? classes.failedIndicator : item?.status === "paid" && classes.paidIndicator}`}></div>
          {capitalizeFirstLetter(item?.status)}
        </div>
      </td>
      <td>{dateToYMD(item?.date).dashDate}</td>
      <td>${item?.amount}</td>
      <td><div role="button" onClick={generateDocument} className="d-flex justify-content-start gap-1"><DownloadIcon themeColor={themeMode === "light" ? "#141414" : "#fff"} /> <span style={{ color: themeMode === "light" ? PRIMARY_COLOR : "#fff", textDecoration: themeMode === "dark" && 'underline' }}>Download</span></div></td>
    </tr>
  );
};

const ServicesFees = ({ themeMode }) => {
  useDynamicTitle("Service Fees", "dashboard/transactions/services-fees");

  const [feesList, setFeesList] = useState([])
  const companyId = getCookie("companyId");

  useEffect(() => {
    getAllInvoices()
  }, [])

  const getAllInvoices = async () => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/service-fee/company/${companyId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      console.log("Invoices data", result);
      setFeesList(result)
      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={`${classes.wrapper} main-divs container`}>
      {/* <Helmet>
        <meta charSet="utf-8" />
        <title>Service Fees | Prime Bridge Portal</title>
        <link rel="canonical" href="https://portal.primebridge.io/dashboard/transactions/services-fees" />
      </Helmet> */}
      <div className={`${classes.topSection} d-flex justify-content-between align-items-center mb-4`}>
        <h3>Service Fees</h3>
      </div>
      <div className={classes.tableDiv}>
        <table>
          <MemberHeader />
          {feesList.map((item, i) => (
            <MemberRow item={item} themeMode={themeMode} key={i} />
          ))}
        </table>
      </div>
    </div>
  )
}

export default ServicesFees