import { Box, HStack, VStack } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { toast } from "react-hot-toast";
import InfoIcon from "../../../../../../icons/InfoIcon";
import { numberWithCommas } from "../../../../../../utilities/functions";
import { usePageContext } from "../../../../contexts/page.context";
import CopyIcon from "../../../../components/Copy.icon";
import { Text } from "../../../../components/text";
import SuccessIcon from "../../../../icons/Success.icon";
import { useAddFundsContext } from "../../addfunds.context";
import useAddFunds from "../../hooks/useAddFunds";
import useAddFundsStore from "../../store";
import Tip from "../tip/Tip";
import { useSelector } from "react-redux";
import useWalletStore from "./../../../../store";
import { useThemeColors } from "../../../../../../hooks/useThemeColors";

const CryptoDetails = () => {
  const { lightMode } = usePageContext();
  const amount = useAddFundsStore((s) => s.secondDetails.amount);
  const resetPage = useAddFundsStore((s) => s.resetPage);
  const closeSection = useWalletStore((s) => s.closeSection);
  const completePage = useAddFundsStore((s) => s.completePage);
  const inCompletePage = useAddFundsStore((s) => s.inCompletePage);
  const inputSource = useWalletStore((s) => s.inputSource);
  const depositSettings = useSelector((state) => state.company.depositSettings);
  const { mutateAsync: addFunds } = useAddFunds();
  const amountNum = parseFloat(amount.replace(/,/g, ""));
  const feePercent =
    amountNum >= Math.pow(10, 4) && amountNum < Math.pow(10, 5)
      ? 0.2
      : amountNum >= Math.pow(10, 5) && amountNum < Math.pow(10, 6)
      ? 0.1
      : amountNum >= Math.pow(10, 6) && amountNum < Math.pow(10, 6) * 5
      ? 0.05
      : amountNum >= Math.pow(10, 6) * 5 && amountNum < Math.pow(10, 6) * 10
      ? 0.025
      : amountNum >= Math.pow(10, 6) * 10
      ? 0.0125
      : 0;
  const depositFee = feePercent * amountNum;
  const totalWithdrawal = amountNum + depositFee;
  const { PRIMARY_COLOR, SECONDARY_COLOR_DARK } = useThemeColors();

  useEffect(() => {
    completePage();
    return () => {
      inCompletePage();
    };
  }, []);

  async function copy(str) {
    try {
      await navigator.clipboard.writeText(str);
      toast.success("Text copied to clipboard.", {
        icon: <InfoIcon />,
        position: "top-right",
        style: {
          fontSize: "14px",
          color: lightMode ? "black" : "white",
          borderRadius: "4px",
          backgroundColor: lightMode ? "#f9f9f9" : SECONDARY_COLOR_DARK,
        },
      });
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  }

  const handleSubmit = () => {
    addFunds().then(() => {
      resetPage();
      inputSource("");
      closeSection();
    });
  };

  const DotIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="9"
        viewBox="0 0 8 9"
        fill="none"
      >
        <circle cx="4" cy="4.0166" r="4" fill={PRIMARY_COLOR} />
      </svg>
    );
  };

  return (
    <VStack w={"100%"} alignItems={"center"} gap={"35px"}>
      <HStack
        px={"20px"}
        pt={"27px"}
        borderTop={`1px solid ${lightMode ? "#e1e1e1" : ""}`}
        justifyContent={"space-between"}
        alignItems={"flex-start"}
        w={"100%"}
      >
        <VStack gap={0} alignItems={"flex-start"}>
          <VStack
            id="toggleDetails"
            alignItems={"flex-start"}
            gap={"18px"}
            mb={"38px"}
          >
            <Text fontSize={"14px"} fontWeight={"600"}>
              Crypto Address
            </Text>
            <HStack gap={"13px"}>
              <Text fontSize={"14px"} color={lightMode ? "#7f7f7f" : "#D0D0D0"}>
                1Lbcfr7sAHTD9CgdQo3HTMTkV8LK4ZnX71
              </Text>
              <Box
                cursor={"pointer"}
                onClick={() => copy("1Lbcfr7sAHTD9CgdQo3HTMTkV8LK4ZnX71")}
              >
                <CopyIcon />
              </Box>
            </HStack>
          </VStack>

          <VStack gap={0} alignItems={"flex-start"}>
            <Text mb={"26px"} fontSize={"14px"} fontWeight={"600"}>
              Deposit Details
            </Text>
            <Text mb={"19px"} fontSize={"14px"} fontWeight={"600"}>
              Amount: US${numberWithCommas(amount)}
            </Text>
            <HStack mb={"12px"}>
              <Text fontSize={"14px"} fontWeight={"600"}>
                Allocation Fee: US$
                {numberWithCommas(depositFee.toFixed(2))}
              </Text>
              <Tip />
            </HStack>
            <HStack alignItems={"flex-end"} mb={"9px"}>
              <Text fontSize={"14px"} lineHeight={"13px"} fontWeight={"600"}>
                Total to Send:
              </Text>
              <Text
                fontSize={"20px"}
                fontWeight={"500"}
                lineHeight={"20px"}
                color={PRIMARY_COLOR}
              >
                US$
                {numberWithCommas(totalWithdrawal.toFixed(2))}
              </Text>
            </HStack>
          </VStack>
        </VStack>
        <VStack id="toggleHow" alignItems={"flex-start"}>
          <Text mb={"10px"} fontSize={"14px"}>
            How it works
          </Text>
          <VStack gap={0} alignItems={"flex-start"}>
            <HStack gap={0}>
              <DotIcon />
              <Text
                pl={"11px"}
                lineHeight={"22px"}
                fontSize={"12px"}
                fontWeight={"500"}
              >
                Transfer Money
              </Text>
            </HStack>
            <Text
              ml={"4px"}
              pl={"26px"}
              py={"5px"}
              lineHeight={"22px"}
              fontSize={"12px"}
              borderLeft={`1px solid ${lightMode ? "#e1e1e1" : "#343C40"}`}
              fontWeight={"500"}
              color={lightMode ? "#7f7f7f" : "#d0d0d0"}
            >
              Transfer your money to Prime Bridge account
            </Text>
            <HStack gap={0}>
              <DotIcon />
              <Text
                pl={"11px"}
                lineHeight={"22px"}
                fontSize={"12px"}
                fontWeight={"500"}
              >
                Process Order
              </Text>
            </HStack>
            <Text
              ml={"4px"}
              pl={"26px"}
              py={"5px"}
              lineHeight={"22px"}
              fontSize={"12px"}
              borderLeft={`1px solid ${lightMode ? "#e1e1e1" : "#343C40"}`}
              fontWeight={"500"}
              color={lightMode ? "#7f7f7f" : "#d0d0d0"}
            >
              3 to 5 business days for the fund to arrive
            </Text>
            <HStack gap={0}>
              <DotIcon />
              <Text
                pl={"11px"}
                lineHeight={"22px"}
                fontSize={"12px"}
                fontWeight={"500"}
              >
                Funds Arrived
              </Text>
            </HStack>
            <Text
              ml={"4px"}
              pl={"26px"}
              py={"5px"}
              lineHeight={"22px"}
              fontSize={"12px"}
              fontWeight={"500"}
              color={lightMode ? "#7f7f7f" : "#d0d0d0"}
            >
              Receive your deposit amount
            </Text>
          </VStack>
        </VStack>
      </HStack>
      <Button
        width={"243px"}
        gap={"10px"}
        borderRadius={"31px"}
        bg={PRIMARY_COLOR}
        _hover={{
          color: lightMode ? "white" : PRIMARY_COLOR,
        }}
        fontWeight={"700"}
        color={"white"}
        height={"48px"}
        onClick={handleSubmit}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        fontSize={"14px"}
      >
        Submit
      </Button>
    </VStack>
  );
};

export default CryptoDetails;
