import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';
import styles from "./BarChartComponent.module.css"
import ChartCustomTooltip from '../chartComponent/components/ChartCustomTooltip';
import { Button } from '../chartComponent/Chart';
import BarChartCustomTooltip from './components/BarChartCustomTooltip';

const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

export default class BarChartComponent extends PureComponent {
  static demoUrl = 'https://codesandbox.io/s/tiny-bar-chart-35meb';

  constructor(props) {
    super(props);
    this.state = {
      percentageActive: false,
      oneDayActive: false,
      fiveDaysActive: false,
      oneMonthActive: false,
      oneYearActive: false,
      yearToDateActive: false,
      allActive: true,
    };
  }

  render() {
    const {currentBalance, maxBalance, data, query, themeMode} = this.props
    
    return (
        <div className={styles.chartContainer}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart width={150} height={40} data={data} stackOffset='sign'>
        <YAxis  tickCount={3} unit={'%'} tick={{fill: themeMode === "dark" ? "#FFF" : undefined}}/>
        <XAxis dataKey="date" tick={false}/>
        <Tooltip content={<BarChartCustomTooltip />} cursor={{ fill: "transparent" }} />
          <ReferenceLine y={0} stroke="#8C8C8C"  />
          {/* <Bar dataKey="p" barSize={20} fill="#3ACD8D" radius={4} h/> */}
          <Bar dataKey="drawDown" barSize={20} fill="#BD3437" radius={4}/>
        </BarChart>
      </ResponsiveContainer>
      <div className={`${styles.chartBtns} d-flex justify-content-center align-items-center gap-4`}>
            <Button
              active={this.state.oneDayActive}
              onClick={() => {
                query(1)
                this.setState({
                  oneDayActive: true,
                  fiveDaysActive: false,
                  oneMonthActive: false,
                  oneYearActive: false,
                  yearToDateActive: false,
                  allActive: false,
                })
              }
              }
            >
              1D
            </Button>
            <Button
              active={this.state.fiveDaysActive}
              onClick={() =>{
                query(7)
                this.setState({
                  oneDayActive: false,
                  fiveDaysActive: true,
                  oneMonthActive: false,
                  oneYearActive: false,
                  yearToDateActive: false,
                  allActive: false,
                })
              }
              }
            >
              1W
            </Button>
            <Button
              active={this.state.oneMonthActive}
              onClick={() =>{
                query(30)
                this.setState({
                  oneDayActive: false,
                  fiveDaysActive: false,
                  oneMonthActive: true,
                  oneYearActive: false,
                  yearToDateActive: false,
                  allActive: false,
                })
              }
              }
            >
              1M
            </Button>
            <Button
              active={this.state.oneYearActive}
              onClick={() => {
                query(90)
                this.setState({
                  oneDayActive: false,
                  fiveDaysActive: false,
                  oneMonthActive: false,
                  oneYearActive: true,
                  yearToDateActive: false,
                  allActive: false,
                })
              }
              }
            >
              3M
            </Button>
            <Button
              active={this.state.yearToDateActive}
              onClick={() =>{
                query(365)
                this.setState({
                  oneDayActive: false,
                  fiveDaysActive: false,
                  oneMonthActive: false,
                  oneYearActive: false,
                  yearToDateActive: true,
                  allActive: false,
                })
              }
              }
            >
              Y
            </Button>
            <Button
              active={this.state.allActive}
              onClick={() =>{
                query('all')
                this.setState({
                  oneDayActive: false,
                  fiveDaysActive: false,
                  oneMonthActive: false,
                  oneYearActive: false,
                  yearToDateActive: false,
                  allActive: true,
                })
              }
              }
            >
              ALL
            </Button>
        </div>
    </div>
    );
  }
}