// useDynamicTitle.js
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDomainContext } from "../contexts/DomainContext";
import { capitalizeFirstLetter } from "../utilities/functions";

export const useDynamicTitle = (pageTitle, page) => {
    const { companyTheme } = useDomainContext();

    useEffect(() => {
        // Set the page title dynamically
        const themeName = companyTheme?.name || "Prime Bridge Portal";  // Default to "Prime Bridge Portal" if theme is not available
        document.title = `${pageTitle} | ${capitalizeFirstLetter(themeName)}`;
    }, [companyTheme, pageTitle]);

    return (
        <Helmet>
            <meta charSet="utf-8" />
            <link rel="canonical" href={companyTheme?.url ? companyTheme.url + '/' + page : `https://portal.primebridge.io/${page}`} />
        </Helmet>
    );
};