import { VStack } from "@chakra-ui/layout";
import React from "react";
import { Helmet } from "react-helmet";
import HistoryList from "./features/history/ui/HistoryList";
import QuickActions from "./features/actions/ui/QuickActions";
import TradingAccounts from "./features/accounts/ui/TradingAccounts";
import PageContextProvider from "./contexts/page.context";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ms from "ms";
import { useDynamicTitle } from "../../hooks/useDynamicTitle";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
      staleTime: ms("1h"),
    },
  },
});

const Funds = ({ themeMode }) => {
  useDynamicTitle("Funds", "dashboard/transactions/funds");
  return (
    <QueryClientProvider client={queryClient}>
      <PageContextProvider themeMode={themeMode}>
        <main
          style={{
            width: "100%",

            // paddingBottom: "116px",
          }}
        >
          {/* <Helmet>
            <meta charSet="utf-8" />
            <title>Funds | Prime Bridge Portal</title>
            <link
              rel="canonical"
              href="https://portal.primebridge.io/dashboard/transactions/funds"
            />
          </Helmet> */}
          <VStack maxWidth={"94%"}>
            <QuickActions />
            <TradingAccounts />
            <HistoryList />
          </VStack>
        </main>
      </PageContextProvider>
    </QueryClientProvider>
  );
};

export default Funds;
