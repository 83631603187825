import { HStack, VStack } from "@chakra-ui/layout";
import { Input } from "@chakra-ui/input";
import React, { useEffect, useState } from "react";
import { usePageContext } from "../../../../contexts/page.context";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "../../../../components/menu";
import { Text } from "../../../../components/text";
import NextButton from "../../../../components/NextButton";
import { numberWithCommas } from "../../../../../../utilities/functions";
import SuccessGreyIcon from "../../../../icons/SuccessGrey.icon";
import { toast } from "react-hot-toast";
import { PRIMARY_COLOR } from "../../../../../../constants/colors";
import SavedIcon from "../../../../icons/Saved.icon";
import { Checkbox } from "../../../../components/checkbox";
import useSendFundsStore from "../../store";
import { Coins } from "../../constants";
import classes from "./CryptoInput.module.css"
import useSavedAddresses from "../../hooks/useSavedAddresses";
import DropGainDownIcon from "../../../../../../icons/DropGainDownIcon";
import { useThemeColors } from "../../../../../../hooks/useThemeColors";

const CryptoInput = () => {
  const { lightMode } = usePageContext();
  const toggleCryptoAddresses = useSendFundsStore(
    (s) => s.toggleCryptoAddresses
  );
  const { data: savedAddresses } = useSavedAddresses("crypto");
  const coin = useSendFundsStore((s) => s.cryptoDetails.coin);
  const walletAddress = useSendFundsStore((s) => s.cryptoDetails.walletAddress);
  const saveCryptoDetails = useSendFundsStore((s) => s.saveCryptoDetails);
  const completePage = useSendFundsStore((s) => s.completePage);
  const nextPage = useSendFundsStore((s) => s.nextPage);

  const inCompletePage = useSendFundsStore((s) => s.inCompletePage);
  const [saveAddress, setSaveAddress] = useState(false);

  const [showMenu, setShowMenu] = useState(false); // Track visibility of main menu
  const [openSubmenu, setOpenSubmenu] = useState(null); // Track the open submenu item


  const { SECONDARY_COLOR_DARK } = useThemeColors();

  const handleMenuToggle = () => {
    setShowMenu(!showMenu); // Toggle main menu visibility
    setOpenSubmenu(null); // Reset any open submenu when main menu is toggled
  };

  const handleSubmenuToggle = (submenu) => {
    // Toggle submenu visibility
    setOpenSubmenu(openSubmenu === submenu ? null : submenu);
  };


  const handleSubmit = () => {
    saveCryptoDetails({
      walletAddress,
      coin,
      saveAddress,
    });
    nextPage();
  };

  useEffect(() => {
    if (!!walletAddress && !!coin) completePage();
    else inCompletePage();
    return () => {};
  }, [walletAddress, coin]);

  return (
    <VStack w={"100%"} pt={"20px"}>
      <HStack
        w={"100%"}
        gap={"35px"}
        justifyContent={"space-between"}
        alignItems={"flex-end"}
      >
        <VStack flexGrow={0.5} alignItems={"19px"}>
          <Text fontSize={"14px"} fontWeight={"500"}>
            Coins
          </Text>
          {/* <Menu
            width={"100%"}
            px={"20px"}
            bg={lightMode ? "#f5f5f5" : "#01293E"}
            py={"12px"}
            onChange={(str) => {
              saveCryptoDetails({
                walletAddress,
                coin: str,
              });
            }}
            value={coin}
          >
            <MenuButton placeholder={"Select coin"} />
            <MenuList>
              {Coins.map((x, i) => (
                <div className="d-flex justify-content-between align-items-end">
                <MenuItem disabled={x.title !== "USDT"} key={i}>
                  {x.title}
                </MenuItem>
                {<div className={classes.subdr}>Select network</div>}
                </div>
                
              ))}
            </MenuList>
          </Menu> */}
             <div className={classes.contextMenu}>
      <div 
        onClick={handleMenuToggle} 
        className={`${classes.selectItemButton} ${showMenu ? classes.open : ''} d-flex justify-content-between align-items-center`}
      >
        <div>{coin ? coin : "Select coin"}</div> <DropGainDownIcon color="#111" dropDown={showMenu} height={11} width={14}/>
      </div>
      {showMenu && (
        <ul className={classes.menu}>
          {Coins.map((x, i) => (
          <li key={i}>
            <button onClick={() => handleSubmenuToggle(i)} className={classes.menuButton} disabled={x.title !== "USDT"}>{x.title}</button>
            {openSubmenu === i && (
              <ul className={classes.submenu}>
                <h6>Select Network</h6>
                {x.subMenu.map((item, index) => (<li key={index} onClick={() => {
                  saveCryptoDetails({
                walletAddress,
                coin: x.title + " - " + item.title,
              })
              setShowMenu(false);
              setOpenSubmenu(null);
              }}>{item.title}</li>))}
              </ul>
            )}
          </li>))}
        </ul>
      )}
    </div>
        </VStack>
        <VStack flexGrow={1} alignItems={"19px"}>
          <Text fontSize={"14px"} fontWeight={"500"}>
            Wallet Address
          </Text>
          <HStack
            flexGrow={1}
            borderRadius={"50px"}
            bg={lightMode ? "#f5f5f5" : SECONDARY_COLOR_DARK}
            px={"18px"}
            py={"12px"}
          >
            <Input
              px={0}
              value={walletAddress}
              height={"100%"}
              flexGrow={1}
              color={lightMode ? "black" : "white"}
              onChange={(e) => {
                saveCryptoDetails({
                  walletAddress: e.target.value,
                  coin,
                });
              }}
              w={"100%"}
              border={"none"}
              outlineColor={"transparent"}
              focusBorderColor={"transparent"}
              _placeholder={{
                color: lightMode ? "#7f7f7f" : "#d0d0d0",
              }}
              fontSize={"14px"}
              bg={"transparent"}
              placeholder="Insert address"
            />
          </HStack>
        </VStack>
        <VStack alignItems={"flex-end"}>
          {!!savedAddresses?.length && (
            <HStack
              onClick={() => toggleCryptoAddresses()}
              gap={"11px"}
              cursor={"pointer"}
              alignItems={"center"}
            >
              <Text
                textDecoration={lightMode ? "" : "underline"}
                mb={0}
                color={lightMode ? PRIMARY_COLOR : "white"}
                fontWeight={"500"}
                fontSize={"13px"}
              >
                Saved Addresses
              </Text>
              <SavedIcon />
            </HStack>
          )}

          <NextButton
            active={!!coin && !!walletAddress}
            onSubmit={handleSubmit}
          />
        </VStack>
      </HStack>
      <HStack w={"100%"} gap={"10px"}>
        <Checkbox value={saveAddress} onChange={setSaveAddress} />
        <Text
          color={lightMode ? PRIMARY_COLOR : "white"}
          fontWeight={"500"}
          fontSize={"14px"}
        >
          Save address for faster withdrawals
        </Text>
      </HStack>
    </VStack>
  );
};

export default CryptoInput;
