import { useDomainContext } from "../contexts/DomainContext";


export const useThemeColors = () => {
    const { companyTheme } = useDomainContext();
    
    return {
        PRIMARY_COLOR: companyTheme?.colors?.primary || "#0077B5",
        SECONDARY_COLOR: companyTheme?.colors?.washedPrimary || "#E5F1F7",
        SECONDARY_COLOR_DARK: companyTheme?.colors?.secondary || "#01293E",
        ACCENT_COLOR: companyTheme?.colors?.primary || "#01486D"
    };
};