import { HStack, VStack } from "@chakra-ui/layout";
import { Input } from "@chakra-ui/input";
import { useEffect, useState } from "react";
import { usePageContext } from "../../../contexts/page.context";
import { Text } from "../../../components/text";
import Tip from "../tip/Tip";
import { numberWithCommas } from "../../../../../utilities/functions";
import NextButton from "../../../components/NextButton";
import useTransferFundsStore from "../store";
import useSendCode from "../hooks/useSendCode";
import useVerifyCode from "../hooks/useVerifyCode";
import { useThemeColors } from "../../../../../hooks/useThemeColors";

const AmountInput = () => {
  const { lightMode } = usePageContext();
  const completePage = useTransferFundsStore((s) => s.completePage);
  const nextPage = useTransferFundsStore((s) => s.nextPage);
  const { mutateAsync: requestCode } = useSendCode();
  const { mutateAsync: verifyCode } = useVerifyCode();
  const inCompletePage = useTransferFundsStore((s) => s.inCompletePage);
  const saveSecondDetails = useTransferFundsStore((s) => s.saveSecondDetails);
  const [amount, setAmount] = useState("");
  const [code, setCode] = useState("");

  const { SECONDARY_COLOR_DARK } = useThemeColors();

  const sendCode = async () => {
    await requestCode();
  };

  const handleSubmit = () => {
    verifyCode(code).then(() => {
      saveSecondDetails({
        amount,
        code,
      });
      nextPage();
    });
  };

  useEffect(() => {
    if (!!amount.length && !!code.length) completePage();
    else inCompletePage();
    return () => {};
  }, [amount, code]);

  return (
    <HStack
      w={"100%"}
      gap={"54px"}
      justifyContent={"space-between"}
      alignItems={"flex-end"}
    >
      <VStack flexGrow={1} alignItems={"19px"}>
        <Text fontSize={"14px"} fontWeight={"500"}>
          Amount
        </Text>
        <HStack
          width={"100%"}
          borderRadius={"50px"}
          bg={lightMode ? "#f5f5f5" : SECONDARY_COLOR_DARK}
          px={"18px"}
          py={"12px"}
        >
          <Input
            px={0}
            value={amount ? "$" + numberWithCommas(amount) : ""}
            height={"100%"}
            flexGrow={1}
            color={lightMode ? "black" : "white"}
            onChange={(e) => {
              const str = e.target.value.replace("$", "").replaceAll(",", "");

              if (str.length === 0) setAmount("");
              if (/\d/.test(str))
                setAmount(e.target.value.replace("$", "").replaceAll(",", ""));
            }}
            border={"none"}
            outlineColor={"transparent"}
            focusBorderColor={"transparent"}
            _placeholder={{
              color: lightMode ? "#7f7f7f" : "#d0d0d0",
            }}
            fontSize={"14px"}
            bg={"transparent"}
            placeholder="No miminum amount"
          />
          <Text fontSize={"14px"} color={lightMode ? "black" : "white"}>
            USD
          </Text>
        </HStack>
      </VStack>
      <VStack flexGrow={1} alignItems={"19px"}>
        <HStack justifyContent={"space-between"}>
          <HStack alignItems={"center"}>
            <Text fontSize={"14px"} fontWeight={"500"}>
              Security Code
            </Text>
            <Tip />
          </HStack>
          <Text
            onClick={sendCode}
            cursor={"pointer"}
            textDecoration={"underline"}
            color={lightMode ? "#000" : "#fff"}
            fontSize={"14px"}
            fontWeight={"500"}
          >
            Get Code
          </Text>
        </HStack>

        <HStack
          width={"100%"}
          borderRadius={"50px"}
          bg={lightMode ? "#f5f5f5" : SECONDARY_COLOR_DARK}
          px={"18px"}
          py={"12px"}
        >
          <Input
            px={0}
            value={code}
            height={"100%"}
            flexGrow={1}
            color={lightMode ? "black" : "white"}
            onChange={(e) => {
              setCode(e.target.value);
            }}
            border={"none"}
            outlineColor={"transparent"}
            focusBorderColor={"transparent"}
            _placeholder={{
              color: lightMode ? "#7f7f7f" : "#d0d0d0",
            }}
            fontSize={"14px"}
            bg={"transparent"}
            placeholder="Insert code"
          />
        </HStack>
      </VStack>
      <NextButton active={!!amount && !!code} onSubmit={handleSubmit} />
    </HStack>
  );
};

export default AmountInput;
