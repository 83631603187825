import React from 'react'
import { useThemeColors } from '../../../hooks/useThemeColors';

const DepositIcon = () => {
    const { PRIMARY_COLOR } = useThemeColors();
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="33" viewBox="0 0 20 33" fill="none">
            <path d="M18 22L10 30M10 30L2 22M10 30V2" stroke={PRIMARY_COLOR} stroke-width="4" stroke-linecap="round" />
        </svg>
    )
}

export default DepositIcon