import React from "react";
import { usePageContext } from "../contexts/page.context";
import { useThemeColors } from "../../../hooks/useThemeColors";

const SavedIcon = () => {
  const { lightMode } = usePageContext();
  const { PRIMARY_COLOR} = useThemeColors();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="16"
      viewBox="0 0 14 20"
      fill="none"
    >
      <path
        d="M13 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 2.16531e-09 0.734784 2.16531e-09 1V19C-1.25753e-05 19.1912 0.0547687 19.3783 0.157854 19.5393C0.260939 19.7003 0.408009 19.8284 0.58164 19.9083C0.755271 19.9883 0.948188 20.0169 1.13754 19.9906C1.32688 19.9643 1.50473 19.8843 1.65 19.76L7 15.27L12.29 19.71C12.3834 19.8027 12.4943 19.876 12.6161 19.9258C12.7379 19.9755 12.8684 20.0008 13 20C13.1312 20.0034 13.2613 19.976 13.38 19.92C13.5626 19.845 13.7189 19.7176 13.8293 19.5539C13.9396 19.3901 13.999 19.1974 14 19V1C14 0.734784 13.8946 0.48043 13.7071 0.292893C13.5196 0.105357 13.2652 0 13 0ZM12 16.86L7.64 13.2C7.46031 13.0503 7.23385 12.9684 7 12.9684C6.76615 12.9684 6.53969 13.0503 6.36 13.2L2 16.86V2H12V16.86Z"
        fill={lightMode ? PRIMARY_COLOR : "white"}
      />
    </svg>
  );
};

export default SavedIcon;
