import { Box, HStack, VStack } from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { usePageContext } from "../../../contexts/page.context";
import { Menu, MenuButton, MenuItem, MenuList } from "../../../components/menu";
import { Text } from "../../../components/text";
import ErrorIcon from "../../../icons/Error.icon";
import NextButton from "../../../components/NextButton";
import useTransferFundsStore from "../store";
import { getCookie } from "../../../../../utilities/functions";
import useTradingAccounts from "../../accounts/hooks/useTradingAccounts";
import useOpenTrades from "../../sendfunds/hooks/useOpenTrades";
import useWalletStore from "../../../store";
import { useThemeColors } from "../../../../../hooks/useThemeColors";

const InitialStep = () => {
  const { lightMode } = usePageContext();
  const saveFirstDetails = useTransferFundsStore((s) => s.saveFirstDetails);
  const completePage = useTransferFundsStore((s) => s.completePage);
  const inCompletePage = useTransferFundsStore((s) => s.inCompletePage);
  const nextPage = useTransferFundsStore((s) => s.nextPage);
  const companyId = getCookie("companyId");
  const balance = useWalletStore((s) => s.balance);
  const { data: tradingAccounts } = useTradingAccounts(companyId);
  const [source, setSource] = useState();
  const [destination, setDestination] = useState();
  const { data: openSTrades } = useOpenTrades(source?.credentials?.apiId);
  const { data: openDestTrades } = useOpenTrades(
    destination?.credentials?.apiId
  );
  const sourceSet = useWalletStore((s) => s.source);

  const { SECONDARY_COLOR_DARK } = useThemeColors();

  const handleSubmit = () => {
    // const bal = !!balance ? balance : 0
    //     if (
    //       true
    //     ) {
    //       handleError();
    //     } else {
    saveFirstDetails({
      source: source,
      destination: destination,
    });
    nextPage();
    // }
  };

  useEffect(() => {
    if (!!destination && !!source) completePage();
    else inCompletePage();
    return () => {};
  }, [source, destination]);

  const handleError = () => {
    toast.success(
      "Please close open trades to complete the withdrawal process.",
      {
        icon: <ErrorIcon />,
        position: "top-right",
        style: {
          fontSize: "14px",
          boxShadow: "none",
          color: lightMode ? "black" : "white",
          borderRadius: "8px",
          border: `1px solid ${lightMode ? "#e1e1e1" : "#343C40"}`,
          backgroundColor: lightMode ? "#f9f9f9" : SECONDARY_COLOR_DARK,
        },
      }
    );
  };

  useEffect(() => {
    if (!!sourceSet) {
      const src = tradingAccounts?.find((x) => x._id === sourceSet);
      setSource(src);
    }
    return () => {};
  }, [sourceSet]);

  return (
    <HStack
      width={"100%"}
      justifyContent={"space-between"}
      gap={"53px"}
      align={"flex-end"}
    >
      <VStack flexGrow={1} alignItems={"19px"}>
        <Text fontSize={"14px"} fontWeight={"500"}>
          Source Account
        </Text>
        <Box>
          <Menu
            value={
              !!source
                ? source?.cashAccount
                  ? "Cash Account"
                  : `${source?.accountName.split(" - ")[0].split(" -")[0]} - ${
                      source?.credentials?.accountNumber
                    }`
                : ""
            }
            width={"100%"}
            px={"20px"}
            bg={lightMode ? "#f5f5f5" : SECONDARY_COLOR_DARK}
            py={"12px"}
            onChange={(str) => {
              if (str === "Cash Account") {
                const acc = tradingAccounts?.find((x) => x.cashAccount);
                setSource(acc);
              } else {
                const acc = tradingAccounts?.find(
                  (x) =>
                    x.accountName.split(" - ")[0].split(" -")[0] ===
                      str.split(" - ")[0] &&
                    x.credentials.accountNumber === str.split(" - ")[1]
                );

                setSource(acc);
              }
            }}
          >
            <MenuButton
              disabled={!!sourceSet}
              placeholder={"Select source"}
              fontWeight={"500"}
            />
            <MenuList>
              {tradingAccounts
                ?.filter((x) => !x.cashAccount)
                ?.map((x, i) => (
                  <MenuItem key={i}>
                    {x.cashAccount
                      ? `${x.accountName.split(" - ")[0].split(" -")[0]}`
                      : `${x.accountName.split(" - ")[0].split(" -")[0]} - ${
                          x.credentials?.accountNumber
                        }`}
                  </MenuItem>
                ))}
            </MenuList>
          </Menu>
        </Box>
      </VStack>
      <VStack flexGrow={1} alignItems={"19px"}>
        <Text fontSize={"14px"} fontWeight={"500"}>
          Destination Account
        </Text>
        <Box flexGrow={1}>
          <Menu
            width={"100%"}
            px={"20px"}
            bg={lightMode ? "#f5f5f5" : SECONDARY_COLOR_DARK}
            py={"12px"}
            onChange={(str) => {
              if (str === "Cash Account") {
                const acc = tradingAccounts?.find((x) => x.accountName === str);
                setDestination(acc);
              } else {
                const acc = tradingAccounts?.find(
                  (x) =>
                    x.accountName.split(" - ")[0].split(" -")[0] ===
                      str.split(" - ")[0] &&
                    x.credentials.accountNumber == str.split(" - ")[1]
                );
                setDestination(acc);
              }
            }}
            value={
              !!destination
                ? destination?.cashAccount
                  ? "Cash Account"
                  : `${
                      destination?.accountName.split(" - ")[0].split(" -")[0]
                    } - ${destination?.credentials?.accountNumber}`
                : ""
            }
          >
            <MenuButton placeholder={"Select destination"} fontWeight={"500"} />
            <MenuList>
              {tradingAccounts
                ?.filter((x) =>
                  x._id === source?._id ? false : x.cashAccount ? false : true
                )
                ?.map((x, i) => (
                  <MenuItem key={i}>
                    {x.cashAccount
                      ? `${x.accountName.split(" - ")[0].split(" -")[0]}`
                      : `${x.accountName.split(" - ")[0].split(" -")[0]} - ${
                          x.credentials?.accountNumber
                        }`}
                  </MenuItem>
                ))}
            </MenuList>
          </Menu>
        </Box>
      </VStack>
      <NextButton active={!!source && !!destination} onSubmit={handleSubmit} />
    </HStack>
  );
};

export default InitialStep;
