import { Box, Grid, HStack, VStack } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import { Text } from "../../../../components/text";
import CaretUpIcon from "../../../../icons/CaretUp.icon";
import { useSendfundsContext } from "../../sendfunds.context";
import { usePageContext } from "../../../../contexts/page.context";
import MenuTip from "../tip/MenuTip";
import DotMenuIcon from "./../../../../icons/DotMenu.icon";
import PencilIcon from "../../../../icons/Pencil.icon";
import TrashIcon from "../../../../icons/Trash.icon";
import TextInput from "../bank/TextInput";
import useSendFundsStore from "./../../store";
import { useState, useEffect } from "react";
import useSavedAddresses from "../../hooks/useSavedAddresses";
import useEditAddress from "../../hooks/useEditAddress";
import { useQueryClient } from "@tanstack/react-query";
import useDeleteAddress from "../../hooks/useDeleteAddress";
import { useThemeColors } from "../../../../../../hooks/useThemeColors";
import { useDomainContext } from "../../../../../../contexts/DomainContext";

const SavedBank = () => {
  const queryClient = useQueryClient();
  const { data: savedAddresses } = useSavedAddresses("wire-transfer");
  const { lightMode } = usePageContext();
  const [isEditing, setIsEditing] = useState();
  const [selected, setSelected] = useState();
  const [isDeleting, setIsDeleting] = useState();
  const [bankName, setBankName] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [address, setAddress] = useState("");
  const [sortCode, setSortCode] = useState("");
  const [swift, setSwift] = useState("");
  const saveBankDetails = useSendFundsStore((s) => s.saveBankDetails);
  const toggleBankAddresses = useSendFundsStore((s) => s.toggleBankAddresses);
  const RED = lightMode ? "#BD3437" : "#FD6B6E";
  const { mutateAsync: editAddress } = useEditAddress();
  const { mutateAsync: deleteAddress } = useDeleteAddress();

  const { PRIMARY_COLOR, SECONDARY_COLOR_DARK } = useThemeColors();

  const { companyTheme } = useDomainContext();

  const update = () => {
    editAddress({
      name: `${bankName} - ${accountNumber}`,
      wireBankName: bankName,
      wireAccountName: accountName,
      wireAccountNumber: accountNumber,
      wireAddress: address,
      wireBranch: sortCode,
      wireSwift: swift,
      id: savedAddresses[isEditing]._id,
    }).then(() => {
      queryClient.refetchQueries(["saved addresses"]);
      setIsEditing(undefined);
    });
  };

  const remove = () => {
    deleteAddress(savedAddresses[isDeleting]?._id).then(() => {
      queryClient.refetchQueries("saved addresses");
      setIsDeleting(undefined);
      setIsEditing(undefined);
      setSelected(undefined);
    });
  };

  const confirmSavedBank = () => {
    saveBankDetails({
      bankName: savedAddresses[selected]?.wireBankName,
      accountName: savedAddresses[selected]?.wireAccountName,
      accountNumber: savedAddresses[selected]?.wireAccountNumber,
      swift: savedAddresses[selected]?.wireSwift,
      sortCode: savedAddresses[selected]?.wireBranch,
      address: savedAddresses[selected]?.wireAddress,
      saveAddress: false,
    });
  };

  useEffect(() => {
    if (isEditing !== undefined) {
      setAccountNumber(savedAddresses[isEditing].wireAccountNumber);
      setBankName(savedAddresses[isEditing].wireBankName);
      setAccountName(savedAddresses[isEditing].wireAccountName);
      setAddress(savedAddresses[isEditing].wireAddress);
      setSortCode(savedAddresses[isEditing].wireBranch);
      setSwift(savedAddresses[isEditing].wireSwift);
    }

    return () => {};
  }, [isEditing]);

  return (
    <VStack w={"100%"}>
      <VStack pl={"64px"} mb={"20px"} pr={"56px"} w={"100%"}>
        <HStack
          w={"100%"}
          pl={"20px"}
          mb={"20px"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Text>Saved Addresses</Text>

          <HStack
            onClick={() => toggleBankAddresses()}
            cursor={"pointer"}
            alignItems={"center"}
            gap={"6px"}
          >
            <Text
              textDecoration={lightMode ? "" : "underline"}
              color={lightMode ? PRIMARY_COLOR : "white"}
            >
              Hide Saved
            </Text>
            <CaretUpIcon />
          </HStack>
        </HStack>

        <HStack w={"100%"} gap={"61px"} alignItems={"flex-start"}>
          <VStack alignItems={"flex-start"} w={"266px"} maxW={"266px"}>
            {savedAddresses?.map((x, i) => (
              <HStack w={"100%"} position={"relative"}>
                <HStack
                  width={"100%"}
                  cursor={"pointer"}
                  onClick={() => {
                    setIsDeleting(undefined);
                    setIsEditing(undefined);
                    setSelected(i);
                  }}
                  borderWidth={"1px"}
                  borderRadius={"50px"}
                  borderColor={
                    isDeleting === i
                      ? RED
                      : selected === i
                      ? PRIMARY_COLOR
                      : lightMode
                      ? "black"
                      : "white"
                  }
                  bg={
                    isDeleting === i
                      ? "transparent"
                      : selected === i
                      ? lightMode
                        ? "#E5F1F7"
                        : SECONDARY_COLOR_DARK
                      : "transparent"
                  }
                  height={"48px"}
                  justifyContent={"space-between"}
                  py={"12px"}
                  pl={"20px"}
                  key={i}
                >
                  <Text
                    fontSize={"14px"}
                    color={
                      isDeleting === i
                        ? RED
                        : selected === i
                        ? PRIMARY_COLOR
                        : lightMode
                        ? "black"
                        : "white"
                    }
                  >
                    {!!x.name && <>{x.name}</>}
                    {!x.name && (
                      <>
                        {x.bankName} - {x.accountNumber}
                      </>
                    )}
                  </Text>
                </HStack>
                <Box>
                  {isDeleting !== undefined && isDeleting === i ? (
                    <Button
                      position={"absolute"}
                      right={"16px"}
                      top={"50%"}
                      transform={"translate(0%, -50%)"}
                      zIndex={"100"}
                      bg={RED}
                      w={"90px"}
                      display={"flex"}
                      fontSize={"13px"}
                      justifyContent={"center"}
                      color={"#fff"}
                      fontWeight={"700"}
                      _hover={{
                        color: "#ffffff",
                      }}
                      onClick={() => {
                        remove();
                      }}
                      h={"30px"}
                      borderRadius={"31px"}
                      alignItems={"center"}
                    >
                      Remove
                    </Button>
                  ) : (
                    <MenuTip
                      value={selected}
                      isEditing={isEditing}
                      isDeleting={isDeleting}
                      icon={
                        <DotMenuIcon
                          color={
                            isDeleting === i
                              ? RED
                              : selected === i
                              ? PRIMARY_COLOR
                              : lightMode
                              ? "black"
                              : "white"
                          }
                        />
                      }
                    >
                      <VStack
                        gap={"10px"}
                        alignItems={"flex-start"}
                        px={"11px"}
                      >
                        <HStack
                          onClick={() => {
                            setIsEditing(i);
                            setSelected(i);
                          }}
                          cursor={"pointer"}
                          gap={"10px"}
                        >
                          <PencilIcon />
                          <Text fontSize={"12px"} letterSpacing={"1.2px"}>
                            Edit
                          </Text>
                        </HStack>
                        <HStack
                          onClick={() => {
                            setIsDeleting(i);
                            setSelected(i);
                          }}
                          cursor={"pointer"}
                          gap={"10px"}
                        >
                          <TrashIcon />
                          <Text
                            color={RED}
                            fontSize={"12px"}
                            letterSpacing={"1.2px"}
                          >
                            Remove
                          </Text>
                        </HStack>
                      </VStack>
                    </MenuTip>
                  )}
                </Box>
              </HStack>
            ))}
          </VStack>

          {selected !== undefined && !isEditing && (
            <HStack gap={0} flexGrow={1} alignItems={"flex-end"}>
              <VStack gap={"5px"} flexGrow={1} alignItems={"flex-start"}>
                <Text fontSize={"13px"} mb={"5px"}>
                  Bank Details
                </Text>
                {companyTheme === "default" && <Text fontSize={"12px"}>
                  Bank Name:{" "}
                  <Text color={"7f7f7f"} display={"inline-block"}>
                    {savedAddresses[selected]?.wireBankName}
                  </Text>
                </Text>}
                <Text fontSize={"12px"}>
                  Account Name:{" "}
                  <Text color={"7f7f7f"} display={"inline-block"}>
                    {savedAddresses[selected]?.wireAccountName}
                  </Text>
                </Text>
                {companyTheme === "default" && <Text fontSize={"12px"}>
                IBAN:{" "}
                  <Text color={"7f7f7f"} display={"inline-block"}>
                    {savedAddresses[selected]?.wireAccountNumber}
                  </Text>
                </Text>}
                <Text fontSize={"12px"}>
                  Address:{" "}
                  <Text color={"7f7f7f"} display={"inline-block"}>
                    {savedAddresses[selected]?.wireAddress}
                  </Text>
                </Text>
                <Text fontSize={"12px"}>
                  Branch/Sort Code:{" "}
                  <Text color={"7f7f7f"} display={"inline-block"}>
                    {savedAddresses[selected]?.wireBranch}
                  </Text>
                </Text>
                <Text fontSize={"12px"}>
                  Swift:{" "}
                  <Text color={"7f7f7f"} display={"inline-block"}>
                    {savedAddresses[selected]?.wireSwift}
                  </Text>
                </Text>
              </VStack>
              <Button
                w={"150px"}
                h={"48px"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                color={"white"}
                bg={PRIMARY_COLOR}
                borderRadius={"50px"}
                onClick={() => {
                  confirmSavedBank(selected);
                  toggleBankAddresses();
                }}
              >
                Use this
              </Button>
            </HStack>
          )}
        </HStack>
      </VStack>
      {isEditing !== undefined && (
        <VStack
          w={"100%"}
          pl={"74px"}
          borderTopWidth={"1px"}
          pt={"25px"}
          borderTopColor={lightMode ? "#e1e1e1" : "#343c40"}
        >
          <Grid
            as={"form"}
            w={"100%"}
            gap={"54px"}
            gridTemplateColumns={"7fr 10fr"}
          >
            <VStack gap={"34px"}>
            {companyTheme === "default" && <VStack gap={"19px"} w={"100%"} alignItems={"flex-start"}>
                <Text
                  as={"label"}
                  htmlFor={"bank-name"}
                  fontSize={"14px"}
                  fontWeight={"500"}
                >
                  Bank Name
                </Text>
                <TextInput
                  value={bankName}
                  onChange={(e) => {
                    setBankName(e.target.value);
                  }}
                  id={"bank-name"}
                  placeholder={"Insert bank name"}
                />
              </VStack>}
              {companyTheme === "default" && <VStack gap={"19px"} w={"100%"} alignItems={"flex-start"}>
              <Text
                  as={"label"}
                  htmlFor={"account-number"}
                  fontSize={"14px"}
                  fontWeight={"500"}
                >
                  IBAN
                </Text>
                <TextInput
                  value={accountNumber}
                  onChange={(e) => {
                    setAccountNumber(e.target.value);
                  }}
                  id={"account-number"}
                  placeholder={"Insert IBAN"}
                />
              </VStack>}
              <VStack gap={"19px"} w={"100%"} alignItems={"flex-start"}>
                <Text
                  as={"label"}
                  htmlFor={"sort-code"}
                  fontSize={"14px"}
                  fontWeight={"500"}
                >
                  Branch/Sort Code
                </Text>
                <TextInput
                  value={sortCode}
                  onChange={(e) => {
                    setSortCode(e.target.value);
                  }}
                  id={"sort-code"}
                  placeholder={"Insert branch or sort code"}
                />
              </VStack>
            </VStack>
            <VStack gap={"34px"}>
              <VStack gap={"19px"} w={"100%"} alignItems={"flex-start"}>
                <Text
                  as={"label"}
                  htmlFor={"account-name"}
                  fontSize={"14px"}
                  fontWeight={"500"}
                >
                  Account Name
                </Text>
                <TextInput
                  value={accountName}
                  onChange={(e) => {
                    setAccountName(e.target.value);
                  }}
                  id={"account-name"}
                  placeholder={"Insert account name"}
                />
              </VStack>
              <VStack gap={"19px"} w={"100%"} alignItems={"flex-start"}>
                <Text
                  as={"label"}
                  htmlFor={"account-name"}
                  fontSize={"14px"}
                  fontWeight={"500"}
                >
                  Address
                </Text>
                <TextInput
                  value={address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                  id={"address"}
                  placeholder={"Insert bank's address"}
                />
              </VStack>
              <VStack gap={"19px"} w={"100%"} alignItems={"flex-start"}>
                <Text
                  as={"label"}
                  htmlFor={"swift"}
                  fontSize={"14px"}
                  fontWeight={"500"}
                >
                  Swift
                </Text>
                <HStack w={"100%"} gap={"33px"}>
                  <TextInput
                    value={swift}
                    onChange={(e) => {
                      setSwift(e.target.value);
                    }}
                    id={"swift"}
                    placeholder={"Insert swift"}
                  />
                  <Button
                    w={"150px"}
                    h={"48px"}
                    onClick={update}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    color={"white"}
                    bg={PRIMARY_COLOR}
                    borderRadius={"50px"}
                  >
                    Update
                  </Button>
                </HStack>
              </VStack>
            </VStack>
          </Grid>
        </VStack>
      )}
    </VStack>
  );
};

export default SavedBank;
