import React, { PureComponent } from "react";
import styles from "./ChartComponent.module.css";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Surface,
  Symbols,
  Line,
} from "recharts";

import _ from "lodash";
import { env } from "../../../constants/env";
import { numberWithCommas } from "../../../helpers/GetDuration";
import Bubble from "./Bubble";
import ChartCustomTooltip from "./components/ChartCustomTooltip";

const mobile = window.innerWidth > 768 ? false : true;

// CHART BUTTON
export const Button = ({ children, active, onClick }) => {
  return (
    <div
      className={`${active && styles.btnDiv} ${styles.btnHover} ${styles.btnStyle} d-flex justify-content-center align-items-center`}
      // style={btnStyle}
      onClick={onClick}
    >
      <div className={styles.btn}>{children}</div>
    </div>
  );
};




const data = [
  {
    date: "10th Oct",
    balance: 3000,
    equity: 4400,
    // amt: 2400,
  },
  {
    date: "20th Oct",
    balance: 9000,
    equity: 8666,
    amt: 2210,
  },
  {
    date: "1st Nov",
    balance: 2000,
    equity: 3800,
    // amt: 2290,
  },
  {
    date: "10th Nov",
    balance: 9780,
    equity: 9208,
    // amt: 2000,
  },
  {
    date: "1st Dec",
    balance: 2780,
    equity: 3908,
    // amt: 2000,
  },
];

export default class ChartComponent extends PureComponent {
  static demoUrl = "https://codesandbox.io/s/stacked-area-chart-ix341";
  constructor(props) {
    super(props);
    this.state = {
      chartColors: {
        balance: {fill:"linear-gradient(#e66465, #9198e5);", stroke: "#01486D"},
      },
      dollarActive: true,
      percentageActive: false,
      oneDayActive: false,
      fiveDaysActive: false,
      oneMonthActive: false,
      oneYearActive: false,
      yearToDateActive: false,
      allActive: true,
    };
  }

  

  renderCusomizedLegend = ({ payload }) => {
    return (
      <div className="customized-legend">
        {payload.map(entry => {
          const { dataKey, color } = entry;
          const style = {
            marginRight: "30px",
          };

          return (
            <span
              className="legend-item"
              onClick={() => this.handleClick(dataKey)}
              style={style}
            >
              <Surface width={20} height={20} viewBox="0 0 10 10" style={{border: `7px solid ${color.fill}`, borderRadius: "30px"}}>
              </Surface>
              <span className={`${styles.lengendText}`}>{dataKey}</span>
            </span>
          );
        })}
      </div>
    );
  };

  render() {
    const {currentBalance, maxBalance, data, query, primaryColor, accentColor} = this.props
    return (
      <div className={styles.chartContainer}>
        {/* <div className={`d-flex ${mobile && "flex-column"}  justify-content-${mobile ? "center" : "between"} align-items-center ${styles.chartBtnsDiv}`}>
          <div className={styles.chartBtnsLeft}>
          ${currentBalance}
          </div>
          <div className={styles.chartBtnsRight}>
            <Button
              active={this.state.oneDayActive}
              onClick={() => {
                query(-1)
                this.setState({
                  oneDayActive: true,
                  fiveDaysActive: false,
                  oneMonthActive: false,
                  oneYearActive: false,
                  yearToDateActive: false,
                  allActive: false,
                })
              }
              }
            >
              1D
            </Button>
            <Button
              active={this.state.fiveDaysActive}
              onClick={() =>{
                query(-7)
                this.setState({
                  oneDayActive: false,
                  fiveDaysActive: true,
                  oneMonthActive: false,
                  oneYearActive: false,
                  yearToDateActive: false,
                  allActive: false,
                })
              }
              }
            >
              1W
            </Button>
            <Button
              active={this.state.oneMonthActive}
              onClick={() =>{
                query(-30)
                this.setState({
                  oneDayActive: false,
                  fiveDaysActive: false,
                  oneMonthActive: true,
                  oneYearActive: false,
                  yearToDateActive: false,
                  allActive: false,
                })
              }
              }
            >
              1M
            </Button>
            <Button
              active={this.state.oneYearActive}
              onClick={() => {
                query(-365)
                this.setState({
                  oneDayActive: false,
                  fiveDaysActive: false,
                  oneMonthActive: false,
                  oneYearActive: true,
                  yearToDateActive: false,
                  allActive: false,
                })
              }
              }
            >
              3M
            </Button>
            <Button
              active={this.state.yearToDateActive}
              onClick={() =>{
                query(-90)
                this.setState({
                  oneDayActive: false,
                  fiveDaysActive: false,
                  oneMonthActive: false,
                  oneYearActive: false,
                  yearToDateActive: true,
                  allActive: false,
                })
              }
              }
            >
              Y
            </Button>
            <Button
              active={this.state.allActive}
              onClick={() =>{
                query("all")
                this.setState({
                  oneDayActive: false,
                  fiveDaysActive: false,
                  oneMonthActive: false,
                  oneYearActive: false,
                  yearToDateActive: false,
                  allActive: true,
                })
              }
              }
            >
              ALL
            </Button>
          </div>
        </div> */}
        <div
          className={styles.chartInner}
          style={{
            height: "500px",
            backgroundColor: "white",
            padding: "1rem",
            marginTop: "3rem",
          }}
          id="print"
        >
          <ResponsiveContainer width={mobile ? "90%" : "100%"} height="100%" >
            <AreaChart
              width={500}
              height={400}
              data={data}
              vertical={false}
              horizontal={false}
              margin={{
                top: 50,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0.18%" stopColor={accentColor} stopOpacity={0.5}/>
            <stop offset="42.73%" stopColor={accentColor} stopOpacity={0.25}/>
            <stop offset="99.82%" stopColor="#FFFFFF" stopOpacity={0}/>
          </linearGradient>
        </defs>
              {/* <CartesianGrid stroke="#fff" strokeDasharray="1 1" /> */}
              <XAxis  hide={true} tickLine={false} />
              <YAxis orientation="right" axisLine={false} tickLine={false} hide={true}/>
   
              <Tooltip content={<ChartCustomTooltip />} cursor={{ fill: "transparent" }} />
              {/* {_.toPairs(this.state.chartColors)
              .map((pair, i) => ( */}

<Area type="monotone" dataKey="balance" strokeWidth={3} stroke={accentColor} fillOpacity={1} fill="url(#colorUv)"/>
               {/*  <Bar
                //   stackId="a"
                //   key={pair[0]}
                //   dataKey={pair[0]}
                //   fill={pair[1]}
                // />
              // ))}
              {/* <Area
                type="monotone"
                dataKey="equity"
                stackId="1"
                stroke="#E8CD5D"
                fill="rgba(255, 217, 19, 1)"
                fillOpacity={0.8}
              />
              <Area
                type="monotone"
                dataKey="balance"
                stackId="1"
                stroke="#3AD07F"
                fill="rgba(3, 207, 97, 0.5)"
                fillOpacity={0.8}
              /> */}
              {/* <Area type="monotone" dataKey="amt" stackId="1" stroke="#ffc658" fill="#ffc658" /> */}
              {/* <Legend
              wrapperStyle={{top: -30, left: 80, textAlign: "left"}}
              payload={_.toPairs(this.state.chartColors).map(pair => ({
                dataKey: pair[0],
                color: pair[1]
              }))}
              content={this.renderCusomizedLegend}
            /> */}
            </AreaChart>
           
          </ResponsiveContainer>
          <div className={`${styles.yAxisContainer} d-flex flex-column align-items-center justify-content-end`}>
            <Bubble balance={maxBalance?.max} descText={"Peak balance"}/>
            <Bubble balance={maxBalance?.average} descText={"Mid balance"}/>
            <Bubble balance={maxBalance?.min} descText={"Lowest balance"}/>
          </div>
        </div>
        <div className={`${styles.borderContainer} d-flex justify-content-center align-items-center`}>
        <div className={styles.bottomBorder}></div>
        </div>
       
        <div className={`${styles.chartBtns} d-flex justify-content-center align-items-center gap-4`}>
            <Button
              active={this.state.oneDayActive}
              onClick={() => {
                query(1)
                this.setState({
                  oneDayActive: true,
                  fiveDaysActive: false,
                  oneMonthActive: false,
                  oneYearActive: false,
                  yearToDateActive: false,
                  allActive: false,
                })
              }
              }
            >
              1D
            </Button>
            <Button
              active={this.state.fiveDaysActive}
              onClick={() =>{
                query(7)
                this.setState({
                  oneDayActive: false,
                  fiveDaysActive: true,
                  oneMonthActive: false,
                  oneYearActive: false,
                  yearToDateActive: false,
                  allActive: false,
                })
              }
              }
            >
              1W
            </Button>
            <Button
              active={this.state.oneMonthActive}
              onClick={() =>{
                query(30)
                this.setState({
                  oneDayActive: false,
                  fiveDaysActive: false,
                  oneMonthActive: true,
                  oneYearActive: false,
                  yearToDateActive: false,
                  allActive: false,
                })
              }
              }
            >
              1M
            </Button>
            <Button
              active={this.state.oneYearActive}
              onClick={() => {
                query(90)
                this.setState({
                  oneDayActive: false,
                  fiveDaysActive: false,
                  oneMonthActive: false,
                  oneYearActive: true,
                  yearToDateActive: false,
                  allActive: false,
                })
              }
              }
            >
              3M
            </Button>
            <Button
              active={this.state.yearToDateActive}
              onClick={() =>{
                query(365)
                this.setState({
                  oneDayActive: false,
                  fiveDaysActive: false,
                  oneMonthActive: false,
                  oneYearActive: false,
                  yearToDateActive: true,
                  allActive: false,
                })
              }
              }
            >
              Y
            </Button>
            <Button
              active={this.state.allActive}
              onClick={() =>{
                query('all')
                this.setState({
                  oneDayActive: false,
                  fiveDaysActive: false,
                  oneMonthActive: false,
                  oneYearActive: false,
                  yearToDateActive: false,
                  allActive: true,
                })
              }
              }
            >
              ALL
            </Button>
        </div>
      </div>
    );
  }
}
