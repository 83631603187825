import { useQuery } from '@tanstack/react-query'
import ms from 'ms'
import React from 'react'
import PBAPIClient from '../../../../../services/prime-client'
import { getCookie } from '../../../../../utilities/functions'

const useWalletAccounts = () => {
    const authToken = getCookie("authToken")
    const companyId = getCookie("companyId")
    const userType = getCookie("userType")
    const userId = getCookie("userId")

    console.log("USERTYPE", userId)


    const idToUse = userType === "under-company" ? userId : companyId;

    const apiClient = new PBAPIClient(`/account/wallet-accounts/${idToUse}`, authToken, userType);


    return useQuery({
        queryKey: ["wallet-accounts"],
        queryFn: () => apiClient.get(),
        staleTime: ms("1h"),
        enabled: !!idToUse
    })

}

export default useWalletAccounts