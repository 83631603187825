import { Box, Grid, GridItem, HStack, VStack } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { toast } from "react-hot-toast";
import InfoIcon from "../../../../../../icons/InfoIcon";
import { numberWithCommas } from "../../../../../../utilities/functions";
import { usePageContext } from "../../../../contexts/page.context";
import CopyIcon from "../../../../components/Copy.icon";
import { Text } from "../../../../components/text";
import AlertIcon from "../../../../icons/Alert.icon";
import SuccessIcon from "../../../../icons/Success.icon";
import useAddFundsStore from "../../store";
import useWalletStore from "./../../../../store";
import Tip from "../tip/Tip";
import useAddFunds from "../../hooks/useAddFunds";
import { useSelector } from "react-redux";
import { useThemeColors } from "../../../../../../hooks/useThemeColors";

const BankDetails = () => {
  const { lightMode } = usePageContext();
  const amount = useAddFundsStore((s) => s.secondDetails.amount);
  const closeSection = useWalletStore((s) => s.closeSection);
  const resetPage = useAddFundsStore((s) => s.resetPage);
  const completePage = useAddFundsStore((s) => s.completePage);
  const inCompletePage = useAddFundsStore((s) => s.inCompletePage);
  const refNumber = useSelector((state) => state.company.refNumber);
  const inputSource = useWalletStore((s) => s.inputSource);
  const depositSettings = useSelector((state) => state.company.depositSettings);
  const { mutateAsync: addFunds } = useAddFunds();
  const amountNum = parseFloat(amount.replace(/,/g, ""));

  const { PRIMARY_COLOR, SECONDARY_COLOR, SECONDARY_COLOR_DARK } = useThemeColors();

  const feePercent =
    amountNum >= Math.pow(10, 4) && amountNum < Math.pow(10, 5)
      ? 0.2
      : amountNum >= Math.pow(10, 5) && amountNum < Math.pow(10, 6)
      ? 0.1
      : amountNum >= Math.pow(10, 6) && amountNum < Math.pow(10, 6) * 5
      ? 0.05
      : amountNum >= Math.pow(10, 6) * 5 && amountNum < Math.pow(10, 6) * 10
      ? 0.025
      : amountNum >= Math.pow(10, 6) * 10
      ? 0.0125
      : 0;
  const depositFee = feePercent * amountNum;
  const totalWithdrawal = amountNum + depositFee;

  useEffect(() => {
    completePage();
    return () => {
      inCompletePage();
    };
  }, []);
  async function copy(str) {
    try {
      await navigator.clipboard.writeText(str);
      toast.success("Text copied to clipboard.", {
        icon: <InfoIcon />,
        position: "top-right",
        style: {
          fontSize: "14px",
          color: lightMode ? "black" : "white",
          borderRadius: "4px",
          backgroundColor: lightMode ? "#f9f9f9" : SECONDARY_COLOR_DARK,
        },
      });
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  }

  const handleSubmit = () => {
    addFunds().then(() => {
      resetPage();
      inputSource("");
      closeSection();
    });
  };

  const DotIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="9"
        viewBox="0 0 8 9"
        fill="none"
      >
        <circle cx="4" cy="4.0166" r="4" fill={PRIMARY_COLOR} />
      </svg>
    );
  };

  const Field = ({ children }) => (
    <HStack gap={"13px"} fontSize={"14px"}>
      <Text color={"#7f7f7f"}>{children}</Text>
      <Box cursor={"pointer"} onClick={() => copy(children)}>
        <CopyIcon />
      </Box>
    </HStack>
  );

  return (
    <VStack w={"100%"}>
      <VStack
        id="toggleDetails"
        w={"100%"}
        px={"20px"}
        py={"27px"}
        borderTop={`1px solid ${lightMode ? "#e1e1e1" : ""}`}
      >
        <Grid w={"100%"} gap={"80px"} gridTemplateColumns={"7fr 3fr"}>
          <GridItem as={VStack} alignItems={"flex-start"}>
            <Text mb={"16px"} fontSize={"14px"} fontWeight={"600"}>
              Custody Bank Account Details
            </Text>
            <VStack gap={"8px"} w={"100%"}>
              <HStack
                justifyContent={"space-between"}
                alignItems={"center"}
                w={"100%"}
              >
                <Text fontSize={"14px"}>Bank Name:</Text>
                <Field>FirstRand Bank Limited</Field>
              </HStack>
              <HStack
                justifyContent={"space-between"}
                alignItems={"center"}
                w={"100%"}
              >
                <Text fontSize={"14px"}>Address:</Text>
                <Field>TD MARKETS (PTY) LTD</Field>
              </HStack>
              <HStack
                justifyContent={"space-between"}
                alignItems={"center"}
                w={"100%"}
              >
                <Text fontSize={"14px"}>Account Number:</Text>
                <Field>62794410010</Field>
              </HStack>
              <HStack
                justifyContent={"space-between"}
                alignItems={"center"}
                w={"100%"}
              >
                <Text fontSize={"14px"}>Address:</Text>
                <Field>
                  Merchant Place Corner Fredman Drive and Rivonia Rd, Sandton
                </Field>
              </HStack>
              <HStack
                justifyContent={"space-between"}
                alignItems={"center"}
                w={"100%"}
              >
                <Text fontSize={"14px"}>Branch/Sort Code:</Text>
                <Field>254655</Field>
              </HStack>
              <HStack
                justifyContent={"space-between"}
                alignItems={"center"}
                w={"100%"}
              >
                <Text fontSize={"14px"}>Swift:</Text>
                <Field>FIRNZAJJXXX</Field>
              </HStack>
            </VStack>
          </GridItem>
          <GridItem as={VStack} alignItems={"flex-start"}>
            <Text mb={"10px"} fontSize={"14px"}>
              How it works
            </Text>
            <VStack gap={0} alignItems={"flex-start"}>
              <HStack gap={0}>
                <DotIcon />
                <Text
                  pl={"11px"}
                  lineHeight={"22px"}
                  fontSize={"12px"}
                  fontWeight={"500"}
                >
                  Transfer Money
                </Text>
              </HStack>
              <Text
                ml={"4px"}
                pl={"26px"}
                py={"5px"}
                lineHeight={"22px"}
                fontSize={"12px"}
                borderLeft={`1px solid ${lightMode ? "#e1e1e1" : "#343C40"}`}
                fontWeight={"500"}
                color={lightMode ? "#7f7f7f" : "#d0d0d0"}
              >
                Transfer your money to Prime Bridge account
              </Text>
              <HStack gap={0}>
                <DotIcon />
                <Text
                  pl={"11px"}
                  lineHeight={"22px"}
                  fontSize={"12px"}
                  fontWeight={"500"}
                >
                  Process Order
                </Text>
              </HStack>
              <Text
                ml={"4px"}
                pl={"26px"}
                py={"5px"}
                lineHeight={"22px"}
                fontSize={"12px"}
                borderLeft={`1px solid ${lightMode ? "#e1e1e1" : "#343C40"}`}
                fontWeight={"500"}
                color={lightMode ? "#7f7f7f" : "#d0d0d0"}
              >
                3 to 5 business days for the fund to arrive
              </Text>
              <HStack gap={0}>
                <DotIcon />
                <Text
                  pl={"11px"}
                  lineHeight={"22px"}
                  fontSize={"12px"}
                  fontWeight={"500"}
                >
                  Funds Arrived
                </Text>
              </HStack>
              <Text
                ml={"4px"}
                pl={"26px"}
                py={"5px"}
                lineHeight={"22px"}
                fontSize={"12px"}
                fontWeight={"500"}
                color={lightMode ? "#7f7f7f" : "#d0d0d0"}
              >
                Receive your deposit amount
              </Text>
            </VStack>
          </GridItem>
        </Grid>
      </VStack>
      <HStack
        borderTop={`1px solid ${lightMode ? "#e1e1e1" : ""}`}
        w={"100%"}
        px={"20px"}
        py={"27px"}
        justifyContent={"space-between"}
        alignItems={"flex-end"}
      >
        <VStack gap={0} alignItems={"flex-start"}>
          <Text mb={"26px"} fontSize={"14px"} fontWeight={"600"}>
            Deposit Details
          </Text>
          <Text mb={"19px"} fontSize={"14px"} fontWeight={"600"}>
            Amount: US${numberWithCommas(amount)}
          </Text>
          <HStack mb={"12px"}>
            <Text fontSize={"14px"} fontWeight={"600"}>
              Allocation Fee: US$
              {numberWithCommas(depositFee.toFixed(2))}
            </Text>
            <Tip />
          </HStack>
          <HStack alignItems={"flex-end"} mb={"9px"}>
            <Text fontSize={"14px"} lineHeight={"13px"} fontWeight={"600"}>
              Total to Send:
            </Text>
            <Text
              fontSize={"20px"}
              fontWeight={"500"}
              lineHeight={"20px"}
              color={PRIMARY_COLOR}
            >
              US$
              {numberWithCommas(totalWithdrawal.toFixed(2))}
            </Text>
          </HStack>
        </VStack>
        {!!refNumber && (
          <HStack
            width={"320px"}
            borderRadius={"10PX"}
            bg={lightMode ? SECONDARY_COLOR : SECONDARY_COLOR_DARK}
            gap={"11px"}
            py={"12px"}
            px={"15px"}
          >
            <AlertIcon />
            <Text fontSize={"14px"} fontWeight={"500"}>
              Add{" "}
              <Text
                color={lightMode ? PRIMARY_COLOR : "white"}
                display={"inline-block"}
                fontWeight={"700"}
              >
                {refNumber}
              </Text>{" "}
              as your reference code
            </Text>
          </HStack>
        )}

        <Button
          width={"213px"}
          gap={"10px"}
          borderRadius={"31px"}
          bg={PRIMARY_COLOR}
          _hover={{
            color: lightMode ? "white" : PRIMARY_COLOR,
          }}
          fontWeight={"700"}
          color={"white"}
          height={"48px"}
          onClick={handleSubmit}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          fontSize={"14px"}
        >
          Submit
        </Button>
      </HStack>
    </VStack>
  );
};

export default BankDetails;
