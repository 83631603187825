import React, { useEffect, useLayoutEffect, useState } from "react";
import styles from "./Trading.module.css";
import { OpenTradesData } from "../../../data/OpenTradesData";
import { ClosedTradesData } from "../../../data/ClosedTradesData";
import { getCurrentTime, getTodaysDate } from "../../../helpers/GetTimeDate";
import { env } from "../../../constants/env";
import { useDispatch, useSelector } from "react-redux";
import { fetchClosedTrades } from "../../../Redux/trades-actions";
import ReactPaginate from "react-paginate";
import { useThemeColors } from "../../../hooks/useThemeColors";
import { getCookie } from "../../../utilities/functions";


const userId = getCookie("userId");
const userType = getCookie("userType");
const companyId = getCookie("companyId"); // Assuming companyId is stored as a cookie




// OPEN TRADES HEAD
const OpenTradesHeader = () => {
  const { PRIMARY_COLOR} = useThemeColors();
  return (
    <>
      <tr style={{color: PRIMARY_COLOR}}>
        <th>Open Time</th>
        <th>Type</th>
        <th>Size</th>
        <th>Symbol</th>
        <th>Open</th>
        <th>S/L</th>
        <th>T/P</th>
        <th>Profit</th>
        <th>Pips</th>
        <th>Swap</th>
      </tr>
    </>
  );
};

// CLOSED TRADES HEAD
const ClosedTradesHeader = () => {
  const { PRIMARY_COLOR } = useThemeColors();
  return (
    <>
      <tr style={{color: PRIMARY_COLOR}}>
        <th>Open Time</th>
        <th>Type</th>
        <th>Size</th>
        <th>Symbol</th>
        <th>Open</th>
        <th>S/L</th>
        <th>T/P</th>
        {/* <th>Pnl</th> */}
        <th>Close Time</th>
        <th>Close</th>
        <th>Duration</th>
        <th>Commission</th>
        <th>Profit,$</th>
        {/* <th>Order</th>
        <th>Swap</th> */}
        <th>Comment</th>
      </tr>
    </>
  );
};

// OPEN TRADES ROW COMPONENT
const OpenTradesRow = ({
  openTime,
  type,
  size,
  symbol,
  open,
  sl,
  tp,
  commission,
  profit,
  pips,
  swap,
}) => {
  return (
    <tr>
      <td>
        <p>{openTime?.time}</p>
        <p>{openTime?.date}</p>
      </td>
      <td>{type}</td>
      <td>{size}</td>
      <td style={{ fontWeight: "bold" }}>{symbol}</td>
      <td>{open}</td>
      <td>{sl}</td>
      <td>{tp}</td>
      {/* <td>
        <p>{getCurrentTime()}</p>
        <p>{getTodaysDate()}</p>
      </td> */}
      <td style={{ fontWeight: "bold" }}>{profit}</td>
      <td style={{ fontWeight: "bold" }}>{pips}</td>
      <td style={{ fontWeight: "bold" }}>{swap}</td>
    </tr>
  );
};

// CLOSED TRADE ROW COMPONENT
const ClosedTradesRow = ({
  openTime,
  type,
  size,
  symbol,
  open,
  sl,
  tp,
  pnl,
  closeTime,
  close,
  duration,
  commission,
  profit,
  order,
  swap,
  comment,
}) => {
  const { PRIMARY_COLOR} = useThemeColors();
  return (
    <tr>
      <td>
        <p>{openTime.time}</p>
        <p>{openTime.date}</p>
      </td>
      <td>{type}</td>
      <td>{size}</td>
      <td style={{ fontWeight: "bold" }}>{symbol}</td>
      <td>{open}</td>
      <td>{sl}</td>
      <td>{tp}</td>
      {/* <td>{pnl}</td> */}
      <td>
        <p>{closeTime.time}</p>
        <p>{closeTime.date}</p>
      </td>
      <td>{close}</td>
      <td style={{ fontWeight: "bold", color:  PRIMARY_COLOR }}>{duration}</td>
      <td style={{ fontWeight: "bold" }}>{commission}</td>
      <td style={{ fontWeight: "bold" }}>{profit}</td>
      {/* <td style={{ fontWeight: "bold" }}>{order}</td> */}
      {/* <td style={{ fontWeight: "bold" }}>{swap}</td> */}
      <td>{comment}</td>
    </tr>
  );
};

// OPEN TRADES CARD
export const OpenTrades = ({ hideBtn, showNumber }) => {
  const openTradesData = useSelector(state => state.trades.openTrades)

  const calcDuration = (date1) => {
  const newDate1 = new Date(date1);
  const newDate2 = new Date();
  const diffTime = Math.abs(newDate2 - newDate1);
  var seconds = Math.floor((diffTime / 1000) % 60),
  minutes = Math.floor((diffTime / (1000 * 60)) % 60),
  hours = Math.floor((diffTime / (1000 * 60 * 60)) % 24);
  hours = (hours <= 0) ? "" : `${hours} h`;
  minutes = (minutes <= 0) ? "" : ` ${minutes} min `;
  seconds = (minutes <= 0) ? ` ${seconds} sec` : "";

  return hours + minutes + seconds;
}




const dateTime = (str) =>{
  var now = new Date(str)
  var date = now.toLocaleDateString();
  var time = now.toLocaleTimeString();
    return {
      date,
      time
    }
}
  return (
    <div className={styles.cardContainer}>
      <div>
        <h2>Open Trades {(showNumber && openTradesData) && `(${openTradesData?.length})`}</h2>
        {/* <div className={hideBtn && styles.hidden}>
          <Button>
            <span>Customise</span>
            <img src={Customise} alt="customise" height="15px" width="15px" />
          </Button>
        </div> */}
      </div>
      <div className={styles.tableDiv}>
        <table>
          <OpenTradesHeader />
          {openTradesData?.map((data, i) => {
            return (
              <OpenTradesRow
                key={i}
                openTime={dateTime(data?.time)}
                type={data?.type === "POSITION_TYPE_BUY" ? "Buy" : "Sell"}
                size={data?.volume}
                symbol={data?.symbol}
                open={data?.openPrice}
                sl={data?.sl ? data?.sl : 0}
                tp={data?.tp ? data?.tp : 0}
                profit={data?.profit}
                pips={data?.pips ? Math.round(data?.pips  * 100) / 100 : ""}
                swap={data?.swap}
              />
            );
          })}
        </table>
      </div>
    </div>
  );
};

// CLOSED TRADES CARD
export const ClosedTrades = ({accountNumber, accountId, companyId}) => {


  const dispatch = useDispatch();
  // const totalClosedTrades = useSelector(state => state.trades.totalClosedTrades);
  // const closedTrades = useSelector((state) => state.trades.closedTrades);


  const [perPage, setPerpage] = useState(6);
  const [currentPage, setCurrentPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [pageCount, setPageCount] = useState()
  const [closedTrades, setClosedTrades] = useState([])
  const [totalClosedTrades, setTotalClosedTrades] = useState()

  // useEffect(() => {
  //   const numberOfPage = Math.ceil(totalClosedTrades/perPage)
  //   setPageCount(numberOfPage)
  // },[totalClosedTrades])

  // useEffect(() => {
  //   dispatch(fetchClosedTrades(accountNumber, currentPage + 1));
  // },[currentPage])



  useEffect(() => {
   // Determine which ID to use
   const idToUse = userType === "under-company" ? userId : companyId;

   getClosedTrades(idToUse, accountId, currentPage + 1, perPage)
 },[currentPage, companyId, accountId])




  const getClosedTrades = async (id, account, page, limit) => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/account/closed-trades/${id}/${account}?page=${page}&limit=${limit}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "usertype": userType,
        },
      });

      if (!response.ok) {
        throw new Error("getting tarcking data failed.");
      }

      const result = await response.json();

      console.log("NEW OPEN TRADES WOWWWW", result)
      setClosedTrades(result?.trades)
      setPageCount(result?.totalPages)
      setTotalClosedTrades(result?.totalTrades)

      return result

    };

    try {
      const data = await sendRequest();
    } catch (error) {
      console.log("OPEN TRADES ERROR", error);
    }
  };



  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * perPage;

    setCurrentPage(selectedPage)
    setOffset(offset)
};
  

  const calcDuration = (date1, date2) => {
  const newDate1 = new Date(date1);
  const newDate2 = new Date(date2);
  const diffTime = Math.abs(newDate2 - newDate1);
  var seconds = Math.floor((diffTime / 1000) % 60),
  minutes = Math.floor((diffTime / (1000 * 60)) % 60),
  hours = Math.floor((diffTime / (1000 * 60 * 60)) % 24);
  hours = (hours <= 0) ? "" : `${hours} h`;
  minutes = (minutes <= 0) ? "" : ` ${minutes} min `;
  seconds = (minutes <= 0) ? ` ${seconds} sec` : "";

  return hours + minutes + seconds;
}




const dateTime = (str) =>{
  str = str.split(" ");
  return {
    date: str[0],
    time: str[1]
  }
}
  return (
    <>
    <div className={styles.cardContainer}>
      <div>
        <h2>Closed Trades ({totalClosedTrades})</h2>
        {/* <Button>
          <span>Customise</span>
          <img src={Customise} alt="customise" height="15px" width="15px" />
        </Button> */}
      </div>
      <div className={styles.tableDiv}>
        <table>
          <ClosedTradesHeader />
          {closedTrades.map((data, i) => {
            return (
              <ClosedTradesRow
                key={i}
                openTime={dateTime(data.openTime)}
                type={data.action}
                size={data.sizing.value}
                symbol={data.symbol}
                open={data.openPrice}
                sl={data.sl}
                tp={data.tp}
                // pnl={data.pnl}
                closeTime={dateTime(data.closeTime)}
                close={data.closePrice}
                duration={calcDuration(data.openTime, data.closeTime)}
                commission={data.commission}
                profit={data.profit}
                // order={data.orderNumber}
                // swap={data.swap}
                comment={data.comment}
              />
            );
          })}
        </table>
      </div>
      
    </div>

{/* <div className={`${styles.pagination} d-flex justify-content-end align-items-center`}>{pages.map((page, i) => (<div className={`${styles.numberContainer} d-flex justify-content-center align-items-center`} onClick={() => setActivePage(i)} style={{background: activePage === i && "#0077B5", color: activePage === i && "#fff"}} key={i}>{pages >= 5 ? i + 1 : activePage === 0 ? i + 1 : i === 0  ? "..." : i === 3  ? "..." :  i + 1}</div>))}</div> */}

<ReactPaginate
                    previousLabel={"prev"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={1}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}/>
</>
  );
};

// MAIN PAGE TO EXPORT
export default function Trading({ active, handleChange, openModal }) {
  
  return (
    <div>
      <div className={styles.tradesMainContainer}>
        <OpenTrades />
        <ClosedTrades />
      </div>
    </div>
  );
}
