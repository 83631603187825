import { Box, Grid, GridItem, HStack, VStack } from "@chakra-ui/layout";
import moment from "moment";
import React from "react";
import {
  getCookie,
  numberWithCommas,
} from "../../../../../utilities/functions";
import { Text } from "./../../../components/text";
import { usePageContext } from "../../../contexts/page.context";
import useTradingAccounts from "../../accounts/hooks/useTradingAccounts";
import { useDomainContext } from "../../../../../contexts/DomainContext";

const Deposit = ({ item, id }) => {
  const companyId = getCookie("companyId");
  const { currentTheme } = usePageContext();
  const { data: accounts } = useTradingAccounts(companyId);
  const acc = accounts?.find((x) => x._id === item?.account);

  const { companyTheme } = useDomainContext();

  return (
    <Box w={"100%"} id={id} display={"none"}>
      <Grid w={"100%"} pt={"20px"} pl={"68px"} gridTemplateColumns={"7fr 4fr"}>
        <GridItem gap={"16px"} as={VStack} alignItems={"flex-start"}>
          <Text fontSize={"14px"} fontWeight={"400"}>
            <Text display={"inline-block"} fontSize={"14px"} fontWeight={"500"}>
              Request Date
            </Text>
            : {moment(item.createdAt).format("YYYY-MM-DD HH:mm")}
          </Text>
          <Text
            display={"inline-flex"}
            gap={"8px"}
            fontSize={"14px"}
            fontWeight={"400"}
          >
            <Text display={"inline-block"} fontSize={"14px"} fontWeight={"500"}>
              Status:
            </Text>

            <HStack>
              <Box
                w={"10px"}
                height={"10px"}
                borderRadius={"50%"}
                bg={
                  item.status === "pending"
                    ? "#e1e1e1"
                    : item.status === "failed" ||
                      item.status === "not-transferred" ||
                      item.status === "not-received" ||
                      item.status === "not-paid"
                    ? "#BD3437"
                    : item.status === "received" ||
                      item.status === "transferred" ||
                      item.status === "paid"
                    ? "#5DBE94"
                    : ""
                }
              />
              <Text
                color={currentTheme === "light" ? "black" : "white"}
                fontSize={"14px"}
                fontWeight={"400"}
              >
                {item.status === "pending" ||
                item.status === "not-transferred" ||
                item.status === "not-received"
                  ? "Pending"
                  : item.status === "failed"
                  ? "Failed"
                  : item.status === "received" ||
                    item.status === "paid" ||
                    item.status === "transferred"
                  ? "Completed"
                  : ""}
              </Text>
            </HStack>
          </Text>
          <Text fontSize={"14px"} fontWeight={"400"}>
            <Text display={"inline-block"} fontSize={"14px"} fontWeight={"500"}>
              Method
            </Text>
            :{" "}
            {item.paymentMethod === "wire-transfer"
              ? "Bank Transfer"
              : item.paymentMethod === "crypto"
              ? `Crypto (${item.currency?.toUpperCase()})`
              : "Cash Account"}
          </Text>
          <Text fontSize={"14px"} fontWeight={"400"}>
            <Text display={"inline-block"} fontSize={"14px"} fontWeight={"500"}>
              Destination Account
            </Text>
            : {acc?.accountName} - {acc?.credentials?.accountNumber}
          </Text>
        </GridItem>
        <GridItem gap={"16px"} as={VStack} alignItems={"flex-start"}>
          <Text fontSize={"14px"} fontWeight={"400"}>
            <Text display={"inline-block"} fontSize={"14px"} fontWeight={"500"}>
              Deposit Fee
            </Text>
            : ${numberWithCommas(item?.withdrawalFee)}
          </Text>
          <Text fontSize={"14px"} fontWeight={"400"}>
            <Text display={"inline-block"} fontSize={"14px"} fontWeight={"500"}>
              Deposit Amount
            </Text>
            : ${numberWithCommas(item?.netPayout)}
          </Text>
          <Text fontSize={"14px"} fontWeight={"400"}>
            <Text display={"inline-block"} fontSize={"14px"} fontWeight={"500"}>
              Total Deposit
            </Text>
            : ${numberWithCommas(item?.totalWithdrawal)}
          </Text>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Deposit;
