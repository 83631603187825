import { useEffect, useRef, useState } from "react";
import CheckIcon from "../../icons/CheckIcon";
import CloseIcon from "../../icons/CloseIcon";
import CopyIcon from "../../icons/CopyIcon";
import DownloadIcon from "../../icons/DownloadIcon";
import DropDownIcon from "../../icons/DropDownIcon";
import LockIcon from "../../icons/LockIcon";
import classes from "./Assets.module.css";
import ChartComponent from "./chartComponent/Chart";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";

import { ClosedTrades, OpenTrades } from "./trading/Trading";
import { useDispatch, useSelector } from "react-redux";
import { getCookie } from "../../utilities/functions";
import { env } from "../../constants/env";
import Statistics from "./statistics/Statistics";
import {
  fetchClosedTrades,
  fetchMonthlyGain,
  fetchOpenTrades,
  fetchStatistics,
} from "../../Redux/trades-actions";
import FileDownload from "js-file-download";
import axios from "axios";
import useInterval from "../../hooks/getInterval";
import html2canvas from "html2canvas";
import ImageModule from "docxtemplater-image-module";
import {
  calcDuration,
  checkNum,
  getPercentage,
  getStartDate,
  groupByMonth,
} from "../../helpers/GetDuration";

import factSheetTemplate from "../../template/fact_sheet_template.docx";
import factSheetTemplateDarkMode from "../../template/fact_sheet_template_dark_mode.docx";
import tradingReportsTemplate from "../../template/trading_reports_template.docx";

import { Helmet } from "react-helmet";
import { toast } from "react-hot-toast";
import ShareArrowIcon from "../../icons/ShareArrowIcon";
import LinkIcon from "../../icons/LinkIcon";
import PortfolioLinkModal from "./PortfolioLinkModal";
import InfoIcon from "../../icons/InfoIcon";
import DropGainDownIcon from "../../icons/DropGainDownIcon";
import BarChartComponent from "./barChartComponent/BarChartComponent";
import ProfitChartComponents from "./profitChartComponent/ProfitChartComponent";
import GainChartComponents from "./gainChartComponent /GainChartComponent";
import EquityChartComponent from "./equityChartComponent/EquityChartComponent";
import { fetchAllStatistics } from "../../Redux/statistics-actions";
import { useDynamicTitle } from "../../hooks/useDynamicTitle";
import { useThemeColors } from "../../hooks/useThemeColors";
import { useFeatures } from "../../contexts/ThemeContext";
import Cookies from "js-cookie";

const tabsData = ["Overview", "Statistics", "Trading"];

const useOptions = ["Fact Sheet", "Trading Reports"];

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}

const optionsData = ["Deposit", "Liquidity"];

const optionsProfitData = ["Realized", "Unrealized", "Cumulative"];

const StatisticContainer = ({
  title,
  desc,
  noborder,
  showInfoIcon,
  dropDown,
  profitDropDown,
  setSelectOption,
  selectOption,
  themeMode,
  activeTab,
  onClick,
  disableCard,
  onMouseOver,
  onMouseOut,
  percentage,
}) => {
  const [showFeePopup, setShowFeePopup] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [showProfitDropDown, setShowProfitDropDown] = useState(false);
  const [cardHover, setCardHover] = useState(false);

  const { PRIMARY_COLOR } = useThemeColors();

  document.addEventListener("click", (evt) => {
    const flyoutEl = document.getElementById("selected-chart-option");
    let targetEl = evt.target; // clicked element
    do {
      if (targetEl == flyoutEl) {
        // This is a click inside, does nothing, just return.
        return;
      }
      // Go up the DOM
      targetEl = targetEl.parentNode;
    } while (targetEl);
    // This is a click outside.
    setShowDropDown(false);
  });

  return (
    <div
      className={`${classes.statisticContainer} ${
        noborder ? classes.noboader : undefined
      } ${disableCard ? classes.statisticsCardDisabled : undefined} ${
        activeTab ? classes.activeChartTab : undefined
      } d-flex flex-column justify-content-center align-items-center`}
      role="button"
      onClick={onClick}
      onMouseOver={() => {
        setCardHover(true);
        onMouseOver();
      }}
      onMouseOut={() => {
        setCardHover(false);
        onMouseOut();
      }}
    >
      <div className="d-flex justify-content-center align-items-center">
        {profitDropDown && (
          <div className={classes.profitPercentage}>{percentage}</div>
        )}
        <h3>{title}</h3>
      </div>
      <div className="d-flex justify-content-center align-items-center position-relative">
        <h5>{desc}</h5>
        {showInfoIcon && (
          <>
            {showFeePopup && (
              <div
                className={`${classes.infoPopup} d-flex justify-content-center align-items-center`}
                style={{
                  width:
                    (selectOption === "Realized" ||
                      selectOption === "Unrealized") &&
                    "467px",
                  height: selectOption === "Unrealized" && "97px",
                }}
              >
                {selectOption === "Deposit" || selectOption === "Liquidity"
                  ? "Switch between your gain on deposit or liquidity"
                  : selectOption === "Unrealized"
                  ? "These are potential profits or losses from positions that are still open. These unrealized profits or losses impact your account value, but they are not “locked in” until the trade is closed."
                  : selectOption === "Cumulative"
                  ? "The total profit or loss of an account during the trading period."
                  : "These are profits or losses from positions that have been closed. Realized profits increase your account value, while realized losses decrease it."}
              </div>
            )}
            <div className={classes.infoIconWrapper}>
              <InfoIcon
                size="16"
                color={
                  cardHover
                    ? PRIMARY_COLOR
                    : themeMode === "dark"
                    ? "#FFF"
                    : PRIMARY_COLOR
                }
                onMouseOver={() => setShowFeePopup(true)}
                onMouseOut={() => setShowFeePopup(false)}
              />
            </div>
            {dropDown && (
              <div
                id="selected-chart-option"
                className={`d-flex justify-content-center align-items-center gap-1 ${classes.selectedItem}`}
                onClick={() => {
                  setShowDropDown(!showDropDown);
                }}
                role="button"
              >
                {selectOption}
                <DropGainDownIcon
                  color={
                    cardHover
                      ? "#01486D"
                      : themeMode === "dark"
                      ? "white"
                      : activeTab
                      ? PRIMARY_COLOR
                      : "black"
                  }
                  width={"13"}
                  height={"10"}
                  dropDown={showDropDown}
                />
              </div>
            )}
            {showDropDown && (
              <div
                className={`d-flex flex-column justify-content-center ${classes.selectOption}`}
              >
                {optionsData.map((item, i) => (
                  <div
                    role="button"
                    onClick={() => {
                      setSelectOption(item);
                      setShowDropDown(false);
                    }}
                  >
                    {item !== selectOption && item}
                  </div>
                ))}
              </div>
            )}
            {profitDropDown && (
              <div
                id="selected-chart"
                className={`d-flex justify-content-center align-items-center gap-1 ${classes.selectedItem}`}
                onClick={() => {
                  setShowProfitDropDown(!showProfitDropDown);
                }}
                role="button"
              >
                {selectOption}
                <DropGainDownIcon
                  color={
                    cardHover
                      ? "#01486D"
                      : themeMode === "dark"
                      ? "white"
                      : activeTab
                      ? PRIMARY_COLOR
                      : "black"
                  }
                  width={"13"}
                  height={"10"}
                  dropDown={showProfitDropDown}
                />
              </div>
            )}
            {showProfitDropDown && (
              <div
                className={`d-flex flex-column justify-content-center ${classes.selectProfitOption}`}
              >
                {optionsProfitData.map((item, i) => (
                  <div
                    role="button"
                    style={{
                      whiteSpace: "nowrap",
                    }}
                    onClick={() => {
                      setSelectOption(item);
                      setShowProfitDropDown(false);
                    }}
                  >
                    {item !== selectOption && item}
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const Assets = ({ themeMode }) => {
  useDynamicTitle("My Assets", "dashboard");
  const dispatch = useDispatch();
  const userId = getCookie("userId");
  const userType = getCookie("userType");
  const companyId = getCookie("companyId"); // Assuming companyId is stored as a cookie

  const [accountsDetails, setAccountsDetails] = useState([]);

  const [showStrategies, setShowStrategies] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedTab, setSelectedTab] = useState("Overview");
  const [showMt5Login, setShowMt5Login] = useState(false);
  const [mt5LoginCredentials, setMt5LoginCredentials] = useState();
  const [showInvestorPassword, setShowInvestorPassword] = useState(false);
  const [showMasterPassword, setShowMasterPassword] = useState(false);
  const [currentBalance, setCurrentBalance] = useState(0);
  const [chartData, setChartData] = useState([]);
  const [profitChartData, setProfitChartData] = useState([]);
  const [gainChartData, setGainChartData] = useState([]);
  const [maxDDChartData, setMaxDDChartData] = useState([]);
  const [statsData, setStatsData] = useState();
  const [isSending, setIsSending] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [fileKey, setFileKey] = useState();
  const [maxBalance, setMaxBalance] = useState();
  const [showShareLink, setShowShareLink] = useState(false);
  const [showDownloadOptions, setShowDownloadOptions] = useState(false);
  const [selectedGainOption, setSelectedGainOption] = useState("Liquidity");
  const [selectedProfitOption, setSelectedProfitOption] = useState("Realized");
  const [profitsData, setProfitsData] = useState();

  const [chartOption, setChartOption] = useState("");
  const [disableCard, setDisableCard] = useState();
  const [equityMaxBalance, setEquityMaxBalance] = useState();
  const [equityChartData, setEquityChartData] = useState([]);
  const [activeChart, setActiveChart] = useState("");
  const [floatingProfit, setFloatingProfit] = useState(0);
  const [trackingStats, setTrackingStats] = useState({});

  const { PRIMARY_COLOR, ACCENT_COLOR } = useThemeColors();

  const { companyTheme } = useFeatures();

  const companyName = useSelector((state) => state.company.companyName);

  const statistics = useSelector((state) => state.trades.statistics);

  const monthlyGain = useSelector((state) => state.trades.monthlyGain);

  const closedTrades = useSelector((state) => state.trades.closedTrades);

  const openTrades = useSelector((state) => state.trades.openTrades);

  // Determine which ID to use based on userType
  const idToUse = userType === "under-company" ? userId : companyId;

  const element = document.getElementById("print");

  function retnum(str) {
    var numb = str.match(/[\d,]+\.\d+/)[0];
    return numb;
  }

  useEffect(() => {
    const result = openTrades?.map((item) => item.profit);

    if (result.length > 0) {
      setFloatingProfit(result.reduce((prev, next) => prev + next));
    }
  }, [openTrades]);

  useEffect(() => {
    dispatch(fetchMonthlyGain(mt5LoginCredentials?.credentials?.accountNumber));
    dispatch(fetchStatistics(mt5LoginCredentials?.credentials?.accountNumber));
    dispatch(
      fetchClosedTrades(mt5LoginCredentials?.credentials?.accountNumber, 1)
    );
    // getClosedTradesByDate(mt5LoginCredentials?.credentials?.accountNumber)
    dispatch(fetchAllStatistics(mt5LoginCredentials?.credentials?.apiId));
  }, [mt5LoginCredentials?.credentials]);

  useEffect(() => {
    getAccounts();
  }, []);

  useEffect(() => {
    if (chartOption === "equity") {
      // fetchEquityChartData(mt5LoginCredentials?.credentials?.accountNumber)
      getTrackingChartEquity(companyId, selectedOption?._id, "profit", "all");
    }

    if (chartOption === "profit") {
      // fetchProfitChartData(mt5LoginCredentials?.credentials?.accountNumber)
      getTrackingChartProfit(companyId, selectedOption?._id, "profit", "all");
    }

    if (chartOption === "gain") {
      // fetchGainChartData(mt5LoginCredentials?.credentials?.accountNumber)
      getTrackingChartGain(companyId, selectedOption?._id, "gain", "all");
    }

    if (chartOption === "maxdd") {
      // fetchMaxddChartData(mt5LoginCredentials?.credentials?.accountNumber)
      getTrackingChartMaxdd(companyId, selectedOption?._id, "profit", "all");
    }
  }, [chartOption, selectedOption]);

  // useEffect(() => {
  //   getSingleAccount(selectedOption?._id);
  //   getTrackingStats(companyId, selectedOption?._id);
  //   getTrackingChartBalance(companyId, selectedOption?._id, "profit", "all");
  //   getTrackingChartEquity(companyId, selectedOption?._id, "profit", "all");
  //   getTrackingChartProfit(companyId, selectedOption?._id, "profit", "all");
  //   getTrackingChartGain(companyId, selectedOption?._id, "gain", "all");
  //   getTrackingChartMaxdd(companyId, selectedOption?._id, "profit", "all");
  // }, [selectedOption]);

  const getTrackingData = async () => {
    // If userType is missing, log out the user
    if (!userType) {
      Cookies.remove("authToken");
      window.location.href = "/login";
      return;
    }

    // Determine which ID to use
    const idToUse = userType === "under-company" ? userId : companyId;

    getSingleAccount(selectedOption?._id);
    getTrackingStats(idToUse, selectedOption?._id);
    getTrackingChartBalance(idToUse, selectedOption?._id, "profit", "all");
    getTrackingChartEquity(idToUse, selectedOption?._id, "profit", "all");
    getTrackingChartProfit(idToUse, selectedOption?._id, "profit", "all");
    getTrackingChartGain(idToUse, selectedOption?._id, "gain", "all");
    getTrackingChartMaxdd(idToUse, selectedOption?._id, "profit", "all");
  };

  // Fetch data when `selectedOption` changes
  useEffect(() => {
    getTrackingData();
  }, [selectedOption]);

  useEffect(() => {
    // fetchGainChartData(mt5LoginCredentials?.credentials?.accountNumber)
    getTrackingChartGain(companyId, selectedOption?._id, "gain", "all");
  }, [selectedOption, selectedGainOption]);

  // // const getTrackingStats = async (id, account) => {
  // //   const sendRequest = async () => {
  // //     const response = await fetch(
  // //       `${env.primeBridgeUrl}/api/account/tracking-statistics/${id}/${account}`,
  // //       {
  // //         method: "GET",
  // //         headers: {
  // //           "Content-Type": "application/json",
  // //         },
  // //       }
  // //     );

  // //     if (!response.ok) {
  // //       throw new Error("getting tarcking data failed.");
  // //     }

  // //     const result = await response.json();

  // //     setTrackingStats(result);

  // //     // console.log("TRACKING DATA", result)

  // //     return result;
  // //   };

  // //   try {
  // //     await sendRequest();
  // //   } catch (error) {
  // //     console.log(error);
  // //   }
  // // };

  // // const getTrackingChartBalance = async (id, account, chart, period) => {
  // //   const sendRequest = async () => {
  // //     const response = await fetch(
  // //       `${env.primeBridgeUrl}/api/account/tracking-chart/${id}/${account}?chart=${chart}&period=${period}`,
  // //       {
  // //         method: "GET",
  // //         headers: {
  // //           "Content-Type": "application/json",
  // //         },
  // //       }
  // //     );

  // //     if (!response.ok) {
  // //       throw new Error("getting tarcking data failed.");
  // //     }

  // //     const result = await response.json();

  // //     console.log("BALANCD CHART DATA", result);
  // //     return result;
  // //   };

  // //   try {
  // //     const data = await sendRequest();
  // //     const array = data;

  // //     const max = Math.max(...array.map((o) => o.balance));
  // //     const min = Math.min(...array.map((o) => o.balance));
  // //     const average = (+max + +min) / 2;
  // //     // data?.reduce((n, { balance }) => n + +balance, 0) /
  // //     // data?.length;

  // //     setMaxBalance({
  // //       max: max,
  // //       min: min,
  // //       average: Math.round(average * 100) / 100,
  // //     });
  // //     setChartData(data);
  // //   } catch (error) {
  // //     console.log(error);
  // //   }
  // // };

  // // const getTrackingChartEquity = async (id, account, chart, period) => {
  // //   const sendRequest = async () => {
  // //     const response = await fetch(
  // //       `${env.primeBridgeUrl}/api/account/tracking-chart/${id}/${account}?chart=${chart}&period=${period}`,
  // //       {
  // //         method: "GET",
  // //         headers: {
  // //           "Content-Type": "application/json",
  // //         },
  // //       }
  // //     );

  // //     if (!response.ok) {
  // //       throw new Error("getting tarcking data failed.");
  // //     }

  // //     const result = await response.json();

  // //     const arr = result;

  // //     var transformed = arr.map(function (obj) {
  // //       var result = {
  // //         date: obj.date,
  // //         equity: Math.round((obj.balance + obj.floatingPL) * 100) / 100,
  // //       };

  // //       return result;
  // //     });

  // //     console.log("BALANCE AND EQUITY TRANSFORMED", transformed);

  // //     return transformed;
  // //   };

  // //   try {
  // //     const data = await sendRequest();

  // //     const array = data;

  // //     const max = Math.max(...array.map((o) => o.equity));
  // //     const min = Math.min(...array.map((o) => o.equity));
  // //     const average = (+max + +min) / 2;
  // //     // data?.data.reduce((n, { balance }) => n + +balance, 0) /
  // //     // data?.totalDataPoints;

  // //     setEquityMaxBalance({
  // //       max: Math.round(max * 100) / 100,
  // //       min: Math.round(min * 100) / 100,
  // //       average: Math.round(average * 100) / 100,
  // //     });
  // //     setEquityChartData(data);
  // //   } catch (error) {
  // //     console.log(error);
  // //   }
  // // };

  // // const getTrackingChartProfit = async (id, account, chart, period) => {
  // //   const sendRequest = async () => {
  // //     const response = await fetch(
  // //       `${env.primeBridgeUrl}/api/account/tracking-chart/${id}/${account}?chart=${chart}&period=${period}`,
  // //       {
  // //         method: "GET",
  // //         headers: {
  // //           "Content-Type": "application/json",
  // //         },
  // //       }
  // //     );

  // //     if (!response.ok) {
  // //       throw new Error("getting tarcking data failed.");
  // //     }

  // //     const result = await response.json();

  // //     return result;
  // //   };

  // //   try {
  // //     const data = await sendRequest();
  // //     setProfitChartData(data);
  // //   } catch (error) {
  // //     console.log(error);
  // //   }
  // // };

  // const getTrackingChartGain = async (id, account, chart, period) => {
  //   const sendRequest = async () => {
  //     const response = await fetch(
  //       `${env.primeBridgeUrl}/api/account/tracking-chart/${id}/${account}?chart=${chart}&period=${period}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     if (!response.ok) {
  //       throw new Error("getting tarcking data failed.");
  //     }

  //     const data = await response.json();

  //     const result = data;

  //     console.log("TRACKING DATA", result);

  //     for (var i in result) {
  //       if (selectedGainOption === "Deposit") {
  //         result[i].value = Math.round(+result[i].value * 10 * 100) / 100;
  //       }
  //     }

  //     return result;
  //   };

  //   try {
  //     const data = await sendRequest();
  //     setGainChartData(data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const getTrackingChartMaxdd = async (id, account, chart, period) => {
  //   const sendRequest = async () => {
  //     const response = await fetch(
  //       `${env.primeBridgeUrl}/api/account/tracking-chart/${id}/${account}?chart=${chart}&period=${period}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     if (!response.ok) {
  //       throw new Error("getting tarcking data failed.");
  //     }

  //     const result = await response.json();

  //     return result;
  //   };

  //   try {
  //     const data = await sendRequest();

  //     var maxDDData = [];

  //     for (var i in data) {
  //       const drawDown =
  //         Math.round((data[i].floatingPL / data[i].balance) * 100 * 100) / 100;
  //       const drawDownObj = {
  //         drawDown: Math.abs(drawDown),
  //         date: data[i].date,
  //       };

  //       maxDDData.push(drawDownObj);
  //     }

  //     setMaxDDChartData(maxDDData);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getTrackingStats = async (id, account) => {
    try {
      const response = await fetch(
        `${env.primeBridgeUrl}/api/account/tracking-statistics/${id}/${account}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "usertype": userType,
          },
        }
      );

      if (!response.ok) throw new Error("Getting tracking data failed.");

      const result = await response.json();
      setTrackingStats(result);
    } catch (error) {
      console.log(error);
    }
  };

  const getTrackingChart = async (
    id,
    account,
    chart,
    period,
    setter,
    transformFn = null
  ) => {
    try {
      const response = await fetch(
        `${env.primeBridgeUrl}/api/account/tracking-chart/${id}/${account}?chart=${chart}&period=${period}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "usertype": userType,
          },
        }
      );

      if (!response.ok) throw new Error("Getting tracking data failed.");

      let data = await response.json();

      // Apply transformation if provided
      if (transformFn) data = transformFn(data);

      setter(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getTrackingChartBalance = (id, account, chart, period) => {
    getTrackingChart(id, account, chart, period, setChartData, (data) => {
      const max = Math.max(...data.map((o) => o.balance));
      const min = Math.min(...data.map((o) => o.balance));
      const average = (max + min) / 2;

      setMaxBalance({ max, min, average: Math.round(average * 100) / 100 });
      return data;
    });
  };

  const getTrackingChartEquity = (id, account, chart, period) => {
    getTrackingChart(id, account, chart, period, setEquityChartData, (data) => {
      const transformed = data.map((obj) => ({
        date: obj.date,
        equity: Math.round((obj.balance + obj.floatingPL) * 100) / 100,
      }));

      const max = Math.max(...transformed.map((o) => o.equity));
      const min = Math.min(...transformed.map((o) => o.equity));
      const average = (max + min) / 2;

      setEquityMaxBalance({
        max,
        min,
        average: Math.round(average * 100) / 100,
      });
      return transformed;
    });
  };

  const getTrackingChartProfit = (id, account, chart, period) => {
    getTrackingChart(id, account, chart, period, setProfitChartData);
  };

  const getTrackingChartGain = (id, account, chart, period) => {
    getTrackingChart(id, account, chart, period, setGainChartData, (data) => {
      return data.map((item) => ({
        ...item,
        value:
          selectedGainOption === "Deposit"
            ? Math.round(+item.value * 10 * 100) / 100
            : item.value,
      }));
    });
  };

  const getTrackingChartMaxdd = (id, account, chart, period) => {
    getTrackingChart(id, account, chart, period, setMaxDDChartData, (data) => {
      return data.map((item) => ({
        drawDown: Math.abs(
          Math.round((item.floatingPL / item.balance) * 100 * 100) / 100
        ),
        date: item.date,
      }));
    });
  };

  const getClosedTradesByDate = async (accountNumber) => {
    const sendRequest = async () => {
      const response = await fetch(
        `${process.env.PB_TRACKING_URI}/api/trades/closed-trades/${accountNumber}?page=1&results=5`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      // setClosedTrades(result?.data)
      return result;
    };

    try {
    } catch (error) {
      console.log(error);
    }
  };

  const dateTime = (str) => {
    str = str?.split(" ");
    return {
      date: str ? str[0] : 0,
      time: str ? str[1] : 0,
    };
  };

  var newClosedTradeData = [];

  for (var i in closedTrades) {
    let closedTrade = {
      open_time: dateTime(closedTrades[i].openTime).time,
      open_date: dateTime(closedTrades[i].openTime).date,
      type: closedTrades[i].direction,
      size: closedTrades[i].orderSize,
      symbol: closedTrades[i].symbol,
      open: closedTrades[i].entryPrice,
      sl: closedTrades[i].slPrice,
      tp: closedTrades[i].tpPrice,
      pnl: closedTrades[i].pnl,
      close_time: dateTime(closedTrades[i].closeTime).time,
      close_date: dateTime(closedTrades[i].closeTime).date,
      close: closedTrades[i].exitPrice,
      duration: calcDuration(
        closedTrades[i].openTime,
        closedTrades[i].closeTime
      ),
      commission: closedTrades[i].commission,
      profit: closedTrades[i].profit,
      order: closedTrades[i].orderNumber,
      swap: closedTrades[i].swap,
    };
    newClosedTradeData.push(closedTrade);
  }

  var newOpenTradeData = [];

  for (var i in openTrades) {
    let openTrade = {
      open_time: dateTime(openTrades[i].openTime).time,
      open_date: dateTime(openTrades[i].openTime).date,
      type: openTrades[i].action,
      size: openTrades[i].sizing?.value,
      symbol: openTrades[i].symbol,
      open: openTrades[i].openPrice,
      sl: openTrades[i].sl,
      tp: openTrades[i].tp,
      profit: openTrades[i].profit,
      pips: openTrades[i].pips,
      swap: openTrades[i].swap,
    };
    newOpenTradeData.push(openTrade);
  }

  var newData = [];

  var arrayLength = monthlyGain.length;
  for (var i in monthlyGain) {
    let jan = monthlyGain[i]?.data[0]?.percentage
        ? monthlyGain[i]?.data[0]?.percentage
        : 0,
      feb = monthlyGain[i]?.data[1]?.percentage
        ? monthlyGain[i]?.data[1]?.percentage
        : 0,
      mar = monthlyGain[i]?.data[2]?.percentage
        ? monthlyGain[i]?.data[2]?.percentage
        : 0,
      apr = monthlyGain[i]?.data[3]?.percentage
        ? monthlyGain[i]?.data[3]?.percentage
        : 0,
      may = monthlyGain[i]?.data[4]?.percentage
        ? monthlyGain[i]?.data[4]?.percentage
        : 0,
      jun = monthlyGain[i]?.data[5]?.percentage
        ? monthlyGain[i]?.data[5]?.percentage
        : 0,
      jul = monthlyGain[i]?.data[6]?.percentage
        ? monthlyGain[i]?.data[6]?.percentage
        : 0,
      aug = monthlyGain[i]?.data[7]?.percentage
        ? monthlyGain[i]?.data[7]?.percentage
        : 0,
      sep = monthlyGain[i]?.data[8]?.percentage
        ? monthlyGain[i]?.data[8]?.percentage
        : 0,
      oct = monthlyGain[i]?.data[9]?.percentage
        ? monthlyGain[i]?.data[9]?.percentage
        : 0,
      nov = monthlyGain[i]?.data[10]?.percentage
        ? monthlyGain[i]?.data[10]?.percentage
        : 0,
      dec = monthlyGain[i]?.data[11]?.percentage
        ? monthlyGain[i]?.data[11]?.percentage
        : 0;

    let total =
      Math.round(
        (jan +
          feb +
          mar +
          apr +
          may +
          jun +
          jul +
          aug +
          sep +
          oct +
          nov +
          dec) *
          100
      ) / 100;

    let year = {
      year: monthlyGain[i].year,
      jan_pos: monthlyGain[i].data[0]?.positive,
      jan_neg: monthlyGain[i].data[0]?.negative,
      jan: monthlyGain[i].data[0]?.percentage
        ? `${monthlyGain[i].data[0]?.percentage}%`
        : "",
      feb_pos: monthlyGain[i].data[1]?.positive,
      feb_neg: monthlyGain[i].data[1]?.negative,
      feb: monthlyGain[i].data[1]?.percentage
        ? `${monthlyGain[i].data[1]?.percentage}%`
        : "",
      mar_pos: monthlyGain[i].data[2]?.positive,
      mar_neg: monthlyGain[i].data[2]?.negative,
      mar: monthlyGain[i].data[2]?.percentage
        ? `${monthlyGain[i].data[2]?.percentage}%`
        : "",
      apr_pos: monthlyGain[i].data[3]?.positive,
      apr_neg: monthlyGain[i].data[3]?.negative,
      apr: monthlyGain[i].data[3]?.percentage
        ? `${monthlyGain[i].data[3]?.percentage}%`
        : "",
      may_pos: monthlyGain[i].data[4]?.positive,
      may_neg: monthlyGain[i].data[4]?.negative,
      may: monthlyGain[i].data[4]?.percentage
        ? `${monthlyGain[i].data[4]?.percentage}%`
        : "",
      jun_pos: monthlyGain[i].data[5]?.positive,
      jun_neg: monthlyGain[i].data[5]?.negative,
      jun: monthlyGain[i].data[5]?.percentage
        ? `${monthlyGain[i].data[5]?.percentage}%`
        : "",
      jul_pos: monthlyGain[i].data[6]?.positive,
      jul_neg: monthlyGain[i].data[6]?.negative,
      jul: monthlyGain[i].data[6]?.percentage
        ? `${monthlyGain[i].data[6]?.percentage}%`
        : "",
      aug_pos: monthlyGain[i].data[7]?.positive,
      aug_neg: monthlyGain[i].data[7]?.negative,
      aug: monthlyGain[i].data[7]?.percentage
        ? `${monthlyGain[i].data[7]?.percentage}%`
        : "",
      sep_pos: monthlyGain[i].data[8]?.positive,
      sep_neg: monthlyGain[i].data[8]?.negative,
      sep: monthlyGain[i].data[8]?.percentage
        ? `${monthlyGain[i].data[8]?.percentage}%`
        : "",
      oct_pos: monthlyGain[i].data[9]?.positive,
      oct_neg: monthlyGain[i].data[9]?.negative,
      oct: monthlyGain[i].data[9]?.percentage
        ? `${monthlyGain[i].data[9]?.percentage}%`
        : "",
      nov_pos: monthlyGain[i].data[10]?.positive,
      nov_neg: monthlyGain[i].data[10]?.negative,
      nov: monthlyGain[i].data[10]?.percentage
        ? `${monthlyGain[i].data[10]?.percentage}%`
        : "",
      dec_pos: monthlyGain[i].data[11]?.positive,
      dec_neg: monthlyGain[i].data[11]?.negative,
      dec: monthlyGain[i].data[11]?.percentage
        ? `${monthlyGain[i].data[11]?.percentage}%`
        : "",
      total_pos: total >= 0,
      total_neg: total < 0,
      total: `${total}%`,
    };

    newData.push(year);
  }

  useInterval(() => {
    // Your custom logic here
    dispatch(fetchOpenTrades(mt5LoginCredentials?.credentials?.apiId));
  }, 60 * 1000);

  const getProfits = async (id) => {
    const sendRequest = async () => {
      const response = await fetch(
        `${env.pbTrackingUrl}/api/stats/profits-api/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      console.log("PROFITS RESULTS FROM API :) ", result);

      setProfitsData(result);
      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProfits = async (id) => {
    const sendRequest = async () => {
      const response = await fetch(
        `${env.pbTrackingUrl}/api/stats/profits/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      console.log("PROFITS FOR CHECKINGGG RESULTS :) ", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  const getStats = async (id) => {
    const sendRequest = async () => {
      const response = await fetch(`${env.pbTrackingUrl}/api/stats/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      console.log("RESULTS I WANT TO SEEEEEEEEEE", result);

      setStatsData(result);
      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  // const getAccounts = async () => {
  //   const sendRequest = async () => {
  //     const response = await fetch(
  //       `${env.primeBridgeUrl}/api/account/company/${companyId}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     if (!response.ok) {
  //       throw new Error("sending plays failed.");
  //     }

  //     const result = await response.json();
  //     setAccountsDetails(result);
  //     setSelectedOption(result[0]);

  //     return result;
  //   };

  //   try {
  //     await sendRequest();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getAccounts = async () => {
    // If userType is missing, log out the user
    if (!userType) {
      Cookies.remove("authToken");
      window.location.href = "/login"; // Redirect to login page
      return;
    }
    const sendRequest = async () => {
      const response = await fetch(
        `${env.primeBridgeUrl}/api/account/${
          userType === "under-company" ? "under-company" : "company"
        }/${idToUse}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      setAccountsDetails(result);
      setSelectedOption(result[0]);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  const getSingleAccount = async (accountId) => {
    const sendRequest = async () => {
      const response = await fetch(
        `${env.primeBridgeUrl}/api/account/${accountId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Getting account failed.");
      }

      const result = await response.json();
      console.log("MT5 DETAILS", result);
      setMt5LoginCredentials(result);
      dispatch(fetchOpenTrades(result?.credentials?.apiId));
      fetchCurrentBalance(result?.credentials?.apiId);
      fetchChartData(result?.credentials?.accountNumber);
      getStats(result?.credentials?.accountNumber);
      getProfits(result?.credentials?.apiId);
      fetchProfits(result?.credentials?.accountNumber);
      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCurrentBalance = async (id) => {
    const sendRequest = async () => {
      const response = await fetch(`${env.pbTrackingUrl}/api-balance/${id}`, {
        method: "GET",
        headers: {
          "x-api-key": "hv3zmZLmRLM6ZPIQKPhRXWRNMf71MSZE",
        },
      });

      if (!response.ok) {
        throw new Error("Fetching Bagley failed.");
      }

      const result = await response.json();

      console.log("BALANCE WITH APIID", result);

      return result;
    };

    try {
      const data = await sendRequest();
      setCurrentBalance(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchChartData = async (id, date = "all") => {
    const sendRequest = async () => {
      const response = await fetch(
        `${env.pbTrackingUrl}/api/chart/profit-chart/${id}?startdate=${date}`,
        {
          method: "GET",
          headers: {
            "x-api-key": "hv3zmZLmRLM6ZPIQKPhRXWRNMf71MSZE",
          },
        }
      );

      if (!response.ok) {
        const result = await response.json();
        console.log("ERRRORR", result);
        throw new Error("Fetching Bagley failed.");
      }

      const result = await response.json();

      const array = result;

      console.log("BALANCE AND EQUITY ERRRORR", result);

      return result;
    };

    try {
      const data = await sendRequest();

      const array = data;

      const max = Math.max(...array.map((o) => o.balance));
      const min = Math.min(...array.map((o) => o.balance));
      const average = (+max + +min) / 2;
      // data?.reduce((n, { balance }) => n + +balance, 0) /
      // data?.length;

      setMaxBalance({
        max: max,
        min: min,
        average: Math.round(average * 100) / 100,
      });
      setChartData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchEquityChartData = async (id, date = "all") => {
    const sendRequest = async () => {
      const response = await fetch(
        `${env.pbTrackingUrl}/api/chart/profit-chart/${id}?startdate=${date}`,
        {
          method: "GET",
          headers: {
            "x-api-key": "hv3zmZLmRLM6ZPIQKPhRXWRNMf71MSZE",
          },
        }
      );

      if (!response.ok) {
        const result = await response.json();
        console.log("ERRRORR", result);
        throw new Error("Fetching Bagley failed.");
      }

      const result = await response.json();

      const arr = result;

      var transformed = arr.map(function (obj) {
        var result = {
          date: obj.date,
          equity: Math.round((obj.balance + obj.floatingPL) * 100) / 100,
        };

        return result;
      });

      console.log("BALANCE AND EQUITY TRANSFORMED", transformed);

      return transformed;
    };

    try {
      const data = await sendRequest();

      const array = data;

      const max = Math.max(...array.map((o) => o.equity));
      const min = Math.min(...array.map((o) => o.equity));
      const average = (+max + +min) / 2;
      // data?.data.reduce((n, { balance }) => n + +balance, 0) /
      // data?.totalDataPoints;

      setEquityMaxBalance({
        max: Math.round(max * 100) / 100,
        min: Math.round(min * 100) / 100,
        average: Math.round(average * 100) / 100,
      });
      setEquityChartData(data);
    } catch (error) {
      console.log(error);
    }
  };

  // ===============================================================================================

  const fetchProfitChartData = async (id, date = "all") => {
    const sendRequest = async () => {
      const response = await fetch(
        `${env.pbTrackingUrl}/api/chart/profit-chart/${id}?startdate=${date}`,
        {
          method: "GET",
          headers: {
            "x-api-key": "hv3zmZLmRLM6ZPIQKPhRXWRNMf71MSZE",
          },
        }
      );

      if (!response.ok) {
        const result = await response.json();
        console.log("ERRRORR", result);
        throw new Error("Fetching Bagley failed.");
      }

      const result = await response.json();

      return result;
    };

    try {
      const data = await sendRequest();
      setProfitChartData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMaxddChartData = async (id, date = "all") => {
    const sendRequest = async () => {
      const response = await fetch(
        `${env.pbTrackingUrl}/api/chart/profit-chart/${id}?startdate=${date}`,
        {
          method: "GET",
          headers: {
            "x-api-key": "hv3zmZLmRLM6ZPIQKPhRXWRNMf71MSZE",
          },
        }
      );

      if (!response.ok) {
        const result = await response.json();
        console.log("ERRRORR", result);
        throw new Error("Fetching Bagley failed.");
      }

      const result = await response.json();

      return result;
    };

    try {
      const data = await sendRequest();

      var maxDDData = [];

      for (var i in data) {
        const drawDown =
          Math.round((data[i].floatingPL / data[i].balance) * 100 * 100) / 100;
        const drawDownObj = {
          drawDown: Math.abs(drawDown),
          date: data[i].date,
        };

        maxDDData.push(drawDownObj);
      }

      setMaxDDChartData(maxDDData);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchGainChartData = async (id, date = "all") => {
    const sendRequest = async () => {
      const response = await fetch(
        `${env.pbTrackingUrl}/api/chart/gain-chart/${id}?startdate=${date}`,
        {
          method: "GET",
          headers: {
            "x-api-key": "hv3zmZLmRLM6ZPIQKPhRXWRNMf71MSZE",
          },
        }
      );

      if (!response.ok) {
        const result = await response.json();
        console.log("ERRRORR", result);
        throw new Error("Fetching Bagley failed.");
      }

      const result = await response.json();

      console.log("GAIN RESULTS", result);

      for (var i in result) {
        if (selectedGainOption === "Deposit") {
          result[i].value = Math.round(+result[i].value * 10 * 100) / 100;
        }
      }

      return result;
    };

    try {
      const data = await sendRequest();
      setGainChartData(data);
    } catch (error) {
      console.log(error);
    }
  };

  // ====================================================================================

  const deleteFile = async (key) => {
    let dataContent = { key };
    const sendRequest = async () => {
      const response = await fetch(
        `${env.primeBridgeUrl}/api/docxtopdf/delete-file`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataContent),
        }
      );

      if (!response.ok) {
        const result = await response.json();

        console.log("Error", result);
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      console.log("Reply from plays data", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        FileDownload(res.data, filename);
        setIsLoading(false);
      })
      .then(() => {
        deleteFile(fileKey);
      });
  };

  const uploadDocumentHandler = async (file, fileName) => {
    const sendRequest = async () => {
      setIsSending(true);
      console.log("REACHED HERE");
      const formData = new FormData();
      formData.append("files", file);

      const response = await fetch(`${env.primeBridgeUrl}/api/docxtopdf`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        const result = await response.json();
        setIsSending(false);
        setIsLoading(false);

        toast.error(result.error);
        console.log(result.error);
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      setIsSending(false);

      console.log("File", result.file);
      handleDownload(result.file, fileName);
      return result;
    };

    try {
      const data = await sendRequest();
      // deleteFile(data.key)
      setFileKey(data.key);
    } catch (error) {
      setIsSending(false);
      console.log(error);
    }
  };

  const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/;
  function base64Parser(dataURL) {
    if (typeof dataURL !== "string" || !base64Regex.test(dataURL)) {
      return false;
    }

    const validBase64 =
      /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;

    const stringBase64 = dataURL.replace(base64Regex, "");

    if (!validBase64.test(stringBase64)) {
      throw new Error(
        "Error parsing base64 data, your data contains invalid characters"
      );
    }

    // For nodejs, return a Buffer
    if (typeof Buffer !== "undefined" && Buffer.from) {
      return Buffer.from(stringBase64, "base64");
    }

    // For browsers, return a string (of binary content) :
    const binaryString = window.atob(stringBase64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes.buffer;
  }

  const imageOpts = {
    centered: false,
    getImage: function (tagValue, tagName) {
      // const canvas = document.getElementById("myCanvas");
      return base64Parser(tagValue.toDataURL());
    },
    getSize: function (img, url, tagName) {
      // FOR FIXED SIZE IMAGE :
      const maxWidth = 650;
      const maxHeight = 250;
      const width = element.offsetWidth;
      const height = element.offsetHeight;
      const widthRatio = width / maxWidth;
      const heightRatio = height / maxHeight;
      console.log("WIIDDTH", height);

      if (widthRatio < 1 && heightRatio < 1) {
        /*
         * Do not scale up images that are
         * smaller than maxWidth,maxHeight
         */
        return [width, height];
      }
      let finalWidth, finalHeight;
      if (widthRatio > heightRatio) {
        /*
         * Width will be equal to maxWidth
         * because width is the most "limiting"
         */
        finalWidth = maxWidth;
        finalHeight = height / widthRatio;
      } else {
        /*
         * Height will be equal to maxHeight
         * because height is the most "limiting"
         */
        finalHeight = maxHeight;
        finalWidth = width / heightRatio;
      }
      return [Math.round(finalWidth), Math.round(finalHeight)];
    },
  };

  const generateDocument = async () => {
    setIsLoading(true);
    const canvas = await html2canvas(element);
    loadFile(
      themeMode === "dark"
        ? "https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/fact_sheet_template_dark_mode.docx"
        : "https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/fact_sheet_template.docx",
      // factSheetTemplate,
      async (error, content) => {
        if (error) {
          throw error;
        }
        var zip = new PizZip(content);
        var doc = null;
        try {
          doc = new Docxtemplater(zip, {
            paragraphLoop: true,
            linebreaks: true,
            modules: [new ImageModule(imageOpts)],
          });
          await doc.renderAsync({
            company_name: companyName,
            account: selectedOption?.accountName,
            balance: numberWithCommas(currentBalance),
            equity: statsData?.equity,
            profit: statsData?.profit,
            performance: statsData?.performance,
            max: statsData?.maxdd,
            gain: statistics?.data?.gain
              ? `${Math.round(statistics?.data?.gain * 100) / 100}%`
              : 0,
            ab_gain: statistics?.data?.absGain
              ? `${Math.round(statistics?.data?.absGain * 100) / 100}%`
              : 0,
            daily_gain: statistics?.data?.daily
              ? `${Math.round(statistics?.data?.daily * 100) / 100}%`
              : 0,
            monthly_gain: statistics?.data?.monthly
              ? `${Math.round(statistics?.data?.monthly * 100) / 100}%`
              : 0,
            f_factor: statistics?.data?.profitFactor
              ? Math.round(statistics?.data?.profitFactor * 100) / 100
              : 0,
            ann_return: statistics?.data?.monthly
              ? `${Math.round(statistics?.data?.monthly * 12 * 100) / 100}%`
              : 0,
            acc_age: statistics?.data?.firstTradeDate
              ? calcDuration(
                  statistics?.data?.firstTradeDate,
                  statistics?.data?.lastUpdateDate
                )
              : 0,
            lots: statistics?.statistics?.lotsTraded
              ? Math.round(statistics?.statistics?.lotsTraded * 100) / 100
              : 0,
            comm: statistics?.data?.commission
              ? checkNum(Math.round(statistics?.data?.commission * 100) / 100)
              : 0,
            swap: statistics?.statistics?.swaps
              ? checkNum(Math.round(statistics?.statistics?.swaps * 100) / 100)
              : 0,
            deposits: statistics?.data?.deposits
              ? checkNum(Math.round(statistics?.data?.profit * 100) / 100)
              : 0,
            withdrawal: statistics?.data?.withdrawals
              ? checkNum(Math.round(statistics?.data?.withdrawals * 100) / 100)
              : 0,
            monthly_gains: newData,
            image: canvas,
          });
        } catch (error) {
          // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
          function replaceErrors(key, value) {
            if (value instanceof Error) {
              return Object.getOwnPropertyNames(value).reduce(function (
                error,
                key
              ) {
                error[key] = value[key];
                return error;
              },
              {});
            }
            return value;
          }
          console.log(JSON.stringify({ error: error }, replaceErrors));

          if (error.properties && error.properties.errors instanceof Array) {
            const errorMessages = error.properties.errors
              .map(function (error) {
                return error.properties.explanation;
              })
              .join("\n");
            console.log("errorMessages", errorMessages);
            // errorMessages is a humanly readable message looking like this :
            // 'The tag beginning with "foobar" is unopened'
          }
          throw error;
        }
        var out = doc.getZip().generate({
          type: "blob",
          mimeType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });

        uploadDocumentHandler(out, "fact_sheet.pdf"); //Output the document using Data-URI

        // handleDownloadImage();

        // saveAs(out, 'fact_sheet.docx');
      }
    );
  };

  const generateReport = async () => {
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    var d = new Date();
    var today = new Date();
    d.setDate(d.getDate() - 5);
    var month = monthNames[d.getMonth()],
      monthNow = monthNames[today.getMonth()],
      day = d.getDate(),
      todayDate = today.getDate(),
      year = today.getFullYear();

    if (day.length < 2) day = "0" + day;

    if (todayDate.length < 2) todayDate = "0" + todayDate;

    const reportDate =
      month === monthNow
        ? month + " " + day + " - " + todayDate
        : month + " " + day + " - " + monthNow + " " + todayDate;

    setIsLoading(true);
    const canvas = await html2canvas(element);
    loadFile(
      "https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/trading_reports_template.docx",
      // tradingReportsTemplate,
      async (error, content) => {
        if (error) {
          throw error;
        }
        var zip = new PizZip(content);
        var doc = null;
        try {
          doc = new Docxtemplater(zip, {
            paragraphLoop: true,
            linebreaks: true,
            modules: [new ImageModule(imageOpts)],
          });
          await doc.renderAsync({
            company_name: companyName,
            account: selectedOption?.accountName,
            account_number: mt5LoginCredentials?.credentials?.accountNumber,
            balance: numberWithCommas(currentBalance),
            equity: statsData?.equity,
            profit: statsData?.profit,
            performance: statsData?.performance,
            max: statsData?.maxdd,
            gain: statistics?.data?.gain
              ? `${Math.round(statistics?.data?.gain * 100) / 100}%`
              : 0,
            ab_gain: statistics?.data?.absGain
              ? `${Math.round(statistics?.data?.absGain * 100) / 100}%`
              : 0,
            daily_gain: statistics?.data?.daily
              ? `${Math.round(statistics?.data?.daily * 100) / 100}%`
              : 0,
            monthly_gain: statistics?.data?.monthly
              ? `${Math.round(statistics?.data?.monthly * 100) / 100}%`
              : 0,
            f_factor: statistics?.data?.profitFactor
              ? Math.round(statistics?.data?.profitFactor * 100) / 100
              : 0,
            ann_return: statistics?.data?.monthly
              ? `${Math.round(statistics?.data?.monthly * 12 * 100) / 100}%`
              : 0,
            acc_age: statistics?.data?.firstTradeDate
              ? calcDuration(
                  statistics?.data?.firstTradeDate,
                  statistics?.data?.lastUpdateDate
                )
              : 0,
            lots: statistics?.statistics?.lotsTraded
              ? Math.round(statistics?.statistics?.lotsTraded * 100) / 100
              : 0,
            comm: statistics?.data?.commission
              ? checkNum(Math.round(statistics?.data?.commission * 100) / 100)
              : 0,
            swap: statistics?.statistics?.swaps
              ? checkNum(Math.round(statistics?.statistics?.swaps * 100) / 100)
              : 0,
            deposits: statistics?.data?.deposits
              ? checkNum(Math.round(statistics?.data?.profit * 100) / 100)
              : 0,
            withdrawal: statistics?.data?.withdrawals
              ? checkNum(Math.round(statistics?.data?.withdrawals * 100) / 100)
              : 0,
            pl: statsData?.profit,
            closed_trades:
              newClosedTradeData.length > 5
                ? newClosedTradeData?.slice(0, 5)
                : newClosedTradeData,
            open_trades:
              newOpenTradeData.length > 5
                ? newOpenTradeData?.slice(0, 5)
                : newOpenTradeData,
            date_month: reportDate,
            year: year,
          });
        } catch (error) {
          // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
          function replaceErrors(key, value) {
            if (value instanceof Error) {
              return Object.getOwnPropertyNames(value).reduce(function (
                error,
                key
              ) {
                error[key] = value[key];
                return error;
              },
              {});
            }
            return value;
          }
          console.log(JSON.stringify({ error: error }, replaceErrors));

          if (error.properties && error.properties.errors instanceof Array) {
            const errorMessages = error.properties.errors
              .map(function (error) {
                return error.properties.explanation;
              })
              .join("\n");
            console.log("errorMessages", errorMessages);
            // errorMessages is a humanly readable message looking like this :
            // 'The tag beginning with "foobar" is unopened'
          }
          throw error;
        }
        var out = doc.getZip().generate({
          type: "blob",
          mimeType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });

        uploadDocumentHandler(out, "trading_report.pdf"); //Output the document using Data-URI

        // handleDownloadImage();

        // saveAs(out, 'fact_sheet.docx');
      }
    );
  };

  const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <div className={`${classes.wrapper} main-divs`}>
      {/* <Helmet>
        <meta charSet="utf-8" />
        <title>My Assets | Prime Bridge Portal</title>
        <link rel="canonical" href="https://portal.primebridge.io/dashboard" />
      </Helmet> */}
      <div
        className={`${classes.topSection} d-flex justify-content-between align-items-center`}
      >
        <h2>{companyName}</h2>
        <div className={`${classes.optionsWrapper}`}>
          <div
            style={{
              position: "relative",
              zIndex: 1000,
              backgroundColor: themeMode === "dark" ? "#111111" : "#fff",
            }}
            className={`${classes.dropDownField} ${
              showStrategies ? classes.bridgeDropDown : undefined
            }`}
            onClick={() =>
              accountsDetails.length > 1 && setShowStrategies(!showStrategies)
            }
            role="button"
          >
            <div
              className={`${classes.selectedOption} d-flex justify-content-between align-items-center `}
            >
              <div className="d-flex align-items-center ">
                <div className={classes.iconWrapper}>
                  {accountsDetails.length > 1 && (
                    <DropDownIcon
                      dropDown={showStrategies}
                      themeColor={themeMode === "light" ? "#141414" : "#fff"}
                    />
                  )}
                </div>
                {accountsDetails.length > 0
                  ? selectedOption?.accountName
                  : "No account yet"}
              </div>

              {accountsDetails.length ? (
                <div className={classes.liveButton}>Live</div>
              ) : (
                <div></div>
              )}
            </div>
            {showStrategies &&
              accountsDetails
                .filter(function (el) {
                  return el.accountName != selectedOption.accountName;
                })
                .map((item, i) => (
                  <div
                    style={{
                      paddingLeft: "28px",
                      paddingRight: "2px",
                    }}
                    className={classes.dropDownOption}
                    onClick={() => setSelectedOption(item)}
                    role="button"
                    key={i}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        {/* <div className={classes.iconWrapper}>
                        {item.accountName === selectedOption.accountName && <CheckIcon />}
                      </div> */}
                        {item.accountName}
                      </div>
                      <div className={classes.liveButton}>Live</div>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <div
          className={`${classes.statisticsCard} d-flex justify-content-center align-items-center`}
        >
          <StatisticContainer
            title={`$${
              trackingStats?.balance
                ? numberWithCommas(trackingStats?.balance)
                : 0
            }`}
            desc="BALANCE, USD"
            activeTab={chartOption === "balance"}
            onClick={() => {
              if (activeChart === "balance") {
                setDisableCard(false);
                setActiveChart("");
              } else {
                setChartOption("balance");
                setDisableCard(true);
                setSelectedTab("Overview");
                setActiveChart("balance");
              }
            }}
            disableCard={chartOption !== "balance" ? disableCard : false}
            onMouseOver={() => setChartOption("balance")}
            onMouseOut={() => setChartOption(activeChart)}
          />
          <StatisticContainer
            title={`$${
              trackingStats?.equity
                ? numberWithCommas(trackingStats?.equity)
                : 0
            }`}
            desc="EQUITY, USD"
            disableCard={chartOption !== "equity" ? disableCard : false}
            activeTab={chartOption === "equity"}
            onClick={() => {
              if (activeChart === "equity") {
                setDisableCard(false);
                setActiveChart("");
              } else {
                setChartOption("equity");
                setDisableCard(true);
                setSelectedTab("Overview");
                setActiveChart("equity");
              }
            }}
            onMouseOver={() => setChartOption("equity")}
            onMouseOut={() => setChartOption(activeChart)}
          />
          <StatisticContainer
            // title={profitsData ? (selectedProfitOption === "Unrealized" ? `${numberWithCommas(checkNum(((profitsData.balance - (+statsData?.deposit)) + floatingProfit)?.toFixed(2)))}` : selectedProfitOption === "Cumulative" ? `$${numberWithCommas(profitsData.profit?.toFixed(2))}` : `${numberWithCommas(checkNum((profitsData.balance - (+statsData?.deposit))?.toFixed(2)))}`) : ""}
            title={
              trackingStats?.profit
                ? selectedProfitOption === "Unrealized"
                  ? `${numberWithCommas(
                      checkNum(
                        trackingStats?.profit?.unrealized?.amount?.toFixed(2)
                      )
                    )}`
                  : selectedProfitOption === "Cumulative"
                  ? `$${numberWithCommas(
                      (trackingStats?.profit?.cumulative?.amount).toFixed(2)
                    )}`
                  : `${numberWithCommas(
                      checkNum(
                        trackingStats?.profit?.realized?.amount?.toFixed(2)
                      )
                    )}`
                : ""
            }
            desc="PROFIT, USD"
            showInfoIcon={true}
            activeTab={chartOption === "profit"}
            profitDropDown={true}
            // percentage={profitsData ? (selectedProfitOption === "Unrealized" ? `${((((profitsData.balance - (+statsData?.deposit)) + floatingProfit) / (+statsData?.deposit)) * 100)?.toFixed(2)}%` : selectedProfitOption === "Cumulative" ? `${((profitsData.profit / (+statsData?.deposit)) * 100)?.toFixed(2)}%` : `${(((profitsData.balance - (+statsData?.deposit)) / (+statsData?.deposit) )* 100)?.toFixed(2)}%`) : ""}
            percentage={
              trackingStats?.profit
                ? selectedProfitOption === "Unrealized"
                  ? `${trackingStats?.profit?.unrealized?.percentage?.toFixed(
                      2
                    )}%`
                  : selectedProfitOption === "Cumulative"
                  ? `${trackingStats?.profit?.cumulative?.percentage?.toFixed(
                      2
                    )}%`
                  : `${trackingStats?.profit?.realized?.percentage?.toFixed(
                      2
                    )}%`
                : ""
            }
            selectOption={selectedProfitOption}
            setSelectOption={(item) => setSelectedProfitOption(item)}
            onClick={() => {
              if (activeChart === "profit") {
                setDisableCard(false);
                setActiveChart("");
              } else {
                setChartOption("profit");
                setDisableCard(true);
                setSelectedTab("Overview");
                setActiveChart("profit");
              }
            }}
            disableCard={chartOption !== "profit" ? disableCard : false}
            themeMode={themeMode}
            onMouseOver={() => setChartOption("profit")}
            onMouseOut={() => setChartOption(activeChart)}
          />
          {companyTheme === "default" && (
            <StatisticContainer
              title={
                trackingStats?.gain
                  ? selectedGainOption === "Deposit"
                    ? `${Math.round(trackingStats?.gain?.deposit * 100) / 100}%`
                    : `${trackingStats?.gain?.liquidity}%`
                  : 0
              }
              desc="GAIN"
              showInfoIcon={true}
              dropDown={true}
              selectOption={selectedGainOption}
              setSelectOption={(item) => setSelectedGainOption(item)}
              themeMode={themeMode}
              activeTab={chartOption === "gain"}
              onClick={() => {
                if (activeChart === "gain") {
                  setDisableCard(false);
                  setActiveChart("");
                } else {
                  setChartOption("gain");
                  setDisableCard(true);
                  setSelectedTab("Overview");
                  setActiveChart("gain");
                }
              }}
              disableCard={chartOption !== "gain" ? disableCard : false}
              onMouseOver={() => setChartOption("gain")}
              onMouseOut={() => setChartOption(activeChart)}
            />
          )}
          <StatisticContainer
            title={
              trackingStats?.maxdd ? `${trackingStats?.maxdd?.toFixed(2)}%` : 0
            }
            desc="MAX DD"
            noborder={true}
            activeTab={chartOption === "maxdd"}
            onClick={() => {
              if (activeChart === "maxdd") {
                setDisableCard(false);
                setActiveChart("");
              } else {
                setChartOption("maxdd");
                setDisableCard(true);
                setSelectedTab("Overview");
                setActiveChart("maxdd");
              }
            }}
            disableCard={chartOption !== "maxdd" ? disableCard : false}
            onMouseOver={() => setChartOption("maxdd")}
            onMouseOut={() => setChartOption(activeChart)}
          />
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <div className={`${classes.tabsWrapper} d-flex align-items-center`}>
          {tabsData.map((item, i) => (
            <div
              className={`${classes.tabText} ${
                selectedTab === item ? classes.activeTab : undefined
              } d-flex flex-column`}
              key={i}
              role="button"
              onClick={() => setSelectedTab(item)}
            >
              {item}
              {selectedTab === item && (
                <div className={classes.underLineText}></div>
              )}
            </div>
          ))}
        </div>
        <div className={`${classes.buttonsWrapper} d-flex align-items-center`}>
          <button
            className={classes.mtLogin}
            onClick={() => setShowMt5Login(true)}
          >
            View MT5 Login
          </button>
          {/* {!isloading && (<button className={classes.downloadButton} onClick={generateDocument}>
            <DownloadIcon
              themeColor={themeMode === "light" ? "#000" : "#fff"}
            />
            <span className="ms-3" >
              Fact Sheet
            </span>
          </button>)} */}
          {/* {isloading && (
          <div class="spinner-border" role="status">

            
          </div>
          
        )} */}

          {!isloading ||
            (companyTheme === "default" && (
              <div className={`${classes.downloadOptionsWrapper}`}>
                <div
                  className={`${classes.dropDownFieldDownload} ${
                    showDownloadOptions ? classes.bridgeDropDown : undefined
                  }`}
                  onClick={() => setShowDownloadOptions(!showDownloadOptions)}
                  role="button"
                >
                  <div
                    className={`${classes.selectedOption} d-flex align-items-center gap-2`}
                  >
                    <DownloadIcon
                      themeColor={themeMode === "light" ? "#000" : "#fff"}
                    />
                    Download
                  </div>
                  {showDownloadOptions &&
                    useOptions.map((item, i) => (
                      <div
                        className={`${classes.dropDownOption}`}
                        role="button"
                        onClick={i === 0 ? generateDocument : generateReport}
                        key={i}
                      >
                        {item}
                      </div>
                    ))}
                </div>
              </div>
            ))}
          {isloading && <div class="spinner-border" role="status"></div>}

          <button
            className={`${classes.downloadButton} d-flex align-items-center`}
            onClick={() => setShowShareLink(true)}
          >
            <ShareArrowIcon
              themeColor={themeMode === "light" ? "#000" : "#fff"}
            />
            <span className="ms-3">Share</span>
          </button>
        </div>
      </div>
      {selectedTab === "Overview" && (
        <>
          {(chartOption === "balance" || chartOption === "") && (
            <ChartComponent
              currentBalance={numberWithCommas(currentBalance?.balance)}
              maxBalance={maxBalance}
              data={chartData}
              selectedOption={selectedGainOption}
              query={(period) => {
                // const date = days === "all" ? "all" : getStartDate(days)
                getTrackingChartBalance(
                  companyId,
                  selectedOption?._id,
                  "profit",
                  period
                );
              }}
              primaryColor={PRIMARY_COLOR}
              accentColor={ACCENT_COLOR}
            />
          )}

          {chartOption === "equity" && (
            <EquityChartComponent
              currentBalance={numberWithCommas(currentBalance?.balance)}
              maxBalance={equityMaxBalance}
              data={equityChartData}
              selectedOption={selectedGainOption}
              query={(period) => {
                // const date = days === "all" ? "all" : getStartDate(days)
                getTrackingChartEquity(
                  companyId,
                  selectedOption?._id,
                  "profit",
                  period
                );
              }}
              primaryColor={PRIMARY_COLOR}
              accentColor={ACCENT_COLOR}
            />
          )}

          {chartOption === "profit" && (
            <ProfitChartComponents
              data={profitChartData}
              query={(period) => {
                // const date = days === "all" ? "all" : getStartDate(days)

                getTrackingChartProfit(
                  companyId,
                  selectedOption?._id,
                  "profit",
                  period
                );
              }}
              themeMode={themeMode}
              primaryColor={PRIMARY_COLOR}
              accentColor={ACCENT_COLOR}
            />
          )}

          {chartOption === "gain" && (
            <GainChartComponents
              data={gainChartData}
              query={(period) => {
                // const date = days === "all" ? "all" : getStartDate(days)
                getTrackingChartGain(
                  companyId,
                  selectedOption?._id,
                  "gain",
                  period
                );
              }}
              themeMode={themeMode}
              selectedOption={selectedGainOption}
              primaryColor={PRIMARY_COLOR}
              accentColor={ACCENT_COLOR}
            />
          )}

          {chartOption === "maxdd" && (
            <BarChartComponent
              data={maxDDChartData}
              query={(period) => {
                // const date = days === "all" ? "all" : getStartDate(days)

                getTrackingChartMaxdd(
                  companyId,
                  selectedOption?._id,
                  "profit",
                  period
                );
                // fetchMaxddChartData(
                //   mt5LoginCredentials?.credentials?.accountNumber,
                //   date
                // )
              }}
              themeMode={themeMode}
            />
          )}

          <OpenTrades />
        </>
      )}
      {selectedTab === "Statistics" && (
        <>
          <Statistics
            accountNumber={mt5LoginCredentials?.credentials?.accountNumber}
            apiId={mt5LoginCredentials?.credentials?.apiId}
            statsData={statsData}
            id={mt5LoginCredentials?._id}
            companyId={companyId}
            deposit={mt5LoginCredentials?.credentials?.depositCorrection}
            cumulativeCorrection={
              mt5LoginCredentials?.credentials?.cumulativeCorrection
            }
          />
        </>
      )}
      {selectedTab === "Trading" && (
        <>
          <OpenTrades showNumber={true} />
          <ClosedTrades
            accountNumber={mt5LoginCredentials?.credentials?.accountNumber}
            accountId={mt5LoginCredentials?._id}
            companyId={companyId}
          />
        </>
      )}

      {showMt5Login && (
        <div className={classes.mt5Login}>
          <div className="d-flex flex-row-reverse">
            <CloseIcon
              onClick={() => setShowMt5Login(false)}
              themeColor={themeMode === "light" ? "#000" : "#fff"}
            />
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center mt-2">
            <h4>MT5 Login</h4>
            <div
              className={`d-flex flex-column justify-content-center align-items-center mt-3 ${classes.credentials}`}
            >
              <div className="">
                Account ID: {mt5LoginCredentials?.credentials?.accountNumber}
              </div>
              <div className="d-flex justify-content-center align-items-center">
                Master Password:{" "}
                <span className="ms-2">
                  {showMasterPassword ? (
                    mt5LoginCredentials?.credentials?.masterPassword
                  ) : (
                    <span className={classes.hiddenPassword}>• • • • • •</span>
                  )}
                </span>
                <div className="mx-3">
                  <LockIcon
                    onClick={() => setShowMasterPassword(!showMasterPassword)}
                    themeColor={themeMode === "light" ? "#000" : "#fff"}
                  />
                </div>
                <div
                  onClick={() =>
                    navigator.clipboard.writeText(
                      mt5LoginCredentials?.credentials?.masterPassword
                    )
                  }
                >
                  <CopyIcon currentTheme={themeMode} />
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                Investor Password:{" "}
                <span className="ms-2">
                  {showInvestorPassword ? (
                    mt5LoginCredentials?.credentials?.investorPassword
                  ) : (
                    <span className={classes.hiddenPassword}>• • • • • •</span>
                  )}
                </span>
                <div className="mx-3">
                  <LockIcon
                    onClick={() =>
                      setShowInvestorPassword(!showInvestorPassword)
                    }
                    themeColor={themeMode === "light" ? "#000" : "#fff"}
                  />
                </div>
                <div
                  onClick={() =>
                    navigator.clipboard.writeText(
                      mt5LoginCredentials?.credentials?.investorPassword
                    )
                  }
                >
                  <CopyIcon currentTheme={themeMode} />
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                Server: {mt5LoginCredentials?.credentials?.server}
                <div
                  className="ms-2"
                  onClick={() => navigator.clipboard.writeText("TCBridge-Live")}
                >
                  <CopyIcon currentTheme={themeMode} />
                </div>
              </div>
            </div>

            <div className="d-flex flex-column justify-content-center align-items-center mt-5">
              <h5>Download Platform</h5>
              <div
                className={`${classes.buttonsLinksWrapper} d-flex justify-content-center align-items-center mt-3`}
              >
                <a
                  href="https://download.mql5.com/cdn/web/17666/mt5/tcbridge5setup.exe"
                  className="d-flex justify-content-center align-items-center"
                >
                  Windows
                </a>
                <a
                  href="https://download.mql5.com/cdn/web/metaquotes.software.corp/mt5/MetaTrader5.dmg"
                  className="d-flex justify-content-center align-items-center"
                >
                  Mac
                </a>
              </div>
            </div>
          </div>
        </div>
      )}

      {showShareLink && (
        <PortfolioLinkModal
          classes={classes}
          themeMode={themeMode}
          setShowShareLink={() => setShowShareLink(false)}
          accountId={mt5LoginCredentials?.credentials?.accountNumber}
        />
      )}
    </div>
  );
};

export default Assets;
