import { useThemeColors } from "../hooks/useThemeColors";

const AddUserIcon = () => {
  const { PRIMARY_COLOR } = useThemeColors();
  return (
    <svg
      width="27"
      height="20"
      viewBox="0 0 34 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26 13C26 13.5523 26.4477 14 27 14C27.5523 14 28 13.5523 28 13V9H32C32.5523 9 33 8.55228 33 8C33 7.44772 32.5523 7 32 7H28V3C28 2.44771 27.5523 2 27 2C26.4477 2 26 2.44771 26 3V7H22C21.4477 7 21 7.44772 21 8C21 8.55228 21.4477 9 22 9H26V13Z"
        fill={PRIMARY_COLOR}
      />
      <path
        d="M28 9V8.75H27.75V9H28ZM28 7H27.75V7.25H28V7ZM26 7V7.25H26.25V7H26ZM26 9H26.25V8.75H26V9ZM27 13.75C26.5858 13.75 26.25 13.4142 26.25 13H25.75C25.75 13.6904 26.3096 14.25 27 14.25V13.75ZM27.75 13C27.75 13.4142 27.4142 13.75 27 13.75V14.25C27.6904 14.25 28.25 13.6904 28.25 13H27.75ZM27.75 9V13H28.25V9H27.75ZM32 8.75H28V9.25H32V8.75ZM32.75 8C32.75 8.41421 32.4142 8.75 32 8.75V9.25C32.6904 9.25 33.25 8.69036 33.25 8H32.75ZM32 7.25C32.4142 7.25 32.75 7.58579 32.75 8H33.25C33.25 7.30964 32.6904 6.75 32 6.75V7.25ZM28 7.25H32V6.75H28V7.25ZM27.75 3V7H28.25V3H27.75ZM27 2.25C27.4142 2.25 27.75 2.58579 27.75 3H28.25C28.25 2.30964 27.6904 1.75 27 1.75V2.25ZM26.25 3C26.25 2.58579 26.5858 2.25 27 2.25V1.75C26.3096 1.75 25.75 2.30964 25.75 3H26.25ZM26.25 7V3H25.75V7H26.25ZM22 7.25H26V6.75H22V7.25ZM21.25 8C21.25 7.58579 21.5858 7.25 22 7.25V6.75C21.3096 6.75 20.75 7.30964 20.75 8H21.25ZM22 8.75C21.5858 8.75 21.25 8.41421 21.25 8H20.75C20.75 8.69036 21.3096 9.25 22 9.25V8.75ZM26 8.75H22V9.25H26V8.75ZM26.25 13V9H25.75V13H26.25Z"
        fill={PRIMARY_COLOR}
      />
      <path
        d="M15.5714 6.05882C15.5714 8.30045 13.7486 10.1176 11.5 10.1176C9.25141 10.1176 7.42857 8.30045 7.42857 6.05882C7.42857 3.8172 9.25141 2 11.5 2C13.7486 2 15.5714 3.8172 15.5714 6.05882Z"
        stroke={PRIMARY_COLOR}
        stroke-width="2.5"
      />
      <path
        d="M2 22.9706C2 18.1137 5.94949 14.1765 10.8214 14.1765H12.1786C17.0505 14.1765 21 18.1137 21 22.9706V23.6471C21 24.3943 20.3924 25 19.6429 25H3.35714C2.60761 25 2 24.3943 2 23.6471V22.9706Z"
        stroke={PRIMARY_COLOR}
        stroke-width="2.5"
      />
    </svg>
  );
};

export default AddUserIcon;
