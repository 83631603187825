import { useEffect } from "react";
import { useDomainContext } from "../contexts/DomainContext";
import { capitalizeFirstLetter } from "../utilities/functions";

export const useThemeSettings = () => {
    const { companyTheme } = useDomainContext();

    useEffect(() => {
        if (companyTheme?.name) {
            document.title = `${capitalizeFirstLetter(companyTheme.name)} Portal`;
        }

        if (companyTheme?.favicon) {
            const link = document.querySelector("link[rel~='icon']");
            if (link) {
                link.href = companyTheme.favicon;
            }
        }
    }, [companyTheme]);
};