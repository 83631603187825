import { createContext, useContext, useState, useEffect } from "react";
import { env } from "../constants/env";

export const FeaturesContext = createContext();

export const FeaturesProvider = ({ children }) => {
  const [whitelabel, setWhitelabel] = useState(null);
  const [overview, setOverview] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchSingleCompany = async (companyId) => {
    setLoading(true); // Start loading
    try {
      const response = await fetch(
        `${env.primeBridgeUrl}/api/company/${companyId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Fetching company failed.");
      }

      const result = await response.json();
      setOverview(result.overview ? result.overview : "");
      setWhitelabel(result.whitelabel ? result.theme : null);
    } catch (error) {
      console.error("Error fetching company:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <FeaturesContext.Provider value={{ whitelabel, overview, loading, fetchSingleCompany }}>
      {children}
    </FeaturesContext.Provider>
  );
};

// Custom hook for using ThemeContext
export const useFeatures = () => useContext(FeaturesContext);