import { Helmet } from "react-helmet"
import FindIcon from "../../icons/FindIcon"
import ManageIcon from "../../icons/ManageIcon"
import HelpCard from "./components/HelpCard"
import classes from "./HelpCenter.module.css"
import { useDynamicTitle } from "../../hooks/useDynamicTitle"
import { useDomainContext } from "../../contexts/DomainContext"

const HelpCenter = ({themeMode}) => {
    useDynamicTitle("Help Center", "/dashboard/help");
    const { companyTheme } = useDomainContext();
    return(
       <div className={classes.wrapper}>
        {/* <Helmet>
          <meta charSet="utf-8" />
          <title>Help Center | Prime Bridge Portal</title>
          <link rel="canonical" href="https://portal.primebridge.io/dashboard/help" />
        </Helmet> */}
           
           <div className={`${classes.cardsWrapper} d-flex justify-content-center align-items-end`}>
            <div>
                <h3 className="mb-5">Help Center</h3>
                <HelpCard title="Managing my account" desc="Read articles with essential Prime Bridge information" clickable={true}>
                    <ManageIcon themeColor={themeMode === "light" ? companyTheme?.colors ? companyTheme.colors.primary : "#033C59" : "#fff"}/>
                </HelpCard>
            </div>
            <HelpCard title="Customer Support" button="Contact us">
                <FindIcon themeColor={themeMode === "light" ? companyTheme?.colors ? companyTheme.colors.primary : "#033C59" : "#fff"}/>
            </HelpCard>
           </div>
       </div> 
    )
}

export default HelpCenter