import { HStack, VStack } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import React, { useEffect } from "react";
import {
  getCookie,
  numberWithCommas,
} from "../../../../../../utilities/functions";
import { usePageContext } from "../../../../contexts/page.context";
import InfoIcon from "../../../../components/Info.icon";
import { Text } from "../../../../components/text";
import Tip from "./../tip/NormalTip";
import useSendFundsStore from "../../store";
import { useSelector } from "react-redux";
import useWalletStore from "../../../../store";
import useSendFunds from "../../hooks/useSendFunds";
import useSaveAddress from "../../hooks/useSaveAddress";
import { useThemeColors } from "../../../../../../hooks/useThemeColors";
import { useDomainContext } from "../../../../../../contexts/DomainContext";

const BankDetails = () => {
  const { lightMode } = usePageContext();
  const companyId = getCookie("companyId");
  const source = useSendFundsStore((s) => s.firstDetails.source);
  const closeSection = useWalletStore((s) => s.closeSection);
  const completePage = useSendFundsStore((s) => s.completePage);
  const inCompletePage = useSendFundsStore((s) => s.inCompletePage);
  const amount = useSendFundsStore((s) => s.secondDetails.amount);
  const bankName = useSendFundsStore((s) => s.bankDetails.bankName);
  const accountName = useSendFundsStore((s) => s.bankDetails.accountName);
  const accountNumber = useSendFundsStore((s) => s.bankDetails.accountNumber);
  const address = useSendFundsStore((s) => s.bankDetails.address);
  const saveAddress = useSendFundsStore((s) => s.bankDetails.saveAddress);
  const swift = useSendFundsStore((s) => s.bankDetails.swift);
  const sortCode = useSendFundsStore((s) => s.bankDetails.sortCode);
  const { mutateAsync: sendFunds } = useSendFunds();
  const { mutateAsync: saveBankAddress } = useSaveAddress();
  const resetPage = useSendFundsStore((s) => s.resetPage);
  const inputSource = useWalletStore((s) => s.inputSource);
  const pbShareSettings = source?.pbShareSettings;
  const withdrawalSettings = useSelector(
    (state) => state.company.withdrawalSettings
  );
  const { PRIMARY_COLOR, SECONDARY_COLOR_DARK } = useThemeColors();
  const { companyTheme } = useDomainContext();


  useEffect(() => {
    completePage();
    return () => {
      inCompletePage();
    };
  }, []);

  const handleSubmit = () => {
    sendFunds().then(async () => {
      if (saveAddress) {
        await saveBankAddress();
      }
      resetPage();
      inputSource("");
      closeSection();
    });
  };

  return (
    <HStack
      mt={"8px"}
      justifyContent={"space-between"}
      bg={lightMode ? "#f5f5f5" : SECONDARY_COLOR_DARK}
      borderRadius={"15px"}
      w={"100%"}
      px={"37px"}
      py={"23px"}
    >
      <HStack gap={"49px"} alignItems={"flex-start"}>
     <VStack gap={"14px"} alignItems={"flex-start"}>
          <Text mb={"8px"} fontWeight={"600"} fontSize={"14px"}>
            Withdrawal Details
          </Text>
          {companyTheme === "default" && <>
          <Text fontWeight={"500"} fontSize={"14px"}>
            Bank Name: {bankName}
          </Text>
          <Text fontWeight={"500"} fontSize={"14px"}>
            Account Name: {accountName}
          </Text>
          <Text fontWeight={"500"} fontSize={"14px"}>
          IBAN: {accountNumber}
          </Text>
          <Text fontWeight={"500"} fontSize={"14px"}>
            Address: {address}
          </Text>
          <Text fontWeight={"500"} fontSize={"14px"}>
            Branch/Sort Code: {sortCode}
          </Text>
          <Text fontWeight={"500"} fontSize={"14px"}>
            Swift: {swift}
          </Text>
          </>}
        </VStack>
        <VStack mt={"42px"} gap={"14px"} alignItems={"flex-start"}>
          <HStack>
            <Text fontWeight={"500"} fontSize={"14px"}>
              Total Withdrawal: US${" "}
              {numberWithCommas(
                parseFloat(amount.replace(/,/g, "")).toFixed(2)
              )}
            </Text>
            <Tip icon={<InfoIcon size={"12"} />}>
              Amount requested for withdrawal
            </Tip>
          </HStack>
          {companyTheme === "defualt" && <HStack>
            <Text fontWeight={"500"} fontSize={"14px"}>
              PB Share: US${" "}
              {numberWithCommas(
                Math.round(
                  parseFloat(amount.replace(/,/g, "")).toFixed(2) *
                    +pbShareSettings *
                    100
                ) / 100
              )}
            </Text>
            <Tip icon={<InfoIcon size={"12"} />}>
              Prime Bridge's portion of the withdrawal
            </Tip>
          </HStack>}
          {companyTheme === "default" && <HStack>
            <Text fontWeight={"500"} fontSize={"14px"}>
              Withdrawal Fee: US${" "}
              {numberWithCommas(
                Math.round(
                  (parseFloat(amount.replace(/,/g, "")).toFixed(2) -
                    parseFloat(amount.replace(/,/g, "")).toFixed(2) *
                      +pbShareSettings) *
                    +withdrawalSettings *
                    100
                ) / 100
              )}
            </Text>
            <Tip icon={<InfoIcon size={"12"} />}>
              Fee paid to the regulated custodian
            </Tip>
          </HStack>}
          {companyTheme === "default" && <HStack>
            <Text fontWeight={"500"} fontSize={"14px"}>
              Net Payout: US${" "}
              {numberWithCommas(
                Math.round(
                  (parseFloat(amount.replace(/,/g, "")) -
                    parseFloat(amount.replace(/,/g, "")) * +pbShareSettings -
                    (parseFloat(amount.replace(/,/g, "")) -
                      parseFloat(amount.replace(/,/g, "")) * +pbShareSettings) *
                      +withdrawalSettings) *
                    100
                ) / 100
              )}
            </Text>
            <Tip icon={<InfoIcon size={"12"} />}>
              Final amount you receive after deductions
            </Tip>
          </HStack>}
        </VStack>
      </HStack>
      <Button
        width={"213px"}
        gap={"10px"}
        borderRadius={"31px"}
        bg={PRIMARY_COLOR}
        _hover={{
          bg: PRIMARY_COLOR,
          color: "white",
        }}
        fontWeight={"700"}
        color={"white"}
        height={"48px"}
        onClick={handleSubmit}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        fontSize={"13px"}
      >
        Confirm
      </Button>
    </HStack>
  );
};

export default BankDetails;
