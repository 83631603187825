import { Box, Button, HStack, Text, VStack, Wrap } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon, Icon } from "@chakra-ui/icons";
import React, { useEffect, useState } from "react";
import { MONTHS, useCalendarContext } from "./calendar.context";
import Month from "./Month";
import { usePageContext } from "../../contexts/page.context";
import CircledXIcon from "../../icons/CircledX.icon";
import { START_DATE } from "@datepicker-react/hooks";
import { useThemeColors } from "../../../../hooks/useThemeColors";

const Calendar = ({ onClose, dates }) => {
  const {
    activeMonths,
    dateState,
    setDateState,
    goToPreviousMonths,
    goToNextMonths,
    firstDayOfWeek,
  } = useCalendarContext();
  const { lightMode } = usePageContext();
  const isActive = dateState?.startDate && dateState?.endDate;

  const { PRIMARY_COLOR, SECONDARY_COLOR_DARK } = useThemeColors();

  useEffect(() => {
    setDateState(dates);
    console.log(dates);
    return () => {};
  }, []);

  return (
    <VStack w={"100%"}>
      <Box
        cursor={"pointer"}
        onClick={() => {
          if (dateState?.startDate || dateState?.endDate)
            setDateState({
              startDate: null,
              endDate: null,
              focusedInput: START_DATE,
            });
          else onClose();
        }}
        position={"absolute"}
        top={"16px"}
        right={"24px"}
      >
        <Icon color={lightMode ? "#d0d0d0" : "#343C40"} as={CircledXIcon} />
      </Box>
      <HStack mb={"15px"} gap={"12px"} justifyContent={"center"}>
        <Box
          cursor={"pointer"}
          onClick={() => {
            goToPreviousMonths();
          }}
        >
          <Icon
            color={lightMode ? "black" : "white"}
            as={ChevronLeftIcon}
            w={4}
            height={"auto"}
          />
        </Box>
        <Text mb={0} color={lightMode ? "black" : "white"} fontSize={"16px"}>
          <Text mb={0} display={"inline-block"} mr={1} fontWeight={"600"}>
            {MONTHS[activeMonths[0].month] + " "}
          </Text>
          {activeMonths[0].year}
        </Text>
        <Box
          cursor={"pointer"}
          onClick={() => {
            goToNextMonths();
          }}
        >
          <Icon
            color={lightMode ? "black" : "white"}
            as={ChevronRightIcon}
            w={4}
            height={"auto"}
          />
        </Box>
      </HStack>
      <VStack w={"100%"}>
        {activeMonths.map((month) => (
          <Month
            goToPreviousMonths={goToPreviousMonths}
            goToNextMonths={goToNextMonths}
            key={`${month.year}-${month.month}`}
            year={month.year}
            month={month.month}
            firstDayOfWeek={firstDayOfWeek}
          />
        ))}
      </VStack>

      <Button
        mt={"16px"}
        fontSize={"14px"}
        width={"100%"}
        border={"none"}
        _hover={{
          color: isActive ? "white" : PRIMARY_COLOR,
        }}
        onClick={() => {
          if (isActive) onClose(dateState);
        }}
        height={"42px"}
        borderRadius={"50px"}
        bg={isActive ? PRIMARY_COLOR : lightMode ? "#D0D0D0" : SECONDARY_COLOR_DARK}
        color={"white"}
      >
        Update
      </Button>
    </VStack>
  );
};

export default Calendar;
