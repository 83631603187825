import { Button } from "@chakra-ui/button";
import { ChevronRightIcon } from "@chakra-ui/icons";
import React from "react";
import { usePageContext } from "../contexts/page.context";
import { useThemeColors } from "../../../hooks/useThemeColors";

const NextButton = ({ active, onSubmit }) => {
  const { lightMode } = usePageContext();
  const { PRIMARY_COLOR, SECONDARY_COLOR_DARK } = useThemeColors();
  return (
    <Button
      w={"140px"}
      bg={active ? PRIMARY_COLOR : lightMode ? "#D0D0D0" : SECONDARY_COLOR_DARK}
      justifyContent={"center"}
      alignItems={"center"}
      borderRadius={"50px"}
      _hover={{
        color: "#fff",
      }}
      h={"42px"}
      display={"flex"}
      fontSize={"16px"}
      fontWeight={"700"}
      color={"#fff"}
      onClick={() => {
        if (active) onSubmit();
      }}
    >
      Next <ChevronRightIcon />
    </Button>
  );
};

export default NextButton;
