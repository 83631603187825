import { Box, Center } from "@chakra-ui/react";
import { useDay } from "@datepicker-react/hooks";
import React, { useRef } from "react";
import { useCalendarContext } from "./calendar.context";
import { Text } from "./../text";
import { usePageContext } from "../../contexts/page.context";
import { useThemeColors } from "../../../../hooks/useThemeColors";
function Day({ dayLabel, date }) {
  const dayRef = useRef(null);
  const {
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateSelect,
    onDateFocus,
    onDateHover,
    dateState,
  } = useCalendarContext();

  const { onClick, tabIndex } = useDay({
    date,
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateSelect,
    onDateHover,
    dayRef,
  });
  const { lightMode } = usePageContext();
  const isStartDate = date - dateState?.startDate === 0;
  const isEndDate = date - dateState?.endDate === 0;
  const withinRange =
    date >= dateState?.startDate && date <= dateState?.endDate;
  // const isPresentOrPast = date < new Date()
  //   || (new Date().getMonth() - date.getMonth() >= 0
  //     && new Date().getFullYear() - date.getFullYear() + 1 >= 0)
  const isPresentOrPast = date < new Date();
  const { PRIMARY_COLOR, SECONDARY_COLOR, SECONDARY_COLOR_DARK } = useThemeColors();
  return (
    <Center
      id="day"
      cursor={"pointer"}
      position={"relative"}
      style={{
        height: "40px",
        backgroundColor:
          isStartDate || isEndDate
            ? "rgba(81, 200, 114, 0)"
            : withinRange
            ? lightMode
              ? SECONDARY_COLOR
              : SECONDARY_COLOR_DARK
            : "",
      }}
      ref={dayRef}
      onClick={() => {
        if (isPresentOrPast) onClick();
      }}
      tabIndex={tabIndex}
    >
      {withinRange && <Box className="pink-bg"></Box>}
      {isStartDate && (
        <Box
          position={"absolute"}
          top={0}
          left={0}
          width={"100%"}
          height={"100%"}
        ></Box>
      )}
      {isEndDate && (
        <Box
          position={"absolute"}
          top={0}
          right={0}
          width={"100%"}
          height={"100%"}
        ></Box>
      )}

      {(isStartDate || isEndDate) && (
        <Box
          top={0}
          width={"100%"}
          height={"100%"}
          position={"absolute"}
          bg={PRIMARY_COLOR}
          borderRadius={"4px"}
        ></Box>
      )}
      <Text
        zIndex={"10"}
        color={
          isStartDate || isEndDate
            ? lightMode
              ? "white"
              : "black"
            : !isPresentOrPast
            ? lightMode
              ? "#c6c6c6"
              : "#343C40"
            : lightMode
            ? "black"
            : "white"
        }
        fontSize={"15px"}
      >
        {dayLabel}
      </Text>
    </Center>
  );
}

export default Day;
