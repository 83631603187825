import { useThemeColors } from "../hooks/useThemeColors";

const TransactionIcon = ({ active, category, themeColor }) => {
  const width = category ? "71" : "31"
  const height = category ? "69" : "29"
  const { PRIMARY_COLOR } = useThemeColors();
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 43 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{flex: "none"}}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 4H36.11C37.2146 4 38.11 4.89543 38.11 6V12.7385H26.1605C23.3991 12.7385 21.1605 14.9771 21.1605 17.7385V25.7752C21.1605 28.5366 23.3991 30.7752 26.1605 30.7752H38.11V34.7063C38.11 35.8109 37.2146 36.7064 36.11 36.7064H6C4.89543 36.7064 4 35.8109 4 34.7064V6.00001C4 4.89543 4.89544 4 6 4ZM0 6.00001C0 2.6863 2.68629 0 6 0H36.11C39.4237 0 42.11 2.68629 42.11 6V34.7063C42.11 38.0201 39.4237 40.7064 36.11 40.7064H6C2.6863 40.7064 0 38.0201 0 34.7064V6.00001ZM26.1605 16.7385C25.6082 16.7385 25.1605 17.1862 25.1605 17.7385V25.7752C25.1605 26.3275 25.6082 26.7752 26.1605 26.7752H38.0045V16.7385H26.1605ZM32.9862 21.7568C32.9862 23.3073 31.7293 24.5642 30.1788 24.5642C28.6284 24.5642 27.3715 23.3073 27.3715 21.7568C27.3715 20.2064 28.6284 18.9495 30.1788 18.9495C31.7293 18.9495 32.9862 20.2064 32.9862 21.7568Z"
        fill={active ? PRIMARY_COLOR : category ? "#D0D0D0" : themeColor ? themeColor : "#000000"}
      />
    </svg>
  );
};
export default TransactionIcon;
