import React from "react";
import { useThemeColors } from "../../../hooks/useThemeColors";

const TransferIcon = () => {
  const { PRIMARY_COLOR } = useThemeColors();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="23"
      viewBox="0 0 33 23"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.6067 0C24.1011 0 23.6144 0.194444 23.2542 0.543357C22.8936 0.892574 22.6893 1.36822 22.6893 1.86622C22.6893 2.36421 22.8941 2.8403 23.2546 3.18952L26.4675 6.2877H8.39326C7.88942 6.2877 7.40442 6.48147 7.04541 6.82917C6.68609 7.17719 6.48244 7.6512 6.48244 8.14749C6.48244 8.64378 6.68609 9.11779 7.04541 9.46581C7.40442 9.81352 7.88942 10.0073 8.39326 10.0073H31.0921L31.0935 10.0073C31.4687 10.0055 31.8357 9.89616 32.1483 9.69213C32.4607 9.48825 32.7052 9.1985 32.8498 8.85828C32.9969 8.5188 33.0372 8.14417 32.9651 7.78196C32.893 7.41937 32.7119 7.0869 32.446 6.82598L25.9593 0.543357C25.7808 0.370522 25.5695 0.233893 25.3375 0.140836C25.1056 0.0477848 24.8573 0 24.6067 0ZM1.90788 12.569L1.90648 12.569C1.53125 12.5708 1.16424 12.6801 0.851658 12.8841C0.539317 13.088 0.294777 13.3777 0.15022 13.7179C0.00309103 14.0574 -0.0372156 14.4321 0.0348421 14.7943C0.106967 15.1569 0.288124 15.4894 0.55395 15.7503L0.555334 15.7516L7.04049 22.0327C7.2184 22.2062 7.4295 22.3436 7.66141 22.4371C7.89358 22.5308 8.14228 22.5789 8.39326 22.5789C8.64424 22.5789 8.89295 22.5308 9.12511 22.4371C9.35692 22.3436 9.56795 22.2064 9.74581 22.0329C9.92492 21.8606 10.0676 21.6553 10.1652 21.4283C10.263 21.201 10.3135 20.9569 10.3135 20.71C10.3135 20.4632 10.263 20.2191 10.1652 19.9917C10.0675 19.7646 9.9243 19.5587 9.74496 19.3864L6.53253 16.2886H24.6067C25.1106 16.2886 25.5956 16.0948 25.9546 15.7471C26.3139 15.3991 26.5175 14.9251 26.5175 14.4288C26.5175 13.9325 26.3139 13.4585 25.9546 13.1104C25.5956 12.7627 25.1106 12.569 24.6067 12.569L1.90788 12.569ZM9.74581 22.0329L9.74666 22.0321L9.54442 21.825L9.74496 22.0337L9.74581 22.0329Z"
        fill={PRIMARY_COLOR}
      />
    </svg>
  );
};

export default TransferIcon;
