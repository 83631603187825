import { HStack } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import React, { useEffect } from "react";
import usePagination, { DOTS } from "./usePagination";
import { useThemeColors } from "../../../../hooks/useThemeColors";

const Pagination = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const { PRIMARY_COLOR } = useThemeColors();

  // If there are less than 2 times in pagination range we shall not render the component
  // if (currentPage === 0 || paginationRange!.length < 2) {
  //     return null;
  // }

  useEffect(() => {
    if (!!totalCount) onPageChange(1);
    return () => {};
  }, [totalCount]);

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  // let lastPage = paginationRange[paginationRange?.length - 1];
  return (
    <HStack w={"100%"} justifyContent={"center"} gap={"9px"} px={"41px"}>
      {/* Left navigation arrow */}
      <HStack gap={"14px"}>
        {paginationRange?.map((pageNumber, index) => {
          // If the pageItem is a DOT, render the DOTS unicode character
          if (pageNumber === DOTS) {
            return (
              <Button
                key={index}
                onClick={() => onPageChange(pageNumber)}
                w={"41px"}
                h={"41px"}
                fontSize={"14px"}
                borderRadius={"base"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                border={"1px solid " + PRIMARY_COLOR}
                bg={"transparent"}
                color={PRIMARY_COLOR}
              >
                ...
              </Button>
            );
          }
          // Render our Page Pills
          return (
            <Button
              key={index}
              onClick={() => onPageChange(pageNumber)}
              w={"41px"}
              h={"41px"}
              fontSize={"14px"}
              borderRadius={"base"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              border={"1px solid " + PRIMARY_COLOR}
              bg={pageNumber === currentPage ? PRIMARY_COLOR : "transparent"}
              color={pageNumber === currentPage ? "white" : PRIMARY_COLOR}
            >
              {pageNumber < 10 ? "0" + pageNumber : pageNumber}
            </Button>
          );
        })}
      </HStack>

      {/*  Right Navigation arrow */}
    </HStack>
  );
};

export default Pagination;
