export function setCookie(cname, cvalue, exdays) {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  let expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

export function getCookie(cname) {
  let name = cname + '='
  let ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export function checkCookie() {
  let user = getCookie('username')
  if (user !== '') {
    alert('Welcome again ' + user)
  } else {
    user = prompt('Please enter your name:', '')
    if (user !== '' && user !== null) {
      setCookie('username', user, 365)
    }
  }
}

export function deleteCookie(name, path, domain = 'portal.primebridge.io') {
  if (getCookie(name)) {
    document.cookie =
      name +
      '= ;' +
      (path ? ';path=' + path + '' : '') +
      (domain ? ';domain=' + domain + '' : '') +
      ';expires=Thu, 01 Jan 1970 00:00:01 GMT'
  }
}

export const nFormatter = (num, digits) => {
  // var si = [
  //   { value: 1, symbol: "" },
  //   { value: 1e3, symbol: "k" },
  //   { value: 1e6, symbol: "M" },
  //   { value: 1e9, symbol: "B" },
  //   { value: 1e12, symbol: "T" },
  //   { value: 1e15, symbol: "P" },
  //   { value: 1e18, symbol: "E" },
  // ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  // var i;
  // for (i = si.length - 1; i > 0; i--) {
  //   if (num >= si[i].value) {
  //     break;
  //   }
  // }
  // return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;

  if (num >= 10000) {
    return (Math.round(num / 1000000 * 100) / 100).toFixed(2).replace(rx, "$1") + "M";
  } else if (num >= 1000) {
    return (Math.round(num / 1000 * 100) / 100).toFixed(2).replace(rx, "$1") + "k";
  } else {
    return (Math.round(num * 100) / 100).toFixed(2);
  }
};

const closePopup = (elementId) => {
  document.addEventListener("click", (evt) => {
    const flyoutEl = document.getElementById(elementId);
    let targetEl = evt.target; // clicked element      
    do {
      if (targetEl == flyoutEl) {
        // This is a click inside, does nothing, just return.
        document.getElementById(elementId).textContent = "Clicked inside!";
        return;
      }
      // Go up the DOM
      targetEl = targetEl.parentNode;
    } while (targetEl);
    // This is a click outside.      
    document.getElementById(elementId).textContent = "Clicked outside!";
  });
}

export function numberWithCommas(x) {
  return x ? x.toString()?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') : '0'
}

export function addCommas(num) {
  // Check if it's a valid number
  if (typeof num === 'number' && !isNaN(num)) {
      return num.toLocaleString();
  } else {
      return 0; // Return 0
  }
}

export const capitalizeFirstLetter = (str) => {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : "";
};