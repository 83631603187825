import { useEffect, useRef, useState } from "react";
import classes from "./NewAccount.module.css";
import { env } from "../../constants/env";
import { FaPlus, FaMinus } from "react-icons/fa"; // Import icons
import { useSelector } from "react-redux";


const AccountTypeDropdown = ({ selectedOption, setSelectedOption }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const options = [
    { value: "Instant Funding (Standard)", label: "Instant Funding (Standard)" },
    { value: "Instant Funding (Monthly)", label: "Instant Funding (Monthly)" },
  ];

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={classes.dropdownWrapper} ref={dropdownRef}>
      <div className={classes.dropdownCard} onClick={() => setIsOpen(!isOpen)}>
        {selectedOption
          ? options.find((opt) => opt.value === selectedOption)?.label
          : "Select Account Type"}
      </div>

      {isOpen && (
        <div className={classes.dropdownOptions}>
          {options.map((option) => (
            <div
              key={option.value}
              className={classes.dropdownOption}
              onClick={() => {
                setSelectedOption(option.value);
                setIsOpen(false);
              }}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};


  const AccountSizeSelector = ({ label, value, selected, onChange }) => {
    return (
      <div className="d-flex justify-content-between align-items-center my-2 w-100" >
        {/* Account Size */}
        <div className={`${classes.checkboxContainer} d-flex align-items-center`}>
          <input
            className={classes.checkInput}
            type="checkbox"
            checked={!!selected}
            onChange={() => {
              onChange((prev) => {
                const exists = prev.some((item) => item.accountSize === value);
                if (exists) {
                  return prev.filter((item) => item.accountSize !== value);
                } else {
                  return [...prev, { accountSize: value, number: 1 }];
                }
              });
            }}
          />
          <span className={classes.radioLabel}>{label}</span>
        </div>
  
        {/* Quantity */}
        <div className="d-flex align-items-center gap-2">
          <FaMinus
            className={`${classes.icon} ${!selected ? classes.disabledIcon : ""}`}
            onClick={() =>
              selected &&
              onChange((prev) =>
                prev.map((item) =>
                  item.accountSize === value
                    ? { ...item, number: Math.max(1, item.number - 1) }
                    : item
                )
              )
            }
            size={20}
            role="button"
          />
          <input
            type="number"
            value={selected ? selected.number : ""}
            min={1}
            max={99}
            className={`${classes.numberInput} ${!selected ? classes.disabledInput : ""}`}
            onChange={(e) =>
              selected &&
              onChange((prev) =>
                prev.map((item) =>
                  item.accountSize === value ? { ...item, number: Number(e.target.value) } : item
                )
              )
            }
            disabled={!selected}
          />
          <FaPlus
            className={`${classes.icon} ${!selected ? classes.disabledIcon : ""}`}
            onClick={() =>
              selected &&
              onChange((prev) =>
                prev.map((item) =>
                  item.accountSize === value ? { ...item, number: Math.min(99, item.number + 1) } : item
                )
              )
            }
            size={20}
            role="button"
          />
        </div>
      </div>
    );
  };

const NewAccount = () => {
//   const [accountSize, setAccountSize] = useState("");
  const [paymentLink, setPaymentLink] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [showError, setShowError] = useState(false);
  const [message, setMessage] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [accountSize, setAccountSize] = useState([]);
  const [accountType, setAccountType] = useState(null);

  const companyName = useSelector(state => state.company.companyName)

    const submitHandler = async () => {
      let dataContent = {accountSize, accountType, paymentLink, companyName};
      const sendRequest = async () => {
        setIsSending(true)
        const response = await fetch(`${env.primeBridgeUrl}/api/account/request-account`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataContent)
        });
  
        if (!response.ok) {
          const result = await response.json();
          console.log("ERROR RESPONSE", result)
          throw new Error("Request failed.");
        }
  
        const result = await response.json();

        console.log("REQUEST ACCOUNT RESPONSE", result)
        setMessage("Request received. Your accounts will be synced to your portal within 24 hours.");
        setTimeout(() => {
          setMessage("");
        }, 5000
        );
  
        setAccountSize([]);
        setAccountType(null)
        setPaymentLink("");

  
        return result;
      };
  
      try {
          await sendRequest();
      } catch (error) {
        console.log(error);
      }

      setIsSending(false)
    };

  return (
    <div className={`${classes.wrapper}`}>
  <div className="d-flex flex-column justify-content-center align-items-center">
    <h3>Request New Account</h3>
    <div className={`${classes.formWrapper} mt-5`}>
    
    <div className="container d-flex flex-column align-items-center " >
    {/* Header */}
    <div className="d-flex justify-content-between w-100">
      <span className={classes.columnLabel}>Account Size</span>
      <span className={classes.columnLabel}>Quantity</span>
    </div>

    {/* Account Size Options */}
    <AccountSizeSelector
      label="$25,000"
      value="$25,000"
      selected={accountSize.find((item) => item.accountSize === "$25,000")}
      onChange={setAccountSize}
    />
    <AccountSizeSelector
      label="$50,000"
      value="$50,000"
      selected={accountSize.find((item) => item.accountSize === "$50,000")}
      onChange={setAccountSize}
    />
    <AccountSizeSelector
      label="$100,000"
      value="$100,000"
      selected={accountSize.find((item) => item.accountSize === "$100,000")}
      onChange={setAccountSize}
    />
  </div>
  <AccountTypeDropdown selectedOption={accountType} setSelectedOption={setAccountType} />
      <input
        placeholder="Payment Link"
        value={paymentLink}
        onChange={(e) => setPaymentLink(e.target.value)}
      />
        {showError && ( <div className={classes.error}>Please fill in all fields</div>)}
        {message && <div className={classes.success}>{message}</div>}
      <div className="d-flex justify-content-center align-items-center">
        {!isSending && (
          <button
            className={`${
              buttonDisabled ? classes.disabledButton : undefined
            } addbutton`}
            onClick={() => {
              if (accountSize.length < 1 || !paymentLink || !accountType) {
                setShowError(true);
                setTimeout(() => {
                  setShowError(false);
                }, 5000);
              } else {
                setIsSending(true);
                submitHandler()
              }
            }}
            style={{ background: accountSize && paymentLink ? "#0077B5" : undefined }}
          >
            Submit
          </button>
        )}
        {isSending && <div className="spinner-border" role="status"></div>}
      </div>
    </div>
  </div>
</div>
  );
};
export default NewAccount;
