import React from "react";
import { useThemeColors } from "../../../hooks/useThemeColors";

const DownloadIcon = () => {
  const { PRIMARY_COLOR } = useThemeColors();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
    >
      <path
        d="M15.85 2.65595C15.8472 1.74426 16.5836 1.00287 17.4949 1.00001C18.4061 0.997149 19.1472 1.7339 19.15 2.6456L15.85 2.65595Z"
        fill={PRIMARY_COLOR}
      />
      <path
        d="M21.321 14.6778L19.1945 16.8187L19.15 2.6456L15.85 2.65595L15.8945 16.8291L13.7545 14.7016C13.1082 14.0589 12.0634 14.0622 11.4211 14.7088C10.7787 15.3555 10.782 16.4007 11.4284 17.0434L11.4325 17.0475L11.4342 17.0491L17.5643 23.1438L23.6391 17.0279L23.6483 17.0186L23.6496 17.0173L23.6508 17.016L23.6606 17.0062L23.6617 17.005L21.321 14.6778Z"
        fill={PRIMARY_COLOR}
      />
      <path
        d="M23.6617 17.005C24.3041 16.3583 24.3008 15.3131 23.6545 14.6705C23.0081 14.0278 21.9633 14.0311 21.321 14.6778L23.6617 17.005Z"
        fill={PRIMARY_COLOR}
      />
      <path
        d="M4.3 17.4922C4.3 15.6688 5.77746 14.1907 7.6 14.1907C8.51127 14.1907 9.25 13.4516 9.25 12.5399C9.25 11.6282 8.51127 10.8891 7.6 10.8891C3.95492 10.8891 1 13.8454 1 17.4922V27.3969C1 31.0437 3.95492 34 7.6 34H25.75C30.3063 34 34 30.3046 34 25.7461V17.4922C34 13.8454 31.0451 10.8891 27.4 10.8891C26.4887 10.8891 25.75 11.6282 25.75 12.5399C25.75 13.4516 26.4887 14.1907 27.4 14.1907C29.2225 14.1907 30.7 15.6688 30.7 17.4922V25.7461C30.7 28.4812 28.4838 30.6984 25.75 30.6984H7.6C5.77746 30.6984 4.3 29.2203 4.3 27.3969V17.4922Z"
        fill={PRIMARY_COLOR}
      />
      <path
        d="M15.85 2.65595C15.8472 1.74426 16.5836 1.00287 17.4949 1.00001C18.4061 0.997149 19.1472 1.7339 19.15 2.6456M15.85 2.65595L19.15 2.6456M15.85 2.65595L15.8945 16.8291L13.7545 14.7016M19.15 2.6456L19.1945 16.8187L21.321 14.6778M21.321 14.6778C21.9633 14.0311 23.0081 14.0278 23.6545 14.6705C24.3008 15.3131 24.3041 16.3583 23.6617 17.005M21.321 14.6778L23.6617 17.005M13.7545 14.7016C13.1082 14.0589 12.0634 14.0622 11.4211 14.7088C10.7787 15.3555 10.782 16.4007 11.4284 17.0434M13.7545 14.7016L11.4284 17.0434M11.4284 17.0434L11.4325 17.0475L11.4342 17.0491L17.5643 23.1438L23.6391 17.0279L23.6483 17.0186L23.6496 17.0173L23.6508 17.016L23.6606 17.0062L23.6617 17.005M7.6 14.1907C5.77746 14.1907 4.3 15.6688 4.3 17.4922V27.3969C4.3 29.2203 5.77746 30.6984 7.6 30.6984H25.75C28.4838 30.6984 30.7 28.4812 30.7 25.7461V17.4922C30.7 15.6688 29.2225 14.1907 27.4 14.1907C26.4887 14.1907 25.75 13.4516 25.75 12.5399C25.75 11.6282 26.4887 10.8891 27.4 10.8891C31.0451 10.8891 34 13.8454 34 17.4922V25.7461C34 30.3046 30.3063 34 25.75 34H7.6C3.95492 34 1 31.0437 1 27.3969V17.4922C1 13.8454 3.95492 10.8891 7.6 10.8891C8.51127 10.8891 9.25 11.6282 9.25 12.5399C9.25 13.4516 8.51127 14.1907 7.6 14.1907Z"
        stroke={PRIMARY_COLOR}
        stroke-width="0.5"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default DownloadIcon;
