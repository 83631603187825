import { Icon } from "@chakra-ui/icon";
import { Box, Center, Heading, HStack, Text, VStack } from "@chakra-ui/layout";
import { TableContainer } from "@chakra-ui/table";
import React, { useEffect, useState } from "react";
import { usePageContext } from "../../../contexts/page.context";
import ActionTip from "./ActionTip";
import HeadingTip from "./Tip";
import TransferCircleIcon from "../../../components/TransferCircle.icon";
import UploadCircleIcon from "../../../components/UploadCircle.icon";
import {
  getCookie,
  numberWithCommas,
} from "../../../../../utilities/functions";
import useWalletStore from "../../../store";
import SECTIONS from "../../actions/data/sectionTitle";
import useWalletAccounts from "../hooks/useWalletAccounts";
import { env } from "../../../../../constants/env";
import { useFeatures } from "../../../../../contexts/ThemeContext";
import { useThemeColors } from "../../../../../hooks/useThemeColors";
import { useDomainContext } from "../../../../../contexts/DomainContext";

const companyId = getCookie("companyId");

const TH = ({ children, first, last }) => {
  const { lightMode } = usePageContext();
  const { PRIMARY_COLOR } = useThemeColors();
  return (
    <Box
      flex={1}
      height={"48px"}
      display={"flex"}
      alignItems={"center"}
      // border={"1px solid " + lightMode ? SECONDARY_COLOR : "#01293E"}
      // bg={lightMode ? SECONDARY_COLOR : "#01293E"} borderRadius={first ? "31px 0px 0px 0px" : last ? "0px 31px 0px 0px" : ""}
      fontSize={"14px"}
      fontWeight={"semibold"}
      color={lightMode ? PRIMARY_COLOR : "#fff"}
      // pr={last ? "48px" : ""}
      // pl={first ? "48px" : ""}
    >
      {children}
    </Box>
  );
};

const TBody = ({ children, whitelabel }) => {
  const { lightMode } = usePageContext();
  return (
    <Box
      ps={"40px"}
      pe={whitelabel ? "0px" : "40px"}
      borderRadius={"0 0 31px 31px"}
      borderLeft={`1px solid ${lightMode ? "#e1e1e1" : "#343C40"}`}
      borderRight={`1px solid ${lightMode ? "#e1e1e1" : "#343C40"}`}
      borderBottom={`1px solid ${lightMode ? "#e1e1e1" : "#343C40"}`}
    >
      {children}
    </Box>
  );
};

const TRow = ({ item, last, whitelabel }) => {
  // const { data: account } = useSingleAccount(item._id);
  // const { data: balance } = useBalance(account?.credentials?.apiId);
  // const { data: statsData } = useStats(account?.credentials?.accountNumber);
  const { lightMode } = usePageContext();
  const changeSection = useWalletStore((s) => s.changeSection);
  const inputSource = useWalletStore((s) => s.inputSource);

  const { companyTheme } = useDomainContext();

  return (
    <HStack
      borderBottom={
        last ? "" : `1px solid ${lightMode ? "#e1e1e1" : "#343C40"}`
      }
      px={"8px"}
      gap={0}
      width={"100%"}
      height={"54px"}
    >
      <HStack width={"100%"}>
        <Box flex={1}>
          <HStack alignItems={"center"} height={"54px"}>
            <Text
              color={lightMode ? "black" : "white"}
              mb={0}
              w={"100%"}
              fontWeight={"700"}
              fontSize={"16px"}
            >
              {item.accountName}
            </Text>
          </HStack>
        </Box>
        <Box flex={1}>
          <HStack alignItems={"center"} height={"54px"}>
            <Text
              color={lightMode ? "black" : "white"}
              mb={0}
              w={"100%"}
              fontSize={"16px"}
            >
              {item.accountNumber}
            </Text>
          </HStack>
        </Box>
        <Box flex={1}>
          <HStack alignItems={"center"} height={"54px"}>
            {item?.balance != undefined && (
              <Text
                color={lightMode ? "black" : "white"}
                mb={0}
                pl={"0px"}
                w={"100%"}
                fontSize={"16px"}
              >
                ${numberWithCommas(item.balance)}
              </Text>
            )}
          </HStack>
        </Box>
        {whitelabel && <Box flex={1}>
          <HStack alignItems={"center"} height={"54px"}>
            
                {item?.realizedProfit != undefined && (
              <Text color={lightMode ? "black" : "white"} mb={0}>
                ${numberWithCommas(parseFloat(item.realizedProfit).toFixed(2))}
              </Text>
            )}
          </HStack>
        </Box>}
        {whitelabel && <Box flex={1}>
          <HStack alignItems={"center"} height={"54px"}>
            {item?.percentageWithdrawn != undefined && (
              <Text
                color={lightMode ? "black" : "white"}
                mb={0}
                pl={"0px"}
                w={"100%"}
                fontSize={"16px"}
              >
                {item.percentageWithdrawn}%
              </Text>
            )}
          </HStack>
        </Box>}
        <Box flex={1} pr={"12px"}>
          <HStack
            height={"54px"}
            pl={"0px"}
            pr={"10px"}
            id={"profile-stats"}
            justifyContent={
              item?.realizedProfit != undefined ? "space-between" : "flex-end"
            }
            alignItems={"center"}
          >
            { whitelabel ? <Text color={lightMode ? "black" : "white"} mb={0}>
    ${numberWithCommas(parseFloat(item.remainingAmount).toFixed(2))}
  </Text> :

<>{item?.realizedProfit != undefined && (
  <Text color={lightMode ? "black" : "white"} mb={0}>
    ${numberWithCommas(parseFloat(item.realizedProfit).toFixed(2))}
  </Text>
)}</>

            }
            

            <HStack id="icons" alignItems={"center"} gap={"11px"}>
              <Center
                cursor={"pointer"}
                onClick={() => {
                  if(item?.status === "active"){
                    changeSection(SECTIONS[2]);
                    inputSource(item._id);
                  }
                }}
              >
                <ActionTip
                  type={"Send"}
                  icon={<Icon as={UploadCircleIcon} stroke={"#D0D0D0"} />}
                >
                  Send
                </ActionTip>
              </Center>
              {companyTheme === "default" && <Center
                cursor={"pointer"}
                onClick={() => {
                  changeSection(SECTIONS[3]);
                  inputSource(item._id);
                }}
              >
                <ActionTip
                  type={"Transfer"}
                  icon={<Icon as={TransferCircleIcon} stroke={"#D0D0D0"} />}
                >
                  Transfer
                </ActionTip>
              </Center>}
            </HStack>
          </HStack>
        </Box>
      </HStack>
    </HStack>
  );
};

const TradingAccounts = () => {
  const { currentTheme, lightMode } = usePageContext();
  const { data: walletActions } = useWalletAccounts();
   const { whitelabel} = useFeatures()
   const { SECONDARY_COLOR_DARK, SECONDARY_COLOR } = useThemeColors();

  console.log("LOOKING FOR WALLET", walletActions);


  return (
    <VStack w={"100%"} pb={"48px"} alignItems={"flex-start"} gap={"16px"}>
      <Heading
        fontSize={"24px"}
        color={currentTheme === "light" ? "black" : "white"}
        lineHeight={"normal"}
        fontWeight={"bold"}
      >
        Your trading accounts
      </Heading>
      <TableContainer w={"100%"}>
        <Box width={"100%"} variant="simple">
          <HStack h={"48px"} width={"100%"} py={"17px"}>
            <Box
              px={"48px"}
              border={"1px solid " + lightMode ? SECONDARY_COLOR : SECONDARY_COLOR_DARK}
              bg={lightMode ? SECONDARY_COLOR : SECONDARY_COLOR_DARK}
              borderRadius={"31px 31px 0px 0px"}
              display={"flex"}
              width={"100%"}
            >
              <TH first={true}>Account Name</TH>
              <TH>Account Number</TH>
              <TH>Account Balance</TH>
              {whitelabel && <TH>Realized Profit</TH>}
              {whitelabel && <TH>Withdrawn</TH>}
              <TH last={true}>
                <HStack height={"48px"} alignItems={"center"}>
                  <Text
                    display={"flex"}
                    alignItems={"center"}
                    gap={"4px"}
                    mb={0}
                  >
                    {whitelabel ? "Available" : <>Realized Profit  <HeadingTip /></> }
                  </Text>
                </HStack>
              </TH>
            </Box>
          </HStack>
          <TBody whitelabel={whitelabel}>
            {walletActions?.accounts?.map((x, i) => (
              <TRow
                item={x}
                last={i === walletActions?.accounts?.length - 1}
                key={i}
                whitelabel={whitelabel}
              />
            ))}
          </TBody>
        </Box>
      </TableContainer>
    </VStack>
  );
};

export default TradingAccounts;
