import React from "react";
import { usePageContext } from "../contexts/page.context";
import { useThemeColors } from "../../../hooks/useThemeColors";

const InfoIcon = ({ size }) => {
  const { currentTheme } = usePageContext();
  const { PRIMARY_COLOR } = useThemeColors();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || "17"}
      height={size || "17"}
      viewBox="0 0 17 17"
      fill="none"
    >
      <path
        d="M8.5 4.25C8.96944 4.25 9.35 4.63056 9.35 5.1V9.35C9.35 9.81944 8.96944 10.2 8.5 10.2C8.03056 10.2 7.65 9.81944 7.65 9.35V5.1C7.65 4.63056 8.03056 4.25 8.5 4.25Z"
        fill={currentTheme === "light" ? PRIMARY_COLOR : "white"}
      />
      <path
        d="M8.5 12.75C8.96944 12.75 9.35 12.3694 9.35 11.9C9.35 11.4306 8.96944 11.05 8.5 11.05C8.03056 11.05 7.65 11.4306 7.65 11.9C7.65 12.3694 8.03056 12.75 8.5 12.75Z"
        fill={currentTheme === "light" ? PRIMARY_COLOR : "white"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.5 0C3.80558 0 0 3.80558 0 8.5C0 13.1944 3.80558 17 8.5 17C13.1944 17 17 13.1944 17 8.5C17 3.80558 13.1944 0 8.5 0ZM1.7 8.5C1.7 4.74446 4.74446 1.7 8.5 1.7C12.2555 1.7 15.3 4.74446 15.3 8.5C15.3 12.2555 12.2555 15.3 8.5 15.3C4.74446 15.3 1.7 12.2555 1.7 8.5Z"
        fill={currentTheme === "light" ? PRIMARY_COLOR : "white"}
      />
    </svg>
  );
};

export default InfoIcon;
