import React, { createContext, useContext, useState, useEffect } from 'react';
import { env } from '../constants/env';

export const DomainContext = createContext();

export const DomainProvider = ({ children }) => {
  const [companyTheme, setCompanyTheme] = useState("default");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const primeBridgeUrl = env.primeBridgeUrl;

  useEffect(() => {
    if (companyTheme?.colors) {
      document.documentElement.style.setProperty(
        "--primary-color",
        companyTheme.colors.primary
      );

      if(companyTheme.colors.washedPrimary){
        document.documentElement.style.setProperty(
            "--washed-primary-color",
            companyTheme.colors.washedPrimary
          );
      }

      if(companyTheme.colors.secondary){
        document.documentElement.style.setProperty(
          "--secondary-color",
          companyTheme.colors.secondary
        )
        document.documentElement.style.setProperty(
          "--background-color",
          companyTheme.colors.secondary
        )

        document.documentElement.style.setProperty(
          "--secondary-color-dark",
          companyTheme.colors.secondary
        )
      }

      if (companyTheme.colors.accent) {
        document.documentElement.style.setProperty(
          "--accent-color",
          companyTheme.colors.accent
        );
      }else{
        document.documentElement.style.setProperty(
            "--accent-color",
            companyTheme.colors.primary
          );
      }

    }
  }, [companyTheme]);

  const fetchCompanyTheme = async (name) => {
    setLoading(true);
    setError(false); // Reset error before fetching

    const timeout = setTimeout(() => {
      setError(true);
      setLoading(false);
    }, 10000); // 10 seconds timeout

    try {
      const response = await fetch(`${primeBridgeUrl}/api/company/themes/${name}`);
      clearTimeout(timeout); // Clear timeout if request finishes in time

      if (response.ok) {
        const data = await response.json();
        console.log("COMPANY THEME DATA", data);
        setCompanyTheme(data || "default");
      } else {
        console.error("Failed to fetch company theme");
        // setError(true);
      }
    } catch (error) {
      console.error("Error fetching company theme:", error);
    //   setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (window.location.origin === "http://localhost:3000" || window.location.origin === "https://portal.team-pow.com") {
      fetchCompanyTheme("pow");
    }else{
        setLoading(false);
    }
  }, [primeBridgeUrl]);

  return (
    <DomainContext.Provider value={{ loading, error, companyTheme, fetchCompanyTheme }}>
      {children}
    </DomainContext.Provider>
  );
};

// Custom hook for using DomainContext
export const useDomainContext = () => useContext(DomainContext);