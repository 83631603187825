import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { env } from "../../constants/env";
import CopyIconBlue from "../../icons/CopyIconBlue";
import EyeIcon from "../../icons/EyeIcon";
import { getCookie } from "../../utilities/functions";
import HelpCenterBreadCrumbs from "../Help/components/HelpCenterBreadCrumb";
import classes from "./AddMember.module.css";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { useThemeColors } from "../../hooks/useThemeColors";
import { useDynamicTitle } from "../../hooks/useDynamicTitle";
import { FaPlus, FaMinus } from "react-icons/fa"; // Import icons
import { useDomainContext } from "../../contexts/DomainContext";

const accountTypeOptions = [
  { value: "Instant Funding (Standard)", label: "Instant Funding (Standard)" },
  { value: "Instant Funding (Monthly)", label: "Instant Funding (Monthly)" },
];

const accountSizeOptions = [
  { value: "$25,000", label: "$25,000" },
  { value: "$50,000", label: "$50,000" },
  { value: "$100,000", label: "$100,000" },
];

const Dropdown = ({
  selectedOption,
  setSelectedOption,
  options,
  placeholder,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={classes.dropdownWrapper} ref={dropdownRef}>
      <div className={classes.dropdownCard} onClick={() => setIsOpen(!isOpen)}>
        {selectedOption
          ? options.find((opt) => opt.value === selectedOption)?.label
          : placeholder || "Select an option"}
      </div>

      {isOpen && (
        <div className={classes.dropdownOptions}>
          {options.map((option) => (
            <div
              key={option.value}
              className={classes.dropdownOption}
              onClick={() => {
                setSelectedOption(option.value);
                setIsOpen(false);
              }}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const AccountSizeSelector = ({ label, value, selected, onChange }) => {
  return (
    <div className="d-flex justify-content-between align-items-center my-2 w-100">
      {/* Account Size */}
      <div className={`${classes.checkboxContainer} d-flex align-items-center`}>
        <input
          className={classes.checkInput}
          type="checkbox"
          checked={!!selected}
          onChange={() => {
            onChange((prev) => {
              const exists = prev.some((item) => item.accountSize === value);
              if (exists) {
                return prev.filter((item) => item.accountSize !== value);
              } else {
                return [...prev, { accountSize: value, number: 1 }];
              }
            });
          }}
        />
        <span className={classes.radioLabel}>{label}</span>
      </div>

      {/* Quantity */}
      <div className="d-flex align-items-center gap-2">
        <FaMinus
          className={`${classes.icon} ${!selected ? classes.disabledIcon : ""}`}
          onClick={() =>
            selected &&
            onChange((prev) =>
              prev.map((item) =>
                item.accountSize === value
                  ? { ...item, number: Math.max(1, item.number - 1) }
                  : item
              )
            )
          }
          size={20}
          role="button"
        />
        <input
          type="number"
          value={selected ? selected.number : ""}
          min={1}
          max={99}
          className={`${classes.numberInput} ${
            !selected ? classes.disabledInput : ""
          }`}
          onChange={(e) =>
            selected &&
            onChange((prev) =>
              prev.map((item) =>
                item.accountSize === value
                  ? { ...item, number: Number(e.target.value) }
                  : item
              )
            )
          }
          disabled={!selected}
        />
        <FaPlus
          className={`${classes.icon} ${!selected ? classes.disabledIcon : ""}`}
          onClick={() =>
            selected &&
            onChange((prev) =>
              prev.map((item) =>
                item.accountSize === value
                  ? { ...item, number: Math.min(99, item.number + 1) }
                  : item
              )
            )
          }
          size={20}
          role="button"
        />
      </div>
    </div>
  );
};

const AddMember = () => {
  useDynamicTitle("Add Member", "dashboard/team");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState("");
  const [overviewChecked, setOverviewChecked] = useState(false);
  const [assetChecked, setAssetChecked] = useState(false);
  const [transactionsChecked, setTransactionsChecked] = useState(false);
  const [teamChecked, setTeamChecked] = useState(false);
  const [weeklyReportsChecked, setWeeklyReportsChecked] = useState(false);
  const [weeklySnapshotChecked, setWeeklySnapshotChecked] = useState(false);
  const [helpChecked, setHelpChecked] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [showError, setShowError] = useState(false);
  const sendMemberRef = useRef(null);
  const [accessLevel, setAccessLevel] = useState([]);
  const [isSending, setIsSending] = useState();
  const [isLoading, setIsLaoding] = useState();
  const [overview, setOverview] = useState(false);

  const [paymentLink, setPaymentLink] = useState("");
  const [message, setMessage] = useState("");
  const [selectedAccountSize, setSelectedAccountSize] = useState(null);
  const [selectedAccountType, setSelectedAccountType] = useState(null);

  const companyId = getCookie("companyId");
  const companyName = useSelector((state) => state.company.companyName);

  const { companyTheme } = useDomainContext();

  const { PRIMARY_COLOR } = useThemeColors();

  useEffect(() => {
    fetchSingleCompany(companyId);
  }, [companyId]);

  useEffect(() => {
    if (companyTheme !== "default") {
      setAccessLevel((prev) => {
        const newAccess = new Set(prev);
        newAccess.add("asset-dashboard");
        newAccess.add("transactions");
        return Array.from(newAccess);
      });
      setAssetChecked(true);
      setTransactionsChecked(true);
    }
  }, [companyTheme]);

  const navigate = useNavigate();

  const addUserHandler = async () => {
    let dataContent = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      accessLevel: accessLevel,
      access: "user-portal",
      company: companyId,
      accountSize: selectedAccountSize, 
      accountType: selectedAccountType, 
      paymentLink, 
      companyName,
      userType: companyName !== "default" && "under-company"
    };
    const sendRequest = async () => {
      setIsSending(true);
      const response = await fetch(`${env.primeBridgeUrl}/api/auth`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContent),
      });

      if (!response.ok) {
        setIsSending(false);
        const result = await response.json();
        alert(result.error);
        console.log("ERROR RESPONSE", result.error);
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      setIsSending(false);
      // setFirstName("")
      // setLastName("")
      // setEmail("")
      // setPassword("")
      // setConfirmPassword("")
      // setSelectedAccountSize("")
      // setSelectedAccountType("")

      setButtonDisabled(true);
      sendMemberRef.current.scrollIntoView({ behavior: "smooth" });

      console.log("Reply from plays data", result);

      return result;
    };

    try {
      if (password === confirmPassword) {
        await sendRequest();
      } else {
        console.log("Passwords don't match");
      }
    } catch (error) {
      setIsSending(false);
      console.log(error);
    }
  };

  const fetchSingleCompany = async (companyId) => {
    try {
      const response = await fetch(
        `${env.primeBridgeUrl}/api/company/${companyId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("fetching company failed.");
      }

      const result = await response.json();

      console.log("SINGLE COMPANY", result);
      setOverview(result.overview);

      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const sendCredentials = async () => {
    let dataContent = {
      email: email,
      password: password,
      access: "user-portal",
      fullName: `${firstName} ${lastName}`,
      companyName: companyName,
    };
    const sendRequest = async () => {
      setIsLaoding(true);
      const response = await fetch(
        `${env.primeBridgeUrl}/api/company/team-member-credentials`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataContent),
        }
      );

      if (!response.ok) {
        setIsLaoding(false);
        const result = await response.json();
        alert(result.error);
        console.log("ERROR RESPONSE", result.error);
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      setIsLaoding(false);

      setButtonDisabled(false);

      console.log("Reply from plays data", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      setIsLaoding(false);
      console.log(error);
    }
  };

  return (
    <div className={`${classes.wrapper} container`}>
      {/* <Helmet>
          <meta charSet="utf-8" />
          <title>Add Member | Prime Bridge Portal</title>
          <link rel="canonical" href="https://portal.primebridge.io/dashboard/team" />
      </Helmet> */}
      {showError && (
        <div
          className={`${classes.errorContainer} d-flex justify-content-center align-items-center`}
        >
          First and last name, and email address are required to add a member.
          Complete fields to proceed.
        </div>
      )}
      <div
        className={`${classes.breadCrumbs} d-flex justify-content-between align-items-baseline`}
      >
        <div>
          <h6 onClick={() => navigate("/dashboard/team")} role="button">
            Team
          </h6>
          <h3>Add Member</h3>
        </div>
      </div>
      <div className={classes.descText}>
        Insert member's information and choose their access settings.
      </div>
      <h5>Login Information</h5>

      <div className={classes.formWrapper}>
        <div className={`row`}>
          <div className="col-6">
            <input
              value={firstName}
              placeholder="First name"
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="col-6">
            <input
              value={lastName}
              placeholder="Last name"
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
        </div>
        <input
          placeholder="Email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <div className={`row`}>
          <div className={`col-6 `}>
            <div
              className={`${classes.passwordWrapper} d-flex justify-content-center align-items-center`}
            >
              <input
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value?.trim())}
              />
              <EyeIcon onClick={() => setShowPassword(!showPassword)} />
            </div>
          </div>
          <div className="col-6">
            <div
              className={`${classes.passwordWrapper} d-flex justify-content-center align-items-center`}
            >
              <input
                placeholder="Confirm Password"
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value?.trim())}
              />
              <EyeIcon
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              />
            </div>
          </div>
        </div>
      </div>

      {companyTheme !== "default" && <>
      <h5>Request New Account</h5>

      <div className={`${classes.formWrapper}`}>
        <Dropdown
          selectedOption={selectedAccountSize}
          setSelectedOption={setSelectedAccountSize}
          options={accountSizeOptions}
          placeholder="Select Account Size"
        />
        <Dropdown
          selectedOption={selectedAccountType}
          setSelectedOption={setSelectedAccountType}
          options={accountTypeOptions}
          placeholder="Select Account Type"
        />
        <input
        placeholder="Payment Link"
        value={paymentLink}
        onChange={(e) => setPaymentLink(e.target.value)}
      />
      </div>
      </>}

      <h4>User Access</h4>
      {overview && (
        <div
          className={`${classes.checkboxContainer} d-flex align-items-center`}
        >
          <input
            className={classes.checkInput}
            type="checkbox"
            name="overview"
            value="overview"
            checked={overviewChecked}
            onChange={(e) => {
              setOverviewChecked(!overviewChecked);
              if (!accessLevel.includes("overview")) {
                setAccessLevel([...accessLevel, "overview"]);
              } else {
                setAccessLevel((current) =>
                  current.filter((access) => access !== "overview")
                );
              }
            }}
          />
          <div className={`${classes.radioLabel} ms-2`}>Overview section</div>
        </div>
      )}
      <div className={`${classes.checkboxContainer} d-flex align-items-center`}>
        <input
          className={classes.checkInput}
          type="checkbox"
          name="asset"
          value="asset"
          checked={assetChecked}
          onChange={(e) => {
            setAssetChecked(!assetChecked);
            if (!accessLevel.includes("asset-dashboard")) {
              setAccessLevel([...accessLevel, "asset-dashboard"]);
            } else {
              setAccessLevel((current) =>
                current.filter((access) => access !== "asset-dashboard")
              );
            }
          }}
        />
        <div className={`${classes.radioLabel} ms-2`}>Asset Dashboard</div>
      </div>
      <div className={`${classes.checkboxContainer} d-flex align-items-center`}>
        <input
          className={classes.radioInput}
          type="checkbox"
          name="transactions"
          value="transactions"
          checked={transactionsChecked}
          onChange={(e) => {
            setTransactionsChecked(!transactionsChecked);
            if (!accessLevel.includes("transactions")) {
              setAccessLevel([...accessLevel, "transactions"]);
            } else {
              setAccessLevel((current) =>
                current.filter((access) => access !== "transactions")
              );
            }
          }}
        />
        <div className={`${classes.radioLabel} ms-2`}>Transactions</div>
      </div>
      <div className={`${classes.checkboxContainer} d-flex align-items-center`}>
        <input
          className={classes.radioInput}
          type="checkbox"
          name="team"
          value="team"
          checked={teamChecked}
          onChange={(e) => {
            setTeamChecked(!teamChecked);
            if (!accessLevel.includes("team")) {
              setAccessLevel([...accessLevel, "team"]);
            } else {
              setAccessLevel((current) =>
                current.filter((access) => access !== "team")
              );
            }
          }}
        />
        <div className={`${classes.radioLabel} ms-2`}>Team</div>
      </div>
      <div className={`${classes.checkboxContainer} d-flex align-items-center`}>
        <input
          className={classes.radioInput}
          type="checkbox"
          name="weekly-reports"
          value="weekly-reports"
          checked={weeklyReportsChecked}
          onChange={(e) => {
            setWeeklyReportsChecked(!weeklyReportsChecked);
            if (!accessLevel.includes("weekly-reports")) {
              setAccessLevel([...accessLevel, "weekly-reports"]);
            } else {
              setAccessLevel((current) =>
                current.filter((access) => access !== "weekly-reports")
              );
            }
          }}
        />
        <div className={`${classes.radioLabel} ms-2`}>
          Weekly Reports (via email)
        </div>
      </div>
      <div className={`${classes.checkboxContainer} d-flex align-items-center`}>
        <input
          className={classes.radioInput}
          type="checkbox"
          name="weekly-snapshot"
          value="weekly-snapshot"
          checked={weeklySnapshotChecked}
          onChange={(e) => {
            setWeeklySnapshotChecked(!weeklySnapshotChecked);
            if (!accessLevel.includes("weekly-snapshot")) {
              setAccessLevel([...accessLevel, "weekly-snapshot"]);
            } else {
              setAccessLevel((current) =>
                current.filter((access) => access !== "weekly-snapshot")
              );
            }
          }}
        />
        <div className={`${classes.radioLabel} ms-2`}>
          Overview Weekly Snapshot (via email)
        </div>
      </div>
      <div className={`${classes.checkboxContainer} d-flex align-items-center`}>
        <input
          className={classes.radioInput}
          type="checkbox"
          name="help"
          value="help"
          checked={helpChecked}
          onChange={(e) => {
            setHelpChecked(!helpChecked);
            if (!accessLevel.includes("help-center")) {
              setAccessLevel([...accessLevel, "help-center"]);
            } else {
              setAccessLevel((current) =>
                current.filter((access) => access !== "help-center")
              );
            }
          }}
        />
        <div className={`${classes.radioLabel} ms-2`}>Help Center</div>
      </div>

      <div className="d-flex justify-content-center align-items-center">
        {!isSending && (
          <button
            className={`${
              buttonDisabled ? classes.disabledButton : undefined
            } addbutton`}
            onClick={() => {
              {
                if (!firstName || !lastName || !email || !password) {
                  setShowError(true);
                  setTimeout(() => {
                    setShowError(false);
                  }, 5000);
                }
                if (password !== confirmPassword) {
                  console.log("Passwords don't match");
                } else {
                  addUserHandler();
                }
              }
            }}
            style={{
              background:
                firstName &&
                lastName &&
                email &&
                password &&
                confirmPassword &&
                PRIMARY_COLOR,
            }}
          >
            Add
          </button>
        )}
        {isSending && <div className="spinner-border" role="status"></div>}
      </div>

      {buttonDisabled && (
        <div
          className={`d-flex justify-content-between align-items-center ${classes.sendMember}`}
        >
          <div>
            <h6>Employee Login</h6>
            <div>
              <span className={classes.subText}>{email}</span>
              <CopyIconBlue
                onClick={() => navigator.clipboard.writeText(email)}
              />
            </div>
          </div>
          <div>
            <h6>Password</h6>
            <div>
              <span className={classes.subText}>{password}</span>
              <CopyIconBlue
                onClick={() => navigator.clipboard.writeText(password)}
              />
            </div>
          </div>
          {!isLoading && (
            <button onClick={sendCredentials}>Send to Team Member</button>
          )}
          {isLoading && <div className="spinner-border" role="status"></div>}
        </div>
      )}
      <div ref={sendMemberRef} />
    </div>
  );
};

export default AddMember;
