import React from "react";
import { usePageContext } from "../contexts/page.context";
import { useThemeColors } from "../../../hooks/useThemeColors";

const TransferCircleIcon = () => {
  const { currentTheme } = usePageContext();
  const { PRIMARY_COLOR} = useThemeColors();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.3471 12C24.0112 12 23.6879 12.1292 23.4486 12.361C23.2091 12.593 23.0733 12.909 23.0733 13.2398C23.0733 13.5706 23.2094 13.8869 23.4489 14.1189L25.5833 16.1771H13.5759C13.2412 16.1771 12.919 16.3059 12.6805 16.5369C12.4418 16.7681 12.3065 17.083 12.3065 17.4127C12.3065 17.7424 12.4418 18.0573 12.6805 18.2885C12.919 18.5195 13.2412 18.6482 13.5759 18.6482H28.6556L28.6565 18.6482C28.9058 18.647 29.1496 18.5744 29.3573 18.4388C29.5648 18.3034 29.7273 18.1109 29.8233 17.8849C29.921 17.6593 29.9478 17.4105 29.8999 17.1698C29.852 16.929 29.7317 16.7081 29.5551 16.5347L25.2457 12.361C25.1271 12.2462 24.9867 12.1554 24.8326 12.0936C24.6785 12.0317 24.5136 12 24.3471 12ZM9.26747 20.35L9.26654 20.35C9.01727 20.3512 8.77345 20.4238 8.56579 20.5594C8.35829 20.6948 8.19583 20.8873 8.0998 21.1133C8.00205 21.3388 7.97528 21.5877 8.02315 21.8284C8.07106 22.0693 8.19141 22.2901 8.36801 22.4635L8.36893 22.4644L12.6772 26.6371C12.7954 26.7524 12.9357 26.8436 13.0898 26.9058C13.244 26.968 13.4092 27 13.5759 27C13.7427 27 13.9079 26.968 14.0621 26.9058C14.2161 26.8437 14.3563 26.7525 14.4745 26.6372C14.5935 26.5228 14.6883 26.3864 14.7531 26.2356C14.8181 26.0846 14.8516 25.9224 14.8516 25.7584C14.8516 25.5944 14.8181 25.4322 14.7531 25.2812C14.6882 25.1303 14.5931 24.9936 14.4739 24.8791L12.3398 22.8211H24.3471C24.6819 22.8211 25.0041 22.6924 25.2426 22.4614C25.4813 22.2302 25.6166 21.9152 25.6166 21.5855C25.6166 21.2558 25.4813 20.9409 25.2426 20.7097C25.0041 20.4787 24.6819 20.35 24.3471 20.35L9.26747 20.35ZM14.4745 26.6372L14.4751 26.6367L14.3407 26.4991L14.4739 26.6378L14.4745 26.6372Z"
        fill={currentTheme === "light" ? "#d0d0d0" : PRIMARY_COLOR}
      />
      <circle
        cx="18.5"
        cy="18.5"
        r="17.5"
        stroke={currentTheme === "light" ? "#d0d0d0" : PRIMARY_COLOR}
        stroke-width="2"
      />
    </svg>
  );
};

export default TransferCircleIcon;
