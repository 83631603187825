import { useEffect, useState } from "react";
import classes from "./Overview.module.css";
import LoadingSpinnerIcon from "../../icons/LoadingSpinnerIcon";
import PieChartComponent from "../Assets/pieChartComponent/PieChartComponent";
import InfoAltIcon from "../../icons/InfoAltIcon";
import DetailsModal from "./DetailsModal/DetailsModal";
import { env } from "../../constants/env";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllLPGroups, fetchAllManagementAccounts, fetchAllOverviewAccounts } from "../../Redux/accounts-actions";
import { getCookie, nFormatter } from "../../utilities/functions";
import GroupDetailsModal from "./GroupDetailsModal/GroupDetailsModal";
import Loader from "../Loader/Loader";
import Logo from "../Loader/Logo";
import { useThemeColors } from "../../hooks/useThemeColors";
import { useDynamicTitle } from "../../hooks/useDynamicTitle";

const token = "eyJhbGciOiJSUzUxMiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiIzNWM4YmJmZTE4YWFiNjRiZWNiZjFmNjJkNzcxNmUwMCIsInBlcm1pc3Npb25zIjpbXSwiYWNjZXNzUnVsZXMiOlt7ImlkIjoibWV0YWFwaS1yZXN0LWFwaSIsIm1ldGhvZHMiOlsibWV0YWFwaS1hcGk6cmVzdDpwdWJsaWM6KjoqIl0sInJvbGVzIjpbInJlYWRlciIsIndyaXRlciJdLCJyZXNvdXJjZXMiOlsiKjokVVNFUl9JRCQ6KiJdfSx7ImlkIjoibWV0YXN0YXRzLWFwaSIsIm1ldGhvZHMiOlsibWV0YXN0YXRzLWFwaTpyZXN0OnB1YmxpYzoqOioiXSwicm9sZXMiOlsicmVhZGVyIl0sInJlc291cmNlcyI6WyIqOiRVU0VSX0lEJDoqIl19LHsiaWQiOiJyaXNrLW1hbmFnZW1lbnQtYXBpIiwibWV0aG9kcyI6WyJyaXNrLW1hbmFnZW1lbnQtYXBpOnJlc3Q6cHVibGljOio6KiJdLCJyb2xlcyI6WyJyZWFkZXIiLCJ3cml0ZXIiXSwicmVzb3VyY2VzIjpbIio6JFVTRVJfSUQkOioiXX1dLCJ0b2tlbklkIjoiMjAyMTAyMTMiLCJpbXBlcnNvbmF0ZWQiOmZhbHNlLCJyZWFsVXNlcklkIjoiMzVjOGJiZmUxOGFhYjY0YmVjYmYxZjYyZDc3MTZlMDAiLCJpYXQiOjE2OTMyNzg1ODV9.Kp78fWt9e5kKDx2Vs4ETaua8zUAdcpqfHNI4vR2aVjNQKqA6XoF56c3H0dmnqy7zMO2RQ1ssjaQOFhT3n2APrBtw75swiTtV-7p4jX_qBWbhCy7LADZ9QoUngqYlOIZ4F2QdPysT2n1gcJYv6LE5w6b068NygKd2tMKRyprwXe6rzlZp8BzX7w8kGIsy_OSTehQipD-xRc9YdobZSJOv-lb1x-qT1M-L5WU-GoHWHaIrqVOGbpbSgGRmpzKDWfv98mQJ0wU_pU38ankwZnXZuYdSVl5Gi1KXHVed4aax82IyuozTm0LFPVAsviupm1ejmlJyVuZ77mtX9cRxiNgb1SQpVyeJtx5T4TzgU4PY_JLE62BTCDIDkmkVXtPDz6mAvBxgbzThYEUVq_q9f9bvo2gL4dhR-P1FW2ax3ijiEoTVuB79uutzH5RzzR8HR4BQ0SwAeHr8xNwKqorjim736NrvFTd1BssTh6Vc6ZYAYWRZkbb_1aYNorZ9Dkx9HmoXoTdWw4UHydIJ4NHJAjPOKNs8LDlPqnj6sLCQraimuuFe83ZpFFhzWHZ8y1imEOXsyuqKD7CS-ucK5aS8ipffZPnfksrEvT-c8oOM6xe3DI-R95NfXS0rSpaNlCtGv2ZWjZdpLZAcNyKmNNsVBCX9scsBX_d_1SJtDLtH4zXiIAM"


const StatsContainer = ({ title, amount, infoText }) => {
  const [showInfo, setShowInfo] = useState(false)


  return (
    <div className={`d-flex align-items-center gap-2 ${classes.statsWrapper}`}>{showInfo && <div className={classes.infoCard}>{infoText}</div>}{title} - <span>{amount}</span> <InfoAltIcon onMouseOver={() => setShowInfo(true)} onMouseOut={() => setShowInfo(false)} /></div>

  )
}

const Overview = ({ themeMode }) => {
  useDynamicTitle("Overview", "dashboard/overview");
  const dispatch = useDispatch()
  const companyId = getCookie("companyId");
  const [showModal, setShowModal] = useState(false)
  const [showGroupModal, setShowGroupModal] = useState(false)

  const [balanceSum, setBalanceSum] = useState(0);
  const [lotSum, setLotSum] = useState(0);
  const [gainSum, setGainSum] = useState(0);
  const [chartData, setChartData] = useState([]);
  const [updatedTime, setUpdatedTime] = useState();
  const [totalNOP, setTotalNOP] = useState()
  const [currentNopLimit, setCurrentNopLimit] = useState(0)
  const [totalLots, setTotalLots] = useState(0)
  const [nopCapacity, setNopCapacity] = useState(0)
  const [lotCapacity, setLotCapacity] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isTimedOut, setIsTimedOut] = useState(false)


  const { PRIMARY_COLOR } = useThemeColors();

  const overviewAccountsDetails = useSelector(state => state.accounts.allOverviewAccounts)

  const managementAccountsDetails = useSelector(state => state.accounts.allManagementAccounts)

  useEffect(() => {
    // fetchSingleCompany(companyId)
    fetchOverview(companyId)
    dispatch(fetchAllOverviewAccounts(companyId))
    dispatch(fetchAllManagementAccounts(companyId))
    dispatch(fetchAllLPGroups(companyId))
  }, [companyId])

  useEffect(() => {
    setTimeout(() => {
      setIsTimedOut(true)
    }, 120000);
  }, [])

  useEffect(() => {
    getManagement()
  }, [managementAccountsDetails])

  useEffect(() => {
    getSum(currentNopLimit, managementAccountsDetails)
  }, [currentNopLimit, managementAccountsDetails])

  useEffect(() => {
    getSum2(currentNopLimit, overviewAccountsDetails)
  }, [currentNopLimit, overviewAccountsDetails])


  const getDate = date => {
    const formatedDate = date.split('T')[0]
    // console.log("DATE", formatedDate)
    var newDate = new Date(date)
    var fullDate = new Date(date)
    var strArray = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]
    var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    var d = newDate.getDate()
    var dayName = days[newDate.getDay()]
    var m = strArray[newDate.getMonth()]
    var y = newDate.getFullYear()
    var hr = newDate.getHours()
    var min = newDate.getMinutes()

    if (min < 10) {
      min = "0" + min;
    }
    var ampm = "AM";
    if (hr > 12) {
      hr -= 12;
      ampm = "PM";
    }

    return {
      full: '' + m + ' ' + (d <= 9 ? '0' + d : d) + ', ' + y + ' ' + (hr <= 9 ? '0' + hr : hr) + ':' + min + ' ' + ampm,
      date: d,
      month: m
    }
  }

  const getManagement = async () => {
    let totalBalance = 0, gain = 0


    for (let i = 0; i < managementAccountsDetails?.length; i++) {
      const result = await getProfits(managementAccountsDetails[i]?.credentials?.apiId)
      totalBalance += result ? result?.balance : 0
      gain += result ? result?.gain : 0
      // console.log("REACHED SUMMMMMMM :)", result);
    }



    // var newSum = nFormatter(totalBalance)
    // setBalanceSum(newSum)


    var newGain = nFormatter(gain)
    // setGainSum(newGain)
  }

  const getSum = async (nopLimit, managementAccountsDetails) => {

    let sum = 0,
      lot = 0, gain = 0, arr1 = [], lastUpdated = ""

    // console.log("REACHED SUMMMMMMM :)", nopLimit);

    for (let i = 0; i < managementAccountsDetails.length; i++) {
      const result = await getProfits(managementAccountsDetails[i]?.credentials?.apiId)
      const openLots = await fetchOpenTrades(managementAccountsDetails[i]?.credentials?.apiId)
      sum += result ? result?.balance : 0
      lot += openLots ? openLots.volume : 0
      gain += result ? result?.gain : 0

      lastUpdated = openLots.allData[0]?.updateTime ? getDate(openLots.allData[0]?.updateTime) : ""



      // const newData = openLots.allData.map(obj => {
      //   const pair = Array.from(obj.symbol)  
      //   pair.splice(3, 0, '/')
      //   return {...obj, symbol: pair.join('') }
      //   } 
      // )

      arr1 = [...arr1, ...openLots.allData]
    }

    // var newSum = nFormatter(sum)
    // setBalanceSum(newSum)

    console.log("TOTAL HERE :)", lot)
    if (lot > 0) {
      setIsLoading(false)
    }

    var newLot = nFormatter(lot)
    // setLotSum(newLot)

    // var newGain = nFormatter(gain)
    // setGainSum(newGain)





    let ans = arr1.reduce((agg, curr) => {
      let found = agg.find((x) => x.name === curr.symbol.substring(0, 6));

      if (found) {
        found.value = (found.value || 0) + curr.volume
        found.sell = (found.sell || 0) + (curr.type === "POSITION_TYPE_SELL" ? curr.volume : 0)
        found.buy = (found.buy || 0) + (curr.type === "POSITION_TYPE_BUY" ? curr.volume : 0)
      }
      else {
        agg.push({
          name: curr.symbol.substring(0, 6),
          pair: curr.symbol,
          value: curr.volume,
          sell: curr.type === "POSITION_TYPE_SELL" ? curr.volume : 0,
          buy: curr.type === "POSITION_TYPE_BUY" ? curr.volume : 0
        });
      }
      return agg;
    }, []);

    // console.log("PIE ANSEWER" ,ans.length);

    // setChartData(ans)

    let approximateLots = 0

    const total = ans.reduce((n, { value }) => n + value, 0)

    var nopTotal = 0
    var lotsTotal = 0



    for (let i = 0; i < ans.length; i++) {

      const returnValue = await getNetOpenPosition(ans[i].pair, ans[i].value, nopLimit, ans[i].sell, ans[i].buy)
      nopTotal += returnValue.nop


      const distribution = ans[i].value / total

      const nop = distribution * nopLimit

      var str = ans[i].name.substring(0, 3);

      let lotsLimit

      if (str === "USD") {
        lotsLimit = nop / 100000
      } else if (ans[i].name === "XAUUSD") {
        lotsLimit = nop / (100 * returnValue.result.bid)
      } else {
        lotsLimit = nop / (100000 * returnValue.result.bid)
      }

      approximateLots += lotsLimit
      // console.log("NOP ", returnValue.result.bid);
    }



    // for (let i = 0; i < ans.length; i++) {
    //   const nop = await getNetOpenPosition(ans[i].name, ans[i].value, nopLimit)
    //    nopTotal += nop.nop
    //    lotsTotal += nop.lotsLimit
    // }

    // setTotalNOP(nFormatter(nopTotal))

    // console.log("NOP TOTAL => ", nopTotal);

    // setTotalLots(nFormatter(approximateLots))



    const capacityLot = +approximateLots - lot

    // console.log("Total Opened Lots", lot)

    // setLotCapacity(nFormatter(capacityLot))

    // setUpdatedTime(arr1[arr1.length - 1]?.updateTime ? getDate(arr1[arr1.length - 1].updateTime).full : "")
  }



  const getSum2 = async (nopLimit, overviewAccountsDetails) => {
    let sum = 0,
      lot = 0, arr1 = []

    // console.log("REACHED SUMMMMMMM :)", nopLimit);

    for (let i = 0; i < overviewAccountsDetails.length; i++) {
      const openLots = await fetchOpenTrades(overviewAccountsDetails[i]?.credentials?.apiId)

      arr1 = [...arr1, ...openLots.allData]
    }

    // var newSum = nFormatter(sum)
    // setBalanceSum(newSum)

    // console.log("TOTAL HERE :)", lot)






    let ans = arr1.reduce((agg, curr) => {
      let found = agg.find((x) => x.name === curr.symbol.substring(0, 6));

      if (found) {
        found.value = (found.value || 0) + curr.volume
        found.sell = (found.sell || 0) + (curr.type === "POSITION_TYPE_SELL" ? curr.volume : 0)
        found.buy = (found.buy || 0) + (curr.type === "POSITION_TYPE_BUY" ? curr.volume : 0)
      }
      else {
        agg.push({
          name: curr.symbol.substring(0, 6),
          pair: curr.symbol,
          value: curr.volume,
          sell: curr.type === "POSITION_TYPE_SELL" ? curr.volume : 0,
          buy: curr.type === "POSITION_TYPE_BUY" ? curr.volume : 0
        });
      }
      return agg;
    }, []);

    let approximateLots = 0

    const total = ans.reduce((n, { value }) => n + value, 0)

    var nopTotal = 0
    var lotsTotal = 0



    for (let i = 0; i < ans.length; i++) {

      const returnValue = await getNetOpenPosition2(ans[i].pair, ans[i].value, nopLimit, ans[i].sell, ans[i].buy)
      nopTotal += returnValue.nop


      const distribution = ans[i].value / total

      const nop = distribution * nopLimit

      var str = ans[i].name.substring(0, 3);

      let lotsLimit

      if (str === "USD") {
        lotsLimit = nop / 100000
      } else if (ans[i].name === "XAUUSD") {
        lotsLimit = nop / (100 * returnValue.result.bid)
      } else {
        lotsLimit = nop / (100000 * returnValue.result.bid)
      }

      approximateLots += lotsLimit
      // console.log("NOP ", returnValue.result.bid);
    }

    // setTotalNOP(nFormatter(nopTotal))

    const capacityNop = +nopLimit - +nopTotal

    // setNopCapacity(capacityNop)


  }


  const getNetOpenPosition = async (pr, lots, nopLimit, sell, buy) => {
    var pair = pr.substring(0, 6);
    try {
      const response = await fetch(`https://mt-client-api-v1.new-york.agiliumtrade.ai/users/current/accounts/80102068-bf41-4e79-88aa-eeb8fa042763/symbols/${(pair === "AUDJPY" || pair === "AUDNZD" || pair === "AUDCAD") ? "AUDUSD" : (pair === "CADCHF" || pair === "CADJPY") ? "USDCAD" : pair === "CHFJPY" ? "USDCHF" : (pair === "EURAUD" || pair === "EURCAD" || pair === "EURCHF" || pair === "EURGBP" || pair === "EURJPY" || pair === "EURNZD") ? "EURUSD" : (pair === "NZDJPY" || pair === "NZDCAD" || pair === "NZDCHF") ? "NZDUSD" : (pair === "GBPAUD" || pair === "GBPCAD" || pair === "GBPCHF" || pair === "GBPJPY" || pair === "GBPNZD") ? "GBPUSD" : pair}/current-price`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
      });

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      let nop, lotsLimit

      var str = pair.substring(0, 3);
      var str1 = pair.substring(0, 6);

      const volume = Math.abs(buy - sell)

      // console.log("BUY", buy)
      // console.log("=========================")
      // console.log("SELL", sell)
      // console.log("=========================")
      // console.log("VOLUME", volume)
      // console.log("=========================")
      // console.log("PAIRS", pair)

      if (str === "USD") {
        nop = volume
      } else if (str1 === "CADCHF" || str1 === "CADJPY" || str1 === "CHFJPY") {
        nop = volume * (1 / result.bid)
      } else {
        nop = volume * result.bid
      }

      if (str === "USD") {
        lotsLimit = nopLimit / 100000
      } else if (pair === "XAUUSD") {
        lotsLimit = nopLimit / (100 * result.bid)
      } else {
        lotsLimit = nopLimit / (100000 * result.bid)
      }

      //  console.log("FROM NOP", result)
      return { nop, lotsLimit, result };


    } catch (error) {
      console.log(error);
    }
  };



  const getNetOpenPosition2 = async (pair, lots, nopLimit, sell, buy) => {
    try {
      const response = await fetch(`https://mt-client-api-v1.new-york.agiliumtrade.ai/users/current/accounts/80102068-bf41-4e79-88aa-eeb8fa042763/symbols/${(pair === "AUDJPY.mic" || pair === "AUDNZD.mic" || pair === "AUDCAD.mic") ? "AUDUSD.mic" : (pair === "CADCHF.mic" || pair === "CADJPY.mic") ? "USDCAD.mic" : pair === "CHFJPY.mic" ? "USDCHF.mic" : (pair === "EURAUD.mic" || pair === "EURCAD.mic" || pair === "EURCHF.mic" || pair === "EURGBP.mic" || pair === "EURJPY.mic" || pair === "EURNZD.mic") ? "EURUSD.mic" : (pair === "NZDJPY.mic" || pair === "NZDCAD.mic" || pair === "NZDCHF.mic") ? "NZDUSD.mic" : (pair === "GBPAUD.mic" || pair === "GBPCAD.mic" || pair === "GBPCHF.mic" || pair === "GBPJPY.mic" || pair === "GBPNZD.mic") ? "GBPUSD.mic" : pair}/current-price`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
      });

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      let nop, lotsLimit

      var str = pair.substring(0, 3);
      var str1 = pair.substring(0, 6);

      const volume = Math.abs(buy - sell)

      // console.log("BUY", buy)
      // console.log("=========================")
      // console.log("SELL", sell)
      // console.log("=========================")
      // console.log("VOLUME", volume)
      // console.log("=========================")
      // console.log("PAIRS", pair)

      if (str === "USD") {
        nop = volume
      } else if (str1 === "CADCHF" || str1 === "CADJPY" || str1 === "CHFJPY") {
        nop = volume * (1 / result.bid)
      } else {
        nop = volume * result.bid
      }

      if (str === "USD") {
        lotsLimit = nopLimit / 100000
      } else if (pair === "XAUUSD") {
        lotsLimit = nopLimit / (100 * result.bid)
      } else {
        lotsLimit = nopLimit / (100000 * result.bid)
      }

      //  console.log("FROM NOP", result)

      return { nop, lotsLimit, result };


    } catch (error) {
      console.log(error);
    }
  };


  const getProfits = async (id) => {
    try {
      const response = await fetch(`${env.pbTrackingUrl}/api/stats/profits-api/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      // console.log("PROFIT APIIIIII", result);
      return result;


    } catch (error) {
      console.log(error);
    }
  };

  const fetchOpenTrades = async (id) => {
    try {
      const response = await fetch(
        `${env.pbTrackingUrl}/api/statistics/open-trades/${id}`,
        {
          method: "GET",
          headers: {
            "x-api-key": "hv3zmZLmRLM6ZPIQKPhRXWRNMf71MSZE",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Fetching Open Trades failed.");
      }

      const result = await response.json();
      const newVolume = result.reduce((n, { volume }) => n + volume, 0)
      // const newVolume = result.reduce((n, {volume}) => n + volume, 0)

      // console.log("OPEN TRADES FROM THE API OVERVIEW", result)

      // console.log("OPEN TRADES FROM THE API OVERVIEW VOLUME", newVolume)

      return { volume: newVolume, allData: result };

    } catch (error) {
      console.log(error);
    }
  };

  const fetchSingleCompany = async (companyId) => {
    try {
      const response = await fetch(
        `${env.primeBridgeUrl}/api/company/${companyId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("fetching company failed.");
      }

      const result = await response.json();

      // console.log("SINGLE COMPANY", result)
      // setCurrentNopLimit(result.nopLimit)
      fetchOverview(companyId)
      dispatch(fetchAllOverviewAccounts(companyId))
      dispatch(fetchAllManagementAccounts(companyId))
      dispatch(fetchAllLPGroups(companyId))
      return result;

    } catch (error) {
      console.log(error);
    }
  };


  const fetchOverview = async (companyId) => {
    setIsLoading(true)
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/account/overview/${companyId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Getting overview failed.");
      }

      const result = await response.json();

      console.log("OVERVIEW SAVED DETAILS HAHAHAHAAH", result)
      setChartData(result.pairs)
      setBalanceSum(nFormatter(result.totalBalance, 2))
      setTotalNOP(result.totalNetOpen)
      setLotSum(nFormatter(result.totalOpenLots))
      setGainSum(nFormatter(result.gain))
      setCurrentNopLimit(nFormatter(result.nopLimit))
      setNopCapacity(nFormatter(result.nopCapacity))
      setTotalLots(nFormatter(result.approximateLots))
      setLotCapacity(nFormatter(result.lotsCapacity))
      setUpdatedTime(result.lastUpdate)
      setIsLoading(false)
      return result;
    };

    try {
      const data = await sendRequest();
    } catch (error) {
      setIsLoading(false)
      console.log(error);
    }
  };

  const bottomRowData = [
    {
      title: lotSum,
      desc: "Total Opened Lots",
      detailsButton: false
    },
    {
      title: nFormatter(totalNOP, 2),
      desc: "Total Net Open Positions",
      detailsButton: true
    },
    {
      title: `${gainSum}%`,
      desc: "Portfolio Gain",
      detailsButton: false
    }
  ]

  return (
    <div className="main-divs">
      {(isLoading && !isTimedOut) && <div className={`${classes.loadingWrapper} d-flex flex-column justify-content-center align-items-center gap-4`}>
        <span className={classes.loader}></span>
        {/* <div className="position-relative" style={{height: "350px"}}><Loader /></div> */}
        <p>Optimizing assets, please standby</p>
      </div>}
      {/* {isTimedOut && <div>
     
    Time out, please try again 
    </div>} */}
      {(!isLoading || isTimedOut) && <div className={`${classes.wrapper}`}>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h3>Overview</h3>
            <p>Last updated: {updatedTime}</p>
          </div>
          <div className={`${classes.refreshButton} d-flex justify-content-between align-items-center gap-2`} role="button" onClick={() => { fetchOverview(companyId) }}><LoadingSpinnerIcon width="16" height="17" color={PRIMARY_COLOR} /> Refresh</div>
        </div>
        <div>
          <div className="row mt-5">
            <div className="col-8">
              <div className={classes.card}>
                <PieChartComponent data={chartData} />
              </div>
            </div>
            <div className="col-4">
              <div className={`d-flex flex-column align-items-center ${classes.card}`}>
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <h1>{balanceSum}</h1>
                  <p>Assets Under Management</p>
                </div>
                <button onClick={() => setShowModal(true)}>Details</button>
              </div>
            </div>
          </div>
          <div className={`${classes.bottomRow} row mt-5`}>
            {bottomRowData.map((item, i) => (<div className="col-4" key={i}>
              <div className={`d-flex flex-column justify-content-center align-items-center ${classes.card}`}>
                <h2>{item.title}</h2>
                <p>{item.desc}</p>
                {item.detailsButton && <button onClick={() => setShowGroupModal(true)} className="mt-3">Details</button>}
              </div>
            </div>))}
          </div>
          <div className="row mt-5">
            <div className="col-4">
              <StatsContainer title="Current NOP Limit" amount={currentNopLimit} infoText="The maximum allowable value of your Net Open Positions." />
            </div>
            <div className="col-4">

            </div>
            <div className="col-4">
              <StatsContainer title="Remaining NOP Capacity" amount={nopCapacity} infoText="The unused portion of your NOP limit." />
            </div>
            <div className="col-4">
              <StatsContainer title="Approximate Lots Limit" amount={totalLots} infoText="The estimated maximum number of tradable lots." />
            </div>
            <div className="col-4">

            </div>
            <div className="col-4">
              <StatsContainer title="Remaining Lots Capacity " amount={lotCapacity} infoText="The number of lots left allowed for trading." />
            </div>
          </div>
        </div>
        {showModal && <DetailsModal themeMode={themeMode} closeModal={() => setShowModal(false)} />}
        {showGroupModal && <GroupDetailsModal themeMode={themeMode} closeModal={() => setShowGroupModal(false)} />}
      </div>}
    </div>
  );
};

export default Overview;
