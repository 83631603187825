import React from "react";
import { usePageContext } from "../contexts/page.context";
import { useThemeColors } from "../../../hooks/useThemeColors";

const UploadCircleIcon = () => {
  const { currentTheme } = usePageContext();
  const { PRIMARY_COLOR} = useThemeColors();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
    >
      <path
        d="M19.4827 20.5565C19.4843 21.0708 19.0603 21.489 18.5357 21.4906C18.011 21.4922 17.5843 21.0766 17.5827 20.5624L19.4827 20.5565Z"
        fill={currentTheme === "light" ? "#d0d0d0" : PRIMARY_COLOR}
      />
      <path
        d="M16.3327 13.7754L17.5571 12.5678L17.5827 20.5624L19.4827 20.5565L19.4571 12.5619L20.6892 13.762C21.0613 14.1245 21.6629 14.1227 22.0327 13.7579C22.4025 13.3931 22.4006 12.8035 22.0285 12.441L18.4957 9L14.985 12.4627L16.3327 13.7754Z"
        fill={currentTheme === "light" ? "#d0d0d0" : PRIMARY_COLOR}
      />
      <path
        d="M14.985 12.4627C14.6152 12.8274 14.6171 13.417 14.9892 13.7795C15.3614 14.142 15.9629 14.1402 16.3327 13.7754L14.985 12.4627Z"
        fill={currentTheme === "light" ? "#d0d0d0" : PRIMARY_COLOR}
      />
      <path
        d="M10.9 18.6885C10.9 17.66 11.7507 16.8262 12.8 16.8262C13.3247 16.8262 13.75 16.4093 13.75 15.895C13.75 15.3808 13.3247 14.9639 12.8 14.9639C10.7013 14.9639 9 16.6314 9 18.6885V24.2754C9 26.3324 10.7013 28 12.8 28H23.25C25.8734 28 28 25.9155 28 23.3442V18.6885C28 16.6314 26.2987 14.9639 24.2 14.9639C23.6753 14.9639 23.25 15.3808 23.25 15.895C23.25 16.4093 23.6753 16.8262 24.2 16.8262C25.2493 16.8262 26.1 17.66 26.1 18.6885V23.3442C26.1 24.887 24.824 26.1377 23.25 26.1377H12.8C11.7507 26.1377 10.9 25.3039 10.9 24.2754V18.6885Z"
        fill={currentTheme === "light" ? "#d0d0d0" : PRIMARY_COLOR}
      />
      <path
        d="M19.4827 20.5565C19.4843 21.0708 19.0603 21.489 18.5357 21.4906C18.011 21.4922 17.5843 21.0766 17.5827 20.5624M19.4827 20.5565L17.5827 20.5624M19.4827 20.5565L19.4571 12.5619L20.6892 13.762M17.5827 20.5624L17.5571 12.5678L16.3327 13.7754M16.3327 13.7754C15.9629 14.1402 15.3614 14.142 14.9892 13.7795C14.6171 13.417 14.6152 12.8274 14.985 12.4627M16.3327 13.7754L14.985 12.4627M20.6892 13.762C21.0613 14.1245 21.6629 14.1227 22.0327 13.7579C22.4025 13.3931 22.4006 12.8035 22.0285 12.441M20.6892 13.762L22.0285 12.441M22.0285 12.441L18.4957 9L14.985 12.4627M12.8 16.8262C11.7507 16.8262 10.9 17.66 10.9 18.6885V24.2754C10.9 25.3039 11.7507 26.1377 12.8 26.1377H23.25C24.824 26.1377 26.1 24.887 26.1 23.3442V18.6885C26.1 17.66 25.2493 16.8262 24.2 16.8262C23.6753 16.8262 23.25 16.4093 23.25 15.895C23.25 15.3808 23.6753 14.9639 24.2 14.9639C26.2987 14.9639 28 16.6314 28 18.6885V23.3442C28 25.9155 25.8734 28 23.25 28H12.8C10.7013 28 9 26.3324 9 24.2754V18.6885C9 16.6314 10.7013 14.9639 12.8 14.9639C13.3247 14.9639 13.75 15.3808 13.75 15.895C13.75 16.4093 13.3247 16.8262 12.8 16.8262Z"
        stroke={currentTheme === "light" ? "#d0d0d0" : PRIMARY_COLOR}
        stroke-width="0.3"
        stroke-linecap="round"
      />
      <circle
        cx="18.5"
        cy="18.5"
        r="17.5"
        stroke={currentTheme === "light" ? "#d0d0d0" : PRIMARY_COLOR}
        stroke-width="2"
      />
    </svg>
  );
};

export default UploadCircleIcon;
