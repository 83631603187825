import { useThemeColors } from "../hooks/useThemeColors";

const PencilIcon = () => {
  const { PRIMARY_COLOR } = useThemeColors();
  return (
    <svg
      width="18"
      height="15"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.673 2.6554L15.3449 0.327362C15.1785 0.161054 14.9639 0.0514524 14.7316 0.0141497C14.4994 -0.023153 14.2612 0.0137431 14.0511 0.119589C14.033 0.113453 14.0135 0.112505 13.9949 0.116854C13.9762 0.121202 13.9592 0.130674 13.9457 0.144198L13.7628 0.32701L2.7236 11.366L1.47307 12.6176C1.45796 12.6328 1.448 12.6523 1.44459 12.6735L1.43088 12.6598L0 18L5.34032 16.5691L5.32661 16.5554C5.34777 16.552 5.36732 16.5421 5.38251 16.527L17.8561 4.05356C17.8697 4.04002 17.8791 4.02297 17.8835 4.00434C17.8878 3.9857 17.8869 3.96622 17.8808 3.94809C17.9864 3.73812 18.0231 3.50022 17.9858 3.26816C17.9485 3.03611 17.8391 2.82171 17.673 2.6554Z"
        fill={PRIMARY_COLOR}
      />
    </svg>
  );
};

export default PencilIcon;
