import { useEffect, useState } from "react";
import { env } from "../../../constants/env";
import classes from "./InquiryCard.module.css";
import { getCookie } from "../../../utilities/functions";
import OutlinedCircle from "../../../icons/OutlinedCircle";
import CheckedCircle from "../../../icons/CheckedCircle";
import { useThemeColors } from "../../../hooks/useThemeColors";

const arr = [];

const InquiryCard = ({ item, solvedIssue }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [isSending, setIsSending] = useState();
  const [isSolvedSending, setSolvedIsSending] = useState(false);
  const [message, setMessage] = useState();
  const [linkHeight, setLinkHeight] = useState([]);
  const [showConversation, setShowConversation] = useState(false);
  const [unChecked, setUnChecked] = useState(true);
  const [checked, setChecked] = useState(false);
  const [messages, setMessages] = useState([]);

  const companyId = getCookie("companyId");
  const userId = getCookie("userId");

  const { PRIMARY_COLOR} = useThemeColors();

  

  useEffect(() => {
    for (let i = 0; i < messages?.length; i++) {
      var clientHeight = document.getElementById(`stepDiv-${i}`)?.clientHeight;
      setLinkHeight([...linkHeight, clientHeight]);
    }
  }, [showConversation, messages]);

  const dateToYMD = (date) => {
    const formatedDate = date.split("T")[0];
    console.log("DATE", formatedDate);
    var newDate = new Date(date);
    var fullDate = new Date(date);
    var strArray = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    var d = newDate.getDate();
    var dayName = days[newDate.getDay()];
    var m = strArray[newDate.getMonth()];
    var y = newDate.getFullYear();
    var hr = newDate.getHours();
    var min = newDate.getMinutes();

    if (min < 10) {
      min = "0" + min;
    }
    var ampm = "am";
    if (hr > 12) {
      hr -= 12;
      ampm = "pm";
    }

    return {
      full:
        "" +
        m +
        " " +
        (d <= 9 ? "0" + d : d) +
        ", " +
        (hr <= 9 ? "0" + hr : hr) +
        ":" +
        min +
        " " +
        ampm,
      date: d,
      month: m,
    };
  };

  useEffect(() => {
    getMessages();
  }, []);

  const getMessages = async () => {
    const sendRequest = async () => {
      const response = await fetch(
        `${env.primeBridgeUrl}/api/message/conversations/${item._id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      setMessages(result);

      console.log(`Message data`, result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  const sendMessage = async () => {
    let dataContent = {
      user: userId,
      conversation: item._id,
      message: message,
    };
    const sendRequest = async () => {
      setIsSending(true);
      const response = await fetch(`${env.primeBridgeUrl}/api/message`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContent),
      });

      if (!response.ok) {
        const error = await response.json();
        console.log("ERROR RESPONSE", error?.error);
        setIsSending(false);
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      getMessages();
      setMessage("");
      setIsSending(false);

      console.log("Message saved", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      setIsSending(false);
      console.log(error);
    }
  };

  const solvedHandler = async () => {
    let dataContent = {
      status: "solved",
      solvedBy: "user"
    };
    const sendRequest = async () => {
      setSolvedIsSending(true);
      const response = await fetch(
        `${env.primeBridgeUrl}/api/conversation/update/${item._id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataContent),
        }
      );

      if (!response.ok) {
        setSolvedIsSending(false);
        const error = await response.json();
        console.log("ERROR RESPONSE", error?.error);
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      setSolvedIsSending(false);
      solvedIssue(true);

      console.log("Reply from plays data", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      setSolvedIsSending(false);
      console.log(error);
    }
  };
  return (
    <div className={classes.card}>
      <div className={`${classes.topCardWrapper} d-flex  align-items-center`}>
        <div className="d-flex justify-content-between align-items-start w-100">
          <div
            className="d-flex align-items-center"
            onClick={() => setShowConversation(!showConversation)}
            role="button"
          >
            <div className="me-3">
              <h6>{dateToYMD(item.createdAt).month}</h6>
              <h3>{dateToYMD(item.createdAt).date}</h3>
            </div>
            <div>
              <h4>{item?.subject}</h4>
              <p
                style={{
                  color: item?.lastMessage?.user === userId && "#D0D0D0",
                }}
              >
                {`${
                  item?.lastMessage?.user === userId ? "You" : "Prime Bridge"
                }: ${item?.lastMessage?.content}`}
              </p>
            </div>
          </div>
          <div>
            <div className="d-flex justify-content-center align-items-center mb-2">
              <div style={{ color: "#01486D", marginRight: "5px" }}>
                Mark as Solved
              </div>
              {unChecked && (
                <OutlinedCircle
                  onClick={() => {
                    setUnChecked(false);
                    setChecked(true);
                  }}
                />
              )}
              {checked && (
                <CheckedCircle
                  onClick={() => {
                    setUnChecked(true);
                    setChecked(false);
                  }}
                />
              )}
            </div>
            {checked && (
              <>
                {!isSolvedSending && (
                  <button onClick={solvedHandler}>Confirm</button>
                )}
                {isSolvedSending && (
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="spinner-border" role="status"></div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {showConversation && (
        <>
          <div className={classes.horizontalDivider}></div>
          <div className="mt-4">
            <h6 className="mb-3">History</h6>
            {messages.map((message, i) => (
              <div
                className={`d-flex justify-content-center align-items-start ${classes.stepWrapper}`}
                key={i}
                id={`stepDiv-${i}`}
              >
                <div
                  className={`d-flex flex-column align-items-center ${classes.stepNav}`}
                >
                  <div
                    className={classes.stepDot}
                    style={{
                      background:
                        message.user === userId &&
                        `linear-gradient(0deg,${PRIMARY_COLOR} , ${PRIMARY_COLOR}), linear-gradient(0deg, ${PRIMARY_COLOR}, ${PRIMARY_COLOR}), ${PRIMARY_COLOR}`,
                    }}
                  ></div>
                  {i !== messages.length - 1 && (
                    <div className={classes.verticleDivider}></div>
                  )}
                </div>

                <div className="col">
                  <h6>
                    <span
                      className="me-1"
                      style={{ color: message.user === userId && PRIMARY_COLOR }}
                    >
                      {message.user === userId ? "You" : "Prime Bridge"}
                    </span>{" "}
                    | {dateToYMD(message.createdAt).full}
                  </h6>
                  <p>{message.message}</p>
                </div>
              </div>
            ))}
            {item?.solvedBy !== "support" && <>
              <h6 className="mb-3">Reply</h6>

              <textarea
                placeholder="How can we help you?"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <div className="d-flex justify-content-center align-items-center">
                {!isSending && <button onClick={sendMessage}>Send</button>}
                {isSending && (
                  <div className="spinner-border" role="status"></div>
                )}
              </div>
            </>}
          </div>
        </>
      )}
    </div>
  );
};

export default InquiryCard;
