import { Box, HStack, VStack } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import { Text } from "../../../../components/text";
import CaretUpIcon from "../../../../icons/CaretUp.icon";
import { Coins, useSendfundsContext } from "../../sendfunds.context";
import { usePageContext } from "../../../../contexts/page.context";
import MenuTip from "../tip/MenuTip";
import DotMenuIcon from "../../../../icons/DotMenu.icon";
import PencilIcon from "../../../../icons/Pencil.icon";
import TrashIcon from "../../../../icons/Trash.icon";
import { useEffect, useState } from "react";
import { Input } from "@chakra-ui/input";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "../../../../components/menu";
import useEditAddress from "../../hooks/useEditAddress";
import useDeleteAddress from "../../hooks/useDeleteAddress";
import { useQueryClient } from "@tanstack/react-query";
import useSendFundsStore from "../../store";
import useSavedAddresses from "../../hooks/useSavedAddresses";
import { useThemeColors } from "../../../../../../hooks/useThemeColors";

const SavedCrypto = () => {
  const { lightMode } = usePageContext();
  const [isEditing, setIsEditing] = useState();
  const [selected, setSelected] = useState();
  const [isDeleting, setIsDeleting] = useState();
  const [coin, setCoin] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [name, setName] = useState("");
  const queryClient = useQueryClient();
  const { mutateAsync: editAddress } = useEditAddress();
  const { mutateAsync: deleteAddress } = useDeleteAddress();
  const { data: savedAddresses } = useSavedAddresses("crypto");
  const toggleCryptoAddresses = useSendFundsStore(
    (s) => s.toggleCryptoAddresses
  );
  const saveCryptoDetails = useSendFundsStore((s) => s.saveCryptoDetails);

  const { PRIMARY_COLOR, SECONDARY_COLOR_DARK } = useThemeColors();

  // const update = (id) => {
  //     const tmp = savedCrypto;
  //     tmp[id] = {
  //         coin,
  //         cryptoAddress: walletAddress,
  //         name,
  //     }
  //     setSavedCrypto(tmp);
  // }

  const update = () => {
    editAddress({
      name: name,
      wireBankName: coin,
      wireAccountName: "",
      wireAccountNumber: "",
      wireAddress: walletAddress,
      wireBranch: "",
      wireSwift: "",
      id: savedAddresses[isEditing]._id,
    }).then(() => {
      queryClient.refetchQueries(["saved addresses"]);
      setIsEditing(undefined);
    });
  };

  const remove = () => {
    deleteAddress(savedAddresses[isDeleting]?._id).then(() => {
      queryClient.refetchQueries("saved addresses");
      setIsDeleting(undefined);
    });
  };

  const confirmSavedCrypto = () => {
    saveCryptoDetails({
      coin: savedAddresses[selected]?.wireBankName,
      walletAddress: savedAddresses[selected]?.wireAddress,
    });
  };

  useEffect(() => {
    if (isEditing !== undefined) {
      setCoin(savedAddresses[isEditing]?.wireBankName || "");
      setWalletAddress(savedAddresses[isEditing].wireAddress);
      setName(savedAddresses[isEditing].name);
    }

    return () => {};
  }, [isEditing]);
  console.log(savedAddresses);
  return (
    <VStack w={"100%"}>
      <VStack pl={"64px"} mb={"20px"} pr={"56px"} w={"100%"}>
        <HStack
          w={"100%"}
          pl={"20px"}
          mb={"20px"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Text>Saved Addresses</Text>

          <HStack
            onClick={() => toggleCryptoAddresses()}
            cursor={"pointer"}
            alignItems={"center"}
            gap={"6px"}
          >
            <Text
              textDecoration={lightMode ? "" : "underline"}
              color={lightMode ? PRIMARY_COLOR : "white"}
            >
              Hide Saved
            </Text>
            <CaretUpIcon />
          </HStack>
        </HStack>

        <HStack w={"100%"} gap={"61px"} alignItems={"flex-start"}>
          <VStack alignItems={"flex-start"} w={"266px"} maxW={"266px"}>
            {savedAddresses?.map((x, i) => (
              <HStack key={i} w={"100%"} position={"relative"}>
                <HStack
                  width={"100%"}
                  cursor={"pointer"}
                  onClick={() => {
                    setIsDeleting(undefined);
                    setIsEditing(undefined);
                    setSelected(i);
                  }}
                  borderWidth={"1px"}
                  borderRadius={"50px"}
                  borderColor={
                    isDeleting === i
                      ? "#BD3437"
                      : selected === i
                      ? PRIMARY_COLOR
                      : lightMode
                      ? "black"
                      : "white"
                  }
                  bg={
                    isDeleting === i
                      ? "transparent"
                      : selected === i
                      ? lightMode
                        ? "#E5F1F7"
                        : SECONDARY_COLOR_DARK
                      : "transparent"
                  }
                  height={"48px"}
                  justifyContent={"space-between"}
                  py={"12px"}
                  pl={"20px"}
                  key={i}
                >
                  <Text
                    fontSize={"14px"}
                    color={
                      isDeleting === i
                        ? "#BD3437"
                        : selected === i
                        ? PRIMARY_COLOR
                        : lightMode
                        ? "black"
                        : "white"
                    }
                  >
                    {x.name || x.sendType}
                  </Text>
                </HStack>
                <Box>
                  {isDeleting !== undefined && isDeleting === i ? (
                    <Button
                      position={"absolute"}
                      right={"16px"}
                      top={"50%"}
                      transform={"translate(0%, -50%)"}
                      zIndex={"100"}
                      bg={"#BD3437"}
                      w={"90px"}
                      display={"flex"}
                      fontSize={"13px"}
                      justifyContent={"center"}
                      color={"#fff"}
                      fontWeight={"700"}
                      _hover={{
                        color: "#ffffff",
                      }}
                      onClick={() => {
                        remove();
                      }}
                      h={"30px"}
                      borderRadius={"31px"}
                      alignItems={"center"}
                    >
                      Remove
                    </Button>
                  ) : (
                    <MenuTip
                      value={selected}
                      isEditing={isEditing}
                      isDeleting={isDeleting}
                      icon={
                        <DotMenuIcon
                          color={
                            isDeleting === i
                              ? "#BD3437"
                              : selected === i
                              ? PRIMARY_COLOR
                              : lightMode
                              ? "black"
                              : "white"
                          }
                        />
                      }
                    >
                      <VStack
                        gap={"10px"}
                        alignItems={"flex-start"}
                        px={"11px"}
                      >
                        <HStack
                          onClick={() => {
                            setIsEditing(i);
                            setSelected(i);
                          }}
                          cursor={"pointer"}
                          gap={"10px"}
                        >
                          <PencilIcon />
                          <Text fontSize={"12px"} letterSpacing={"1.2px"}>
                            Edit
                          </Text>
                        </HStack>
                        <HStack
                          onClick={() => {
                            setIsDeleting(i);
                            setSelected(i);
                          }}
                          cursor={"pointer"}
                          gap={"10px"}
                        >
                          <TrashIcon />
                          <Text
                            color={"#BD3437"}
                            fontSize={"12px"}
                            letterSpacing={"1.2px"}
                          >
                            Remove
                          </Text>
                        </HStack>
                      </VStack>
                    </MenuTip>
                  )}
                </Box>
              </HStack>
            ))}
          </VStack>

          {selected !== undefined && !isEditing && (
            <HStack gap={0} flexGrow={1} alignItems={"flex-end"}>
              <VStack gap={"5px"} flexGrow={1} alignItems={"flex-start"}>
                <Text fontSize={"13px"} mb={"5px"}>
                  Crypto Details
                </Text>
                <Text fontSize={"12px"}>
                  Name:{" "}
                  <Text color={"7f7f7f"} display={"inline-block"}>
                    {savedAddresses[selected]?.name}
                  </Text>
                </Text>
                <Text fontSize={"12px"}>
                  Coin:{" "}
                  <Text color={"7f7f7f"} display={"inline-block"}>
                    {savedAddresses[selected]?.wireBankName}
                  </Text>
                </Text>
                <Text fontSize={"12px"}>
                  Wallet Address: <br />
                  <Text color={"7f7f7f"} display={"inline-block"}>
                    {savedAddresses[selected]?.wireAddress}
                  </Text>
                </Text>
              </VStack>
              <Button
                w={"150px"}
                h={"48px"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                color={"white"}
                bg={PRIMARY_COLOR}
                borderRadius={"50px"}
                onClick={() => {
                  confirmSavedCrypto();
                  toggleCryptoAddresses();
                }}
              >
                Use this
              </Button>
            </HStack>
          )}
        </HStack>
      </VStack>
      {isEditing !== undefined && (
        <VStack
          w={"100%"}
          pl={"74px"}
          borderTopWidth={"1px"}
          pt={"25px"}
          borderTopColor={lightMode ? "#e1e1e1" : "#343c40"}
          gap={"20px"}
        >
          <HStack
            w={"100%"}
            gap={"35px"}
            justifyContent={"space-between"}
            alignItems={"flex-end"}
          >
            <VStack flexGrow={0.5} alignItems={"19px"}>
              <Text fontSize={"14px"} fontWeight={"500"}>
                Coin
              </Text>
              <Menu
                width={"100%"}
                px={"20px"}
                bg={lightMode ? "#f5f5f5" : SECONDARY_COLOR_DARK}
                py={"12px"}
                onChange={(str) => setCoin(str)}
                value={coin}
              >
                <MenuButton placeholder={"Select coin"} />
                <MenuList>
                  {Coins.map((x, i) => (
                    <MenuItem disabled={x !== "USDT"} key={i}>
                      {x}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </VStack>
            <VStack flexGrow={1} alignItems={"19px"}>
              <Text fontSize={"14px"} fontWeight={"500"}>
                Wallet Address
              </Text>
              <HStack
                flexGrow={1}
                borderRadius={"50px"}
                bg={lightMode ? "#f5f5f5" : SECONDARY_COLOR_DARK}
                px={"18px"}
                py={"12px"}
              >
                <Input
                  px={0}
                  value={walletAddress}
                  height={"100%"}
                  flexGrow={1}
                  color={lightMode ? "black" : "white"}
                  onChange={(e) => {
                    setWalletAddress(e.target.value);
                  }}
                  w={"100%"}
                  border={"none"}
                  outlineColor={"transparent"}
                  focusBorderColor={"transparent"}
                  _placeholder={{
                    color: lightMode ? "#7f7f7f" : "#d0d0d0",
                  }}
                  fontSize={"14px"}
                  bg={"transparent"}
                  placeholder="Insert address"
                />
              </HStack>
            </VStack>
          </HStack>
          <HStack
            w={"100%"}
            gap={"35px"}
            justifyContent={"space-between"}
            alignItems={"flex-end"}
          >
            <VStack flexGrow={1} alignItems={"19px"}>
              <Text fontSize={"14px"} fontWeight={"500"}>
                Name
              </Text>
              <HStack
                flexGrow={1}
                borderRadius={"50px"}
                bg={lightMode ? "#f5f5f5" : SECONDARY_COLOR_DARK}
                px={"18px"}
                py={"12px"}
              >
                <Input
                  px={0}
                  value={name}
                  height={"100%"}
                  flexGrow={1}
                  color={lightMode ? "black" : "white"}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  w={"100%"}
                  border={"none"}
                  outlineColor={"transparent"}
                  focusBorderColor={"transparent"}
                  _placeholder={{
                    color: lightMode ? "#7f7f7f" : "#d0d0d0",
                  }}
                  fontSize={"14px"}
                  bg={"transparent"}
                  placeholder="Insert name"
                />
              </HStack>
            </VStack>
            <Button
              w={"150px"}
              h={"48px"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              color={"white"}
              bg={PRIMARY_COLOR}
              borderRadius={"50px"}
              onClick={() => {
                update(isEditing);
                setIsEditing(undefined);
              }}
            >
              Update
            </Button>
          </HStack>
        </VStack>
      )}
    </VStack>
  );
};

export default SavedCrypto;
