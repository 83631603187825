import { HStack, VStack } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import React, { useEffect } from "react";
import { numberWithCommas } from "../../../../../../utilities/functions";
import { usePageContext } from "../../../../contexts/page.context";
import { Text } from "../../../../components/text";
import { useAddFundsContext } from "../../addfunds.context";
import { toast } from "react-hot-toast";
import SuccessIcon from "../../../../icons/Success.icon";
import useWalletStore from "./../../../../store";
import useAddFundsStore from "../../store";
import { useSelector } from "react-redux";
import useAddFunds from "../../hooks/useAddFunds";
import { useThemeColors } from "../../../../../../hooks/useThemeColors";

const CashDetails = () => {
  const { lightMode } = usePageContext();
  const closeSection = useWalletStore((s) => s.closeSection);
  const account = useAddFundsStore((s) => s.firstDetails.account);
  const amount = useAddFundsStore((s) => s.secondDetails.amount);
  const resetPage = useAddFundsStore((s) => s.resetPage);
  const inputSource = useWalletStore((s) => s.inputSource);
  const completePage = useAddFundsStore((s) => s.completePage);
  const inCompletePage = useAddFundsStore((s) => s.inCompletePage);
  const { mutateAsync: addFunds } = useAddFunds();
  const { PRIMARY_COLOR, SECONDARY_COLOR_DARK } = useThemeColors();

  useEffect(() => {
    completePage();
    return () => {
      inCompletePage();
    };
  }, []);

  const handleSubmit = () => {
    addFunds().then(() => {
      resetPage();
      inputSource("");
      closeSection();
    });
  };

  return (
    <HStack
      px={"28px"}
      justifyContent={"space-between"}
      py={"18px"}
      borderRadius={"15px"}
      bg={lightMode ? "#f5f5f5" : SECONDARY_COLOR_DARK}
      w={"100%"}
    >
      <VStack alignItems={"flex-start"}>
        <Text fontSize={"14px"} fontWeight={"500"}>
          Deposit Details
        </Text>
        <Text fontSize={"14px"} fontWeight={"500"}>
          Amount: US${numberWithCommas(amount)}
        </Text>
      </VStack>
      <VStack alignItems={"flex-start"}>
        <Text fontSize={"14px"} fontWeight={"500"}>
          Source: {account.accountName.split(" - ")[0]} -{" "}
          {account.credentials.accountNumber}
        </Text>
        <Text fontSize={"14px"} fontWeight={"500"}>
          Destination: Cash Account
        </Text>
      </VStack>
      <Button
        onClick={handleSubmit}
        w={"140px"}
        bg={PRIMARY_COLOR}
        _hover={{
          color: lightMode ? "white" : PRIMARY_COLOR,
        }}
        justifyContent={"center"}
        alignItems={"center"}
        borderRadius={"50px"}
        h={"42px"}
        display={"flex"}
        fontSize={"13px"}
        fontWeight={"700"}
        color={"#fff"}
      >
        Confirm
      </Button>
    </HStack>
  );
};

export default CashDetails;
