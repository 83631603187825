import React, { useEffect } from "react";
import styles from "./Statistics.module.css";
import Slider from "react-slick";
import StatsList from "./statsList/StatsList";
import MonthlyGain from "./monthlyGain/MonthlyGain";
import CardContainer from "./cardContainer/CardContainer";
import TradesCard from "./tradesCard/TradesCard";
import { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./statistics.scss";
import StatsMobileCard from "./StatsMobileCard/StatsMobileCard";
import MonthlyGainMobile from "./monthlyGainMobile/MonthlyGainMobile";
import { useDispatch, useSelector } from "react-redux";
import { env } from "../../../constants/env";
import { fetchMonthlyGain, fetchStatistics } from "../../../Redux/trades-actions";
import { calcDuration, checkNum, getPercentage, groupByMonth } from "../../../helpers/GetDuration";
import { getCookie } from "../../../utilities/functions";

const percentage = 40;

const userId = getCookie("userId");
const userType = getCookie("userType");
const companyId = getCookie("companyId"); // Assuming companyId is stored as a cookie

// const companyId = getCookie("companyId");



// STATISTICS CARD
export const StatisticsCard = ({
  children,
  height,
  width,
  bottomColor,
  padding,
}) => {
  const cardStyle = {
    height: height,
    width: width,
    padding: padding,
  };
  return (
    <div style={cardStyle} className={styles.cardContainer}>
      {children}
      {bottomColor && (
        <div
          className={styles.cardBottom}
          style={{ backgroundColor: bottomColor }}
        ></div>
      )}
    </div>
  );
};

// COLORED CARD COMPONENT
const ColoredCard = ({ children, height, width, bottomColor }) => {
  const cardStyle = {
    height: height,
    width: width,
  };
  return (
    <div style={cardStyle} className={styles.gainCard}>
      <div className={styles.gainCardBody}>{children}</div>
      <div
        style={{ backgroundColor: bottomColor, height: "14px" }}
        className={styles.gainCardBottom}
      ></div>
    </div>
  );
};



// STATISTICS SECTION
const StatisticSection = ({accountNumber, accountId, statsData, apiId, deposit, cumulativeCorrection, companyId}) => {
  const dispatch = useDispatch()
  

  const mobile = window.innerWidth > 768 ? false : true;
  const settingsNoModules = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    dotsClass: "button__bar",
    // centerMode: true,
    arrows: false,
  };

  const slideOneData = [
    {
      title: "Profit Factor",
      num: "0.87",
    },
    {
      title: "Sharpe Ratio",
      num: "-0.13",
    },
    {
      title: "One Day Return",
      num: "0.17%",
    },
    {
      title: "Annualized Return",
      num: "-99.53%",
    },
    {
      title: "Annualized Volatility",
      num: "758%",
    },
    {
      title: "Sortino Ratio",
      num: "0.14",
    },
    {
      title: "Omega Ratio",
      num: "1.7",
    },
  ];

  const slideTwoData = [
    {
      title: "Chance of Profit",
      num: "58.93%",
    },
    {
      title: "Account Age",
      num: "9 m 5 d",
    },
    {
      title: "Lots traded",
      num: "21.86M",
    },
    {
      title: "Commissions",
      num: "- $2,100.80 USD",
    },
    {
      title: "Swaps",
      num: "-$532.11 USD",
    },
    {
      title: "Total Deposits",
      num: "$20.22K USD",
    },
    {
      title: "Total Withdrawals",
      num: "-$15.12K USD",
    },
  ];

  const [statisticsData, setStatisticsData] = useState({});

  const statistics = useSelector(state => state.trades.statistics)

  const [profitsData, setProfitsData] = useState();

  useEffect(() => {
    getProfits(apiId)
  },[apiId])

  useEffect(() => {
    dispatch(fetchStatistics(accountNumber))
    // getStatsSummary(companyId, accountId)
  },[accountNumber])

  useEffect(() => {

    const idToUse = userType === "under-company" ? userId : companyId;

    getStatsSummary(idToUse, accountId)
  },[accountId, companyId])


  const getProfits = async (id) => {
    const sendRequest = async () => {
      const response = await fetch(`${env.pbTrackingUrl}/api/stats/profits-api/32413`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      console.log("PROFITS RESULTS FROM API :) ", result)

      setProfitsData(result);
      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };



  const getStatsSummary = async (id, account) => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/account/statistic-summary/${id}/${account}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "usertype": userType,
        },
      });

      if (!response.ok) {
        throw new Error("getting tarcking data failed.");
      }

      const result = await response.json();

      setStatisticsData(result)

      return result;

    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };


  

  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className={`${styles.statSection} `}>
        <h1 className={styles.statTitle}>Statistics</h1>
        {mobile ? (
          <div>
            <Slider {...settingsNoModules}>
              <div className={styles.sliderCard}>
                <StatsMobileCard list={slideOneData} title={"Summary"} />
              </div>
              <div className={styles.sliderCard}>
                <StatsMobileCard list={slideTwoData} title={"Summary"} />
              </div>
            </Slider>
          </div>
        ) : (
          <CardContainer>
            {statisticsData && <>
            <h1 className={styles.summaryText}>Summary</h1>
             <div className={`d-flex justify-content-between`}>
              <div>
                <StatsList
                  title={"Gain"}
                  num={statisticsData?.gain ? `${statisticsData?.gain}%` : 0}
                  firstColumn={true}
                  underline={true}
                  descText={<><p>Time-Weighted Return (TWR) that measures the performance of a dollar invested in the system since inception.</p>
                  
                  <p>TWR measurement is required by the Global Investment Performance Standards published by the CFA Institute.</p> 
                  
                  <p>Its distinguishing characteristic is that cash inflows, cash outflows and amounts invested over different time periods have no impact on the return.</p></>}
                  firstRow={true}
                />
                <StatsList
                  title={"Absolute Gain"}
                  num={statisticsData?.absoluteGain ? `${statisticsData?.absoluteGain}%` : 0}
                  underline={true}
                  descText={"Returns of the investment as a percentage of the total deposits. By definition, new deposits will affect the absolute gain."}
                  firstColumn={true}
                />
                <StatsList
                  title={"Daily Gain"}
                  num={statisticsData?.dailyGain ? `${statisticsData?.dailyGain}%` : 0}
                  underline={true}
                  descText={"Daily compound rate of return leading to the total gain."}
                  firstColumn={true}
                />
                <StatsList
                  title={"Monthly Gain"}
                  num={statisticsData?.monthlyGain ? `${statisticsData?.monthlyGain}%` : 0}
                  underline={true}
                  descText={"Monthly compound rate of return leading to the total gain."}
                  firstColumn={true}
                />
                <StatsList title={"Account Age"} num={statisticsData.accountAge ? `${statisticsData.accountAge?.toFixed(0)} days` : 0} />
                <StatsList title={"Lots traded"} num={statisticsData.lotsTraded ? statisticsData.lotsTraded : 0} />
                {/* <StatsList
                  title={"Annualized Volatility"}
                  num={"758%"}
                  firstColumn={true}
                /> */}
              </div>
              <div className={styles.vertical}></div>
              <div>
                <StatsList
                  title={"Profit Factor"}
                  num={statisticsData?.profitFactor ? statisticsData?.profitFactor : 0}
                  firstRow={true}
                  underline={true}
                  descText={<p>The amount yielded by winning trades divided by the amount of losses yielded by losing ones <a href="https://www.investopedia.com/articles/fundamental-analysis/10/strategy-performance-reports.asp">(read more)</a>.</p>}
                />
                <StatsList title={"Annualized Return"} num={statisticsData.annualizedReturn ? `${statisticsData.annualizedReturn}%` : 0} underline={true}
                  descText={"Annualized Return will be calibrated after 30 calendar days"}/>
                  <StatsList title={"Sharpe Ratio"} num={statisticsData.sharpeRatio ? `${statisticsData.sharpeRatio}%` : 0} underline={true} descText={"The average return earned in excess of the risk-free rate per unit of volatility. It's calculated if there are at least 30 closed deals in the history."}/>
                  <StatsList title={"Sortino Ratio"} num={statisticsData?.sortinoRatio ? `${statisticsData?.sortinoRatio}%` : 0} underline={true} descText={"It differentiates harmful volatility from total overall volatility. It's calculated if there are at least 30 closed deals in the history."}/>
                  <StatsList title={"Standard Deviation"} num={statisticsData?.standardDeviation ? `$${statisticsData?.standardDeviation}` : 0} underline={true} descText={<p>A statistical measure of volatility shows how much variation or dispersion there is from the mean profit in basic currency <a href="https://www.investopedia.com/terms/s/standarddeviation.asp">(read more)</a>. It is calculated if there are at least 30 closed deals in the history.</p>}/>
                  <StatsList title={"Expectancy"} num={statisticsData?.expectancy ? `${statisticsData?.expectancy}` : 0} descText={<p>The average <a href="https://www.investopedia.com/terms/e/expectedreturn.asp">expected profitability</a> of one trade in basic currency.</p>} underline={true}/>
                {/* <StatsList title={"Account Age"} num={statistics?.data?.firstTradeDate ? calcDuration(statistics?.data?.firstTradeDate, statistics?.data?.lastUpdateDate) : 0} />
                <StatsList title={"Lots traded"} num={statistics?.statistics?.lotsTraded ? Math.round(statistics?.statistics?.lotsTraded  * 100) / 100 : 0} /> */}
              </div>
              <div className={styles.vertical}></div>
              <div>
              <StatsList title={"Z-Score (Probability)"} num={statisticsData.zScore ? `${statisticsData.zScore}` : 0} firstRow={true} underline={true} descText={"The ability of a trading system to generate wins and losses in streaks. It is calculated if there are at least 30 closed deals in the history."}/>
              <StatsList title={"GHPR"} num={statisticsData.ghpr ? `${statisticsData.ghpr}%` : 0}  underline={true} descText={<p><a href="https://www.investopedia.com/terms/g/geometricmean.asp">Geometric holding period return.</a></p>}/>
                <StatsList
                  title={"Commissions"}
                  num={statisticsData?.commissions ? checkNum(statisticsData?.commissions) : 0}
                  firstRow={true}
                />
                {/* <StatsList title={"Swaps"} num={statistics?.statistics?.swaps ? checkNum(Math.round(statistics?.statistics?.swaps  * 100) / 100) : 0} /> */}
                <StatsList title={"Total Deposits"} num={statisticsData.totalDeposits ? checkNum(statisticsData.totalDeposits) : 0} />
                <StatsList title={"Cumulative Profit"} num={statisticsData?.cumulativeProfit ? checkNum(statisticsData?.cumulativeProfit) : 0} underline={true} descText={<p>The total yield of <a href="https://www.investopedia.com/terms/c/closeposition.asp">closed positions</a> for the entire period (total result).</p>}/>
                <StatsList title={"Total Withdrawals"} num={statisticsData?.totalWithdrawals ? checkNum(statisticsData?.totalWithdrawals) : 0} />
              </div>
            </div></>}
          </CardContainer>
        )}
      </div>
    </div>
  );
};

// MONTHLY GAIN SECTION
const MonthlyGainSection = ({accountNumber, accountId, companyId}) => {

  const [dataMonthly, setDataMonthly] = useState([])
  const dispatch = useDispatch()
  // const monthlyGain = useSelector(state => state.trades.monthlyGain)
  const[ monthlyGain, setMonthlyGain] = useState([])
 

  useEffect(() => {
    dispatch(fetchMonthlyGain(accountNumber))
  },[accountNumber])

  useEffect(() => {
    const idToUse = userType === "under-company" ? userId : companyId;
    getMonthlyGain(idToUse, accountId)
  },[accountId])


  const getMonthlyGain = async (id, account) => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/account/monthly-gain/${id}/${account}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "usertype": userType,
        },
      });

      if (!response.ok) {
        throw new Error("getting tarcking data failed.");
      }

      const result = await response.json();

      console.log("NEW MONTHLY GAIN WOWWWW", result)
      setMonthlyGain(result.monthlyGainData)

      return result

    };

    try {
      const data = await sendRequest();
    } catch (error) {
      console.log("MONTHLY GAIN ERROR", error);
    }
  };

  const mobile = window.innerWidth > 768 ? false : true;
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthlyData = [
    {
    //   year: "2020",
    //   data: [
    //     {
    //       num: 16.08,
    //     },
    //     {
    //       num: -14.08,
    //     },
    //     {
    //       num: 16.08,
    //     },
    //     {
    //       num: -14.08,
    //     },
    //     {
    //       num: 18.12,
    //     },
    //     {
    //       num: -14,
    //     },
    //     {
    //       num: 18.12,
    //     },
    //     {
    //       num: 20.32,
    //     },
    //     {
    //       num: -10.4,
    //     },
    //     {
    //       num: 18.12,
    //     },
    //     {
    //       num: 18.12,
    //     },
    //     {
    //       num: -10.4,
    //     },
    //   ],
    // },
    // {
      year: "2021",
      data: [
        {
          num: 16.08,
        },
        {
          num: -14.08,
        },
        {
          num: 16.08,
        },
        {
          num: -14,
        },
        {
          num: 18.12,
        },
        {
          num: 18.12,
        },
        {
          num: 18.12,
        },
        {
          num: 20.32,
        },
        {
          num: -10.4,
        },
        {
          num: 18.12,
        },
        {
          num: -10.4,
        },
        {
          num: 18.12,
        },
      ],
    },
  ];

  const getNumber = (index) => {
    var num = monthlyData
      .map((item) => item.data[index].num)
      .filter(function (item) {
        return item;
      })[0];
    return num;
  };

  // var newData = [];

  

  // for(var i in data) {
  //  let monthlyData = groupByMonth(data[i]);
  //  let year = {year: i, data: getPercentage(monthlyData, data[i][0].Deposit)}
  //  newData.push(year)
  // }

  console.log("MONTHLY DATAAA", monthlyGain)

  
  
  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className={`${styles.statSection} `}>
        <h1 className={styles.statTitle}>Monthly Gain</h1>

        {mobile ? (
          <MonthlyGainMobile months={months} data={monthlyData} />
        ) : (
          <CardContainer>
            <div className="d-flex justify-content-between">
              <div>
                <h1 className={styles.yearText}>Year</h1>
                {monthlyGain.map((item, i) => (
                  <div className={styles.yearNum}>{item.year}</div>
                ))}
              </div>
              {/* <div
              className={isNegative && 'isNegativeNumber'}
            >
              <div className={styles.monthText}>{month}</div>
              <div className={styles.percentageText} style={{color: isNegative && "#F83458" }}>{num}</div>
            </div> */}
              {months.map((item, i) => (
                <MonthlyGain
                  key={i}
                  month={item}
                  index={i}
                  data={monthlyGain}
                  // num={
                  //   getNumber(i) &&
                  //   (getNumber(i) <= -1 ? getNumber(i) + "%" : "+" + getNumber(i) + "%")
                  // }
                  // isNegative={getNumber(i) <= -1}
                />
              ))}
              <div>
                <h1 className={styles.yearText}>Total</h1>
                {monthlyGain.map((item, i) => (
                  <div className={styles.yearNum} key={i}>{`${Math.round(item?.data?.reduce((partialSum, a) => partialSum + (a?.percentage ? +a?.percentage : 0), 0) * 100) / 100}%`}</div>
                ))}
              </div>
            </div>
          </CardContainer>
        )}
      </div>
    </div>
  );
};

// TRADES SECTION
export const TradesSection = ({accountNumber, accountId, companyId}) => {

  const mobile = window.innerWidth > 768 ? false : true;
  const [activeTab, setActiveTab] = useState("summary");
  const [tradesSummary, setTradesSummary] = useState("");
  const [isSending, setIsSending] = useState()
  const [tradeData, setTradeData] = useState()

  const statistics = useSelector((state) => state.statistics.allStatistics);
  // useEffect(() => {
  //   fetchTradesSummary()
  // },[accountNumber])


  useEffect(() => {
    const idToUse = userType === "under-company" ? userId : companyId;
    getTradeSummary(idToUse, accountId)
  },[accountId])


  console.log("DEV STATITSYISC", statistics)

  const bestWinBestLossPercentage = (num, total) => {
    const percent = (num / total) * 100;

    return checkPercentage(percent)
}

const checkPercentage = (num) => {
      return `${Math.round(num * 100) / 100} %`
}

const calcDuration = (date) => {
  const diffTime = new Date(date);
  var seconds = Math.floor((diffTime / 1000) % 60),
  minutes = Math.floor((diffTime / (1000 * 60)) % 60),
  hours = Math.floor((diffTime / (1000 * 60 * 60)) % 24);
  hours = (hours <= 0) ? "" : `${hours} h`;
  minutes = (minutes <= 0) ? "" : ` ${minutes} min `;
  seconds = (minutes <= 0) ? ` ${seconds} sec` : "";

  return hours + minutes + seconds;
}
  

  const fetchTradesSummary = async () => {
   
    const sendRequest = async () => {
      setIsSending(true)
      const response = await fetch(`${env.pbTrackingUrl}/api/trades/${accountNumber}/summary`, {
        method: "GET",
        headers: {
          "x-api-key": "hv3zmZLmRLM6ZPIQKPhRXWRNMf71MSZE",
        },
      });

      if (!response.ok) {
        setIsSending(false)
        throw new Error("Fetching Bagley failed.");
      }

      const result = await response.json();

      console.log("SUMMARY", result)
      setIsSending(false)


      return result;
    };

    try {
      const data = await sendRequest();
      setTradesSummary(data)
    } catch (error) {
      setIsSending(false)
      console.log(error);
    }
  };



  const getTradeSummary = async (id, account) => {
    const sendRequest = async () => {
      setIsSending(true)
      const response = await fetch(`${env.primeBridgeUrl}/api/account/trade-summary/${id}/${account}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "usertype": userType
        },
      });

      if (!response.ok) {
        setIsSending(false)
        throw new Error("getting tarcking data failed.");
      }

      const result = await response.json();

      console.log("TRACKING DATA SUMMMARY", result)

      setTradeData(result)
      setIsSending(false)

      return result;

    };

    try {
      await sendRequest();
    } catch (error) {
      setIsSending(false)
      console.log(error);
    }
  };
  
  const summaryData = [
    {
      title: "Trades",
      num: tradeData?.trades ? tradeData?.trades : 0,
    },
    {
      title: "Profitable",
      num: tradeData?.profitable ? checkPercentage(tradeData?.profitable) : 0,
    },
    {
      title: "Expectancy",
      num: tradeData?.expectancy ?  `${checkNum(tradeData?.expectancy)}` : 0,
    },
    {
      title: "Average Duration",
      num: tradeData?.averageDuration ? calcDuration(tradeData?.averageDuration) : 0,
    },
  ];

  const winnersData = [
    {
      title: "Total Winners",
      num: tradeData?.totalWinners ? checkPercentage(tradeData?.totalWinners) : 0,
    },
    {
      title: "Best Win",
      num: tradeData?.bestWin ? `${checkNum(tradeData?.bestWin)}` : 0,
    },
    {
      title: "Average Win",
      num: tradeData?.averageWin ? `${checkNum(tradeData?.averageWin)}` : 0,
    },
    {
      title: "Average Pips",
      num: tradeData?.averageWinPips ? tradeData?.averageWinPips : 0,
    },
  ];

  const losersData = [
    {
      title: "Total Losers",
      num: tradeData?.totalLosers ? checkPercentage(tradeData?.totalLosers) : 0,
    },
    {
      title: "Worst Loss",
      num: tradeData?.worstLoss ? `${checkNum(tradeData?.worstLoss)}` : 0,
    },
    {
      title: "Average Loss",
      num: tradeData?.averageLoss ? `${checkNum(tradeData?.averageLoss)}` : 0,
    },
    {
      title: "Average Pips",
      num: tradeData?.averageLossPips ? tradeData?.averageLossPips : 0,
    },
  ];

  
  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className={`${styles.statSection} `}>
        <h1 className={styles.statTitle}>Trades</h1>
        {!isSending && <>
        {mobile ? (
          <div>
            <div className="d-flex mb-4 justify-content-center align-items-center">
              <div
                className={`d-flex justify-content-between align-items-center ${styles.tabsWrapper}`}
              >
                <div
                  className={`${styles.tabText} ${
                    activeTab === "summary" && styles.activeTab
                  }`}
                  onClick={() => setActiveTab("summary")}
                >
                  Summary
                </div>
                <div
                  className={`${styles.tabText} ${
                    activeTab === "winner" && styles.activeTab
                  }`}
                  onClick={() => setActiveTab("winner")}
                >
                  Winners
                </div>
                <div
                  className={`${styles.tabText} ${
                    activeTab === "loser" && styles.activeTab
                  }`}
                  onClick={() => setActiveTab("loser")}
                >
                  Losers
                </div>
              </div>
            </div>
            {activeTab === "summary" && (
              <div className={styles.statsWrapper}>
              <StatsMobileCard list={summaryData} title={"Summary"} />
              </div>
            )}
            {activeTab === "winner" && (
              <div className={styles.statsWrapper}>
              <StatsMobileCard list={winnersData} title={"Winner"} />
              </div>
            )}
            {activeTab === "loser" && (
              <div className={styles.statsWrapper}>
              <StatsMobileCard list={losersData} title={"Loser"} />
              </div>
            )}
          </div>
        ) : (
          <div className="d-flex justify-content-between align-items-center my-5">
            <TradesCard title={"Summary"} list={summaryData} summary={true} />
            <div className={styles.vertical}></div>
            <TradesCard title={"Winners"} list={winnersData} />
            <div className={styles.vertical}></div>

            <TradesCard title={"Losers"} list={losersData} loss={true} />
          </div>
        )}

</>}

{isSending && (
  <div className="d-flex mb-4 justify-content-center align-items-center">
          <div class="spinner-border" role="status">
            {/* <span class="sr-only">Loading...</span> */}
            
          </div>
          </div>
        )}
      </div>
    </div>
  );
};

// MAIN PAGE TO EXPORT
export default function Statistics({ active, handleChange, apiId, accountNumber, statsData, id, companyId, deposit, cumulativeCorrection }) {
  
  return (
    <div>
      <div style={{ height: "max-content", background: "" }}>
        <StatisticSection accountNumber={accountNumber} accountId={id} statsData={statsData} apiId={apiId} deposit={deposit} cumulativeCorrection={cumulativeCorrection} companyId={companyId}/>
        <MonthlyGainSection accountNumber={accountNumber} accountId={id} companyId={companyId}/>
        <TradesSection accountNumber={accountNumber} accountId={id} companyId={companyId}/>
      </div>
    </div>
  );
}
