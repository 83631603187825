import { HStack, VStack } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import React, { useEffect } from "react";
import {
  getCookie,
  numberWithCommas,
} from "../../../../../../utilities/functions";
import { usePageContext } from "../../../../contexts/page.context";
import InfoIcon from "../../../../components/Info.icon";
import { Text } from "../../../../components/text";
import { useSendfundsContext } from "../../sendfunds.context";
import Tip from "./../tip/NormalTip";
import SuccessIcon from "../../../../icons/Success.icon";
import { toast } from "react-hot-toast";
import useSendFundsStore from "../../store";
import useWalletStore from "../../../../store";
import useTradingAccounts from "../../../accounts/hooks/useTradingAccounts";
import { useSelector } from "react-redux";
import useSendFunds from "../../hooks/useSendFunds";
import useTransfer from "../../hooks/useTransfer";
import { useThemeColors } from "../../../../../../hooks/useThemeColors";
import { useDomainContext } from "../../../../../../contexts/DomainContext";

const CashDetails = () => {
  const { lightMode } = usePageContext();
  const companyId = getCookie("companyId");
  const source = useSendFundsStore((s) => s.firstDetails.source);
  const amount = useSendFundsStore((s) => s.secondDetails.amount);
  const destination = useSendFundsStore((s) => s.firstDetails.destination);
  const closeSection = useWalletStore((s) => s.closeSection);
  const completePage = useSendFundsStore((s) => s.completePage);
  const inCompletePage = useSendFundsStore((s) => s.inCompletePage);
  const { data: tradingAccounts } = useTradingAccounts(companyId);
  const { mutateAsync: sendFunds } = useSendFunds();
  const { mutateAsync: transferFunds } = useTransfer();
  const resetPage = useSendFundsStore((s) => s.resetPage);
  const inputSource = useWalletStore((s) => s.inputSource);
  const pbShareSettings = source?.pbShareSettings;
  const withdrawalSettings = useSelector(
    (state) => state.company.withdrawalSettings
  );
  const { PRIMARY_COLOR, SECONDARY_COLOR_DARK } = useThemeColors();

  const { companyTheme } = useDomainContext();

  useEffect(() => {
    completePage();
    return () => {
      inCompletePage();
    };
  }, []);

  const handleSubmit = () => {
    sendFunds().then(() => {
      resetPage();
      inputSource("");
      closeSection();
    });
  };

  console.log(pbShareSettings);
  console.log(withdrawalSettings);

  return (
    <HStack
      justifyContent={"space-between"}
      bg={lightMode ? "#f5f5f5" : SECONDARY_COLOR_DARK}
      borderRadius={"15px"}
      w={"100%"}
      px={"37px"}
      py={"23px"}
    >
      <HStack gap={"89px"} alignItems={"flex-end"}>
        <VStack gap={"14px"} alignItems={"flex-start"}>
          <Text mb={"8px"} fontWeight={"600"} fontSize={"14px"}>
            Withdrawal Details
          </Text>
          <Text fontWeight={"500"} fontSize={"14px"}>
            Amount: US${numberWithCommas(amount)}
          </Text>
          <Text fontWeight={"500"} fontSize={"14px"}>
            Source: {source?.accountName} - {source.credentials.accountNumber}
          </Text>
          {companyTheme === "default" && <Text fontWeight={"500"} fontSize={"14px"}>
            Destination: {destination}
          </Text>}
        </VStack>
        <VStack gap={"14px"} alignItems={"flex-start"}>
        {companyTheme === "default" && <HStack>
             <Text fontWeight={"500"} fontSize={"14px"}>
              PB Share: US${" "}
              {numberWithCommas(
                Math.round(
                  parseFloat(amount.replace(/,/g, "")) * +pbShareSettings * 100
                ) / 100
              )}
            </Text>
            <Tip icon={<InfoIcon size={"12"} />}>
              Prime Bridge's portion of the withdrawal
            </Tip>
          </HStack>}
          {companyTheme === "default" && <HStack>
            <Text fontWeight={"500"} fontSize={"14px"}>
              Withdrawal Fee: US${" "}
              {numberWithCommas(
                Math.round(
                  (parseFloat(amount.replace(/,/g, "")) -
                    parseFloat(amount.replace(/,/g, "")) * +pbShareSettings) *
                    +withdrawalSettings *
                    100
                ) / 100
              )}
            </Text>
            <Tip icon={<InfoIcon size={"12"} />}>
              Fee paid to the regulated custodian
            </Tip>
          </HStack>}
          {companyTheme === "default" && <HStack>
            <Text fontWeight={"500"} fontSize={"14px"}>
              Net Payout: US${" "}
              {numberWithCommas(
                Math.round(
                  (parseFloat(amount.replace(/,/g, "")) -
                    parseFloat(amount.replace(/,/g, "")) * +pbShareSettings -
                    (parseFloat(amount.replace(/,/g, "")) -
                      parseFloat(amount.replace(/,/g, "")) * +pbShareSettings) *
                      +withdrawalSettings) *
                    100
                ) / 100
              )}
            </Text>
            <Tip icon={<InfoIcon size={"12"} />}>
              Final amount you receive after deductions
            </Tip>
          </HStack>}
        </VStack>
      </HStack>
      <Button
        flexShrink={0}
        width={"189px"}
        gap={"10px"}
        borderRadius={"31px"}
        bg={PRIMARY_COLOR}
        _hover={{
          bg: PRIMARY_COLOR,
          color: "white",
        }}
        fontWeight={"700"}
        color={"white"}
        height={"48px"}
        onClick={handleSubmit}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        fontSize={"13px"}
      >
        Confirm
      </Button>
    </HStack>
  );
};

export default CashDetails;
