import { HStack, VStack } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import React, { useEffect } from "react";
import { toast } from "react-hot-toast";
import { useTransferContext } from "../transfer.context";
import { usePageContext } from "../../../contexts/page.context";
import SuccessIcon from "../../../icons/Success.icon";
import { numberWithCommas } from "../../../../../utilities/functions";
import { Text } from "../../../components/text";
import useTransferFundsStore from "../store";
import useWalletStore from "./../../../store";
import useTransfer from "../hooks/useTransfer";
import { useThemeColors } from "../../../../../hooks/useThemeColors";

const TransferDetails = () => {
  const { lightMode } = usePageContext();
  const closeSection = useWalletStore((s) => s.closeSection);
  const source = useTransferFundsStore((s) => s.source);
  const amount = useTransferFundsStore((s) => s.amount);
  const destination = useTransferFundsStore((s) => s.destination);
  const completePage = useTransferFundsStore((s) => s.completePage);
  const inCompletePage = useTransferFundsStore((s) => s.inCompletePage);
  const inputSource = useWalletStore((s) => s.inputSource);
  const { mutateAsync: transfer } = useTransfer();

  const { PRIMARY_COLOR, SECONDARY_COLOR_DARK } = useThemeColors();

  const handleSubmit = () => {
    transfer().then(() => {
      inputSource("");
      closeSection();
    });
  };

  useEffect(() => {
    completePage();
    return () => {
      inCompletePage();
    };
  }, []);

  return (
    <HStack
      px={"28px"}
      justifyContent={"space-between"}
      py={"18px"}
      borderRadius={"15px"}
      bg={lightMode ? "#f5f5f5" : SECONDARY_COLOR_DARK}
      w={"100%"}
    >
      <VStack alignItems={"flex-start"}>
        <Text fontSize={"14px"} fontWeight={"500"}>
          Transfer Details
        </Text>
        <Text fontSize={"14px"} fontWeight={"500"}>
          Amount: US${numberWithCommas(amount)}
        </Text>
      </VStack>
      <VStack alignItems={"flex-start"}>
        <Text fontSize={"14px"} fontWeight={"500"}>
          Source: {source?.accountName} - {source?.credentials?.accountNumber}
        </Text>
        <Text fontSize={"14px"} fontWeight={"500"}>
          Destination: {destination?.accountName} -{" "}
          {destination?.credentials?.accountNumber}
        </Text>
      </VStack>
      <Button
        onClick={handleSubmit}
        w={"140px"}
        bg={PRIMARY_COLOR}
        _hover={{
          color: lightMode ? "white" : PRIMARY_COLOR,
        }}
        justifyContent={"center"}
        alignItems={"center"}
        borderRadius={"50px"}
        h={"42px"}
        display={"flex"}
        fontSize={"13px"}
        fontWeight={"700"}
        color={"#fff"}
      >
        Confirm
      </Button>
    </HStack>
  );
};

export default TransferDetails;
