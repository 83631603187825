import { useDomainContext } from "../../contexts/DomainContext";
import classes from "./LogoContainer.module.css";

const LogoContainer = () => {
  const { companyTheme } = useDomainContext();
  return (
    <div className={`d-flex align-items-center  ${classes.wrapper}`}>
      <img
        src={companyTheme?.name === "pow" ? companyTheme.logoAlternative : "https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/prime-bridge-logo-white.svg"}
        alt="logo"
      />
      {companyTheme === "default" && <h4>Prime Bridge</h4>}
    </div>
  );
};

export default LogoContainer;
