import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Assets from "./components/Assets/Assets";
import Categories from "./components/Help/Categories";
import Category from "./components/Help/Category";
import ContactUs from "./components/Help/ContactUs";
import Help from "./components/Help/Help";
import Layout from "./components/Layout/Layout";
import AddMember from "./components/Team/AddMember";
import EditMember from "./components/Team/EditMember";
import MembersTable from "./components/Team/MembersTable";
import Team from "./components/Team/Team";
import Funds from "./components/Wallet/Funds";
import ServicesFees from "./components/Transactions/ServicesFees";
import Transactions from "./components/Transactions/Transactions";
import useWindowDimensions from "./hooks/getWindowDimensions";
import AuthPage from "./pages/AuthPage/AuthPage";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import MobilePage from "./pages/Mobile/MobilePage";
import PasswordReset from "./pages/PasswordReset/PasswordReset";
import Verification from "./pages/Verification/Verification";
import { getCookie } from "./utilities/functions";
import OpenTickets from "./components/Help/OpenTickets";
import { Toaster } from "react-hot-toast";
import TwoFactor from "./pages/TwoFactor/TwoFactor";
import Notifications from "./components/Notifications/Notifications";
import CustodyProvider from "./pages/CustodyProvider/CustodyProvider";
import Summaries from "./components/Transactions/Summaries";
import Overview from "./components/Overview/Overview";
import { env } from "./constants/env";
import { companyActions } from "./Redux/company-slice";
import NewAccount from "./components/NewAccount/NewAccount";
import { DomainProvider, useDomainContext } from "./contexts/DomainContext";
import classes from './App.module.css'
import { useThemeSettings } from "./hooks/useThemeSettings";

function App() {
  const dispatch = useDispatch();
  const { height, width } = useWindowDimensions();
  useThemeSettings();

  const [accessLevel, setAccessLevel] = useState([]);
  const authToken = getCookie("authToken");
  const isAdmin = getCookie("isAdmin");
  const userId = getCookie("userId");
  const companyId = getCookie("companyId");
  const { loading, error, companyTheme, fetchCompanyTheme } = useDomainContext();


  const overview = useSelector((state) => state.company.overview);

  console.log("CHECK OVERVIEW", isAdmin);

  const getUser = async () => {
    const sendRequest = async () => {
      const response = await fetch(
        `${env.primeBridgeUrl}/api/auth/user/${userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      setAccessLevel(result?.accessLevel);

      console.log("USER ACCESS LEVEL: ", result);

      if (result?.isAdmin) {
        fetchSingleCompany(companyId);
      } else {
        if (window.location.pathname === "/dashboard/overview") {
          if (result.accessLevel?.includes("overview") && overview && (companyTheme === "default")) {
            return;
          } else if (result.accessLevel?.includes("asset-dashboard")) {
            window.location = "/dashboard";
          } else if (result.accessLevel?.includes("transactions")) {
            window.location = "/dashboard/transactions";
          } else if (result.accessLevel?.includes("team")) {
            window.location = "/dashboard/team";
          } else if (result.accessLevel?.includes("help-center")) {
            window.location = "/dashboard/help";
          }
        } else if (window.location.pathname === "/dashboard/dashboard") {
          if (result.accessLevel?.includes("asset-dashboard")) {
            return;
          } else if (result.accessLevel?.includes("overview") && overview) {
            window.location = "/dashboard/overview";
          } else if (result.accessLevel?.includes("transactions")) {
            window.location = "/dashboard/transactions";
          } else if (result.accessLevel?.includes("team")) {
            window.location = "/dashboard/team";
          } else if (result.accessLevel?.includes("help-center")) {
            window.location = "/dashboard/help";
          }
        } else if (window.location.pathname === "/dashboard/transactions") {
          if (result.accessLevel?.includes("transactions")) {
            return;
          } else if (result.accessLevel?.includes("overview") && overview) {
            window.location = "/dashboard/overview";
          } else if (result.accessLevel?.includes("asset-dashboard")) {
            window.location = "/dashboard";
          } else if (result.accessLevel?.includes("team")) {
            window.location = "/dashboard/team";
          } else if (result.accessLevel?.includes("help-center")) {
            window.location = "/dashboard/help";
          }
        } else if (window.location.pathname === "/dashboard/team") {
          if (result.accessLevel?.includes("team")) {
            return;
          } else if (result.accessLevel?.includes("overview") && overview) {
            window.location = "/dashboard/overview";
          } else if (result.accessLevel?.includes("asset-dashboard")) {
            window.location = "/dashboard";
          } else if (result.accessLevel?.includes("transactions")) {
            window.location = "/dashboard/transactions";
          } else if (result.accessLevel?.includes("help-center")) {
            window.location = "/dashboard/help";
          }
        } else if (window.location.pathname === "/dashboard/help") {
          if (result.accessLevel?.includes("help-center")) {
            return;
          } else if (result.accessLevel?.includes("overview") && overview) {
            window.location = "/dashboard/overview";
          } else if (result.accessLevel?.includes("asset-dashboard")) {
            window.location = "/dashboard";
          } else if (result.accessLevel?.includes("transactions")) {
            window.location = "/dashboard/transactions";
          } else if (result.accessLevel?.includes("team")) {
            window.location = "/dashboard/team";
          }
        }
      }

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (authToken) {
      getUser();
    } else {
      if (
        window.location.pathname === "/" ||
        window.location.pathname === "/forgot-password" ||
        window.location.pathname === "/custody-provider" ||
        window.location.href.indexOf("/two-factor") > -1 ||
        window.location.href.indexOf("/password-reset/") > -1
      ) {
        return;
      } else {
        window.location = "/";
      }
    }
  }, [authToken]);

  const fetchSingleCompany = async (companyId) => {
    try {
      const response = await fetch(
        `${env.primeBridgeUrl}/api/company/${companyId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("fetching company failed.");
      }

      const result = await response.json();

      dispatch(companyActions.getOverviewStatus({ overview: result.overview }));

      // if(window.location.pathname === "/" && result.overview){
      //     window.location = '/dashboard/overview'
      // }else
      if (
        (window.location.pathname === "/dashboard/overview" &&
          !result.overview) ||
        (window.location.pathname === "/" && !result.overview)
      ) {
        window.location = "/dashboard";
      }

      return result;
    } catch (error) {
      console.log(error);
    }
  };


  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    )
  }

  if (error) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center vh-100">
        <p style={{ color: "red" }}>Failed to load the portal. Please try again.</p>
        <button onClick={() => fetchCompanyTheme("pow")} className={classes.retryButton}>Retry</button>
      </div>
    );
  }

  return (
    <>
      <Toaster />
      <div className="main-page">
            {width <= 768 ? (
              <MobilePage />
            ) : (
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<AuthPage />} />
                  <Route path="/verification" element={<Verification />} />
                  <Route path="/two-factor" element={<TwoFactor />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route
                    path="/password-reset/:userId/:token"
                    element={<PasswordReset />}
                  />
                  <Route
                    path="/custody-provider"
                    element={<CustodyProvider />}
                  />
                  <Route path="/dashboard" element={<Layout />}>
                    <Route path="/dashboard" element={<Assets />} />
                    <Route path="/dashboard/overview" element={<Overview />} />
                    <Route
                      path="/dashboard/transactions"
                      element={<Transactions />}
                    >
                      <Route
                        path="/dashboard/transactions/funds"
                        element={<Funds />}
                      />
                      <Route
                        path="/dashboard/transactions/services-fees"
                        element={<ServicesFees />}
                      />
                      <Route
                        path="/dashboard/transactions/summaries"
                        element={<Summaries />}
                      />
                    </Route>
                    <Route path="/dashboard/team" element={<Team />}>
                      <Route
                        path="/dashboard/team"
                        element={<MembersTable />}
                      />
                      <Route
                        path="/dashboard/team/add-member"
                        element={<AddMember />}
                      />
                      <Route
                        path="/dashboard/team/edit-member/:id"
                        element={<EditMember />}
                      />
                    </Route>
                    <Route
                      path="/dashboard/new-account"
                      element={<NewAccount />}
                    />
                    <Route path="/dashboard/help" element={<Help />}>
                      <Route
                        path="/dashboard/help/contact-us"
                        element={<ContactUs />}
                      />
                      <Route
                        path="/dashboard/help/open-tickets"
                        element={<OpenTickets />}
                      />
                      <Route
                        path="/dashboard/help/categories"
                        element={<Categories />}
                      >
                        <Route
                          path="/dashboard/help/categories/category/:id"
                          element={<Category />}
                        />
                      </Route>
                    </Route>
                    <Route
                      path="/dashboard/notifications"
                      element={<Notifications />}
                    />
                  </Route>
                </Routes>
              </BrowserRouter>
            )}
      </div>
    </>
  );
}

export default App;
