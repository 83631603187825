import { useThemeColors } from "../hooks/useThemeColors";

const TeamIcon = ({ active, category, themeColor }) => {
  const width = category ? "67" : "27"
  const height = category ? "69" : "29"
  const { PRIMARY_COLOR } = useThemeColors();
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 47 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{flex: "none"}}
    >
      <path
        d="M2.1001 34.5C2.1001 31.7386 4.32039 29.5 7.05927 29.5H14.498C17.2369 29.5 19.4572 31.7386 19.4572 34.5V42C19.4572 44.7614 17.2369 47 14.498 47H7.05927C4.32039 47 2.1001 44.7614 2.1001 42V34.5Z"
        stroke={active ? PRIMARY_COLOR : category ? "#D0D0D0" : themeColor ? themeColor : "#000000"}
        stroke-width="4"
      />
      <path
        d="M35.3527 29.9472C35.4441 29.763 35.7049 29.763 35.7963 29.9472L44.0736 46.6382C44.156 46.8044 44.0361 47 43.8518 47H27.2971C27.1128 47 26.9929 46.8044 27.0754 46.6382L35.3527 29.9472Z"
        stroke={active ? PRIMARY_COLOR : category ? "#D0D0D0" : themeColor ? themeColor : "#000000"}
        stroke-width="4"
      />
      <path
        d="M2.1001 10.75C2.1001 5.91751 5.98561 2 10.7786 2C15.5717 2 19.4572 5.91751 19.4572 10.75C19.4572 15.5825 15.5717 19.5 10.7786 19.5C5.98561 19.5 2.1001 15.5825 2.1001 10.75Z"
        stroke={active ? PRIMARY_COLOR : category ? "#D0D0D0" : themeColor ? themeColor : "#000000"}
        stroke-width="4"
      />
    </svg>
  );
};
export default TeamIcon;
